import {
  FilterIdentifierProps,
  ResponseIdentifierItemConvert,
} from "interface/identifier";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { identifierApi } from "service/identifier";
import {
  ADD_IDENTIFIER_FILTER,
  REMOVE_IDENTIFIER,
  REMOVE_IDENTIFIER_FILTER_ITEM,
  IdentifierActions,
  SET_IDENTIFIER,
  SET_IDENTIFIER_FILTER,
  IdentifierThunkActionTypes,
} from "types/actions/identifier";
import ToastMessage from "utils/notification";

export const setFilterIdentifierAction = (
  payload: FilterIdentifierProps,
): IdentifierActions => ({
  type: SET_IDENTIFIER_FILTER,
  payload,
});
export const setIdentifierAction = (
  payload: ResponseIdentifierItemConvert[],
): IdentifierActions => ({
  type: SET_IDENTIFIER,
  payload,
});
export const addFilterIdentifierAction = (
  payload: FilterIdentifierProps,
): IdentifierActions => ({
  type: ADD_IDENTIFIER_FILTER,
  payload,
});
export const removeFilterIdentifierItemAction = (
  payload: string,
): IdentifierActions => ({
  type: REMOVE_IDENTIFIER_FILTER_ITEM,
  payload,
});
const removeIdentifierAction = (payload: string[]): IdentifierActions => ({
  type: REMOVE_IDENTIFIER,
  payload,
});
// action thunk
export const fetchRemoveIdentifierAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, IdentifierActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: IdentifierThunkActionTypes.FETCH_IDENTIFIER_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await identifierApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa phòng ban thành công",
        });
        dispatch(removeIdentifierAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa phòng ban thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: IdentifierThunkActionTypes.FETCH_IDENTIFIER_ERROR,
        payload: error.message,
      });
    }
  };
};
