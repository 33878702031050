import {
  FilterValueApplicationSlip,
  FilterValueApplicationSlipState,
  ResponseApplicationSlipItem,
} from "interface/application_slip";
import { checkStatusTwoDate } from "utils/common";
import {
  ID,
  RM_CODE,
  CREATED_AT,
  CUSTOMER,
  CREATOR,
  BORROW_DATE,
  RETURN_DATE,
  STATUS,
  PHONE_NUMBER,
  NUMBER_RECORD,
} from "utils/constants/application_slip";

export const DATA_TITLE_FILTER_APPLICATION_SLIP = {
  [RM_CODE]: "Mã",
  [CREATED_AT]: "Ngày tạo",
};
export const DATA_TITLE_APPLICATION_SLIP = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: CUSTOMER,
    name: "độc giả",
    width: 200,
  },

  {
    id: CREATOR,
    name: "Người tạo",
    width: 200,
  },
  {
    id: BORROW_DATE,
    name: "Ngày mượn",
    width: 200,
  },

  {
    id: RETURN_DATE,
    name: "Ngày trả",
    width: 200,
  },

  {
    id: STATUS,
    name: "Trạng thái",
    width: 200,
  },
  {
    id: PHONE_NUMBER,
    name: "Số điện thoại",
    width: 200,
  },
  {
    id: NUMBER_RECORD,
    name: "Số hồ sơ",
    width: 200,
  },
];
export const DATA_TITLE_APPLICATION_SLIP_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: CUSTOMER,
    name: "độc giả",
    width: 200,
  },
  {
    id: PHONE_NUMBER,
    name: "Số điện thoại",
    width: 200,
  },
  {
    id: NUMBER_RECORD,
    name: "Số hồ sơ",
    width: 200,
  },
  {
    id: STATUS,
    name: "Trạng thái",
    width: 200,
  },
  {
    id: CREATOR,
    name: "Người tạo",
    width: 200,
  },
  {
    id: BORROW_DATE,
    name: "Ngày mượn",
    width: 200,
  },

  {
    id: RETURN_DATE,
    name: "Ngày trả",
    width: 200,
  },
];
export const FILTER_APPLICATION_SLIP = [
  {
    title: "",
    list: [
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
      {
        id: BORROW_DATE,
        name: "Ngày muợn",
        width: 200,
      },
      {
        id: RETURN_DATE,
        name: "Ngày trả",
        width: 200,
      },
    ],
  },
];
export const FILTER_APPLICATION_SLIP_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_APPLICATION_SLIP: FilterValueApplicationSlip = {
  [RETURN_DATE]: { value: null, type: typeFilter.distance },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
  [BORROW_DATE]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataApplicationSlip = (
  data: ResponseApplicationSlipItem[],
) => {
  const nowDate = new Date();
  return data.map((x: ResponseApplicationSlipItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    borrow_date: x.borrow_date
      ? new Date(x.borrow_date).toLocaleDateString("en-GB")
      : "",
    return_date: x.return_date
      ? new Date(x.return_date).toLocaleDateString("en-GB")
      : "",
    creator: x.creator.full_name,
    customer: x.customer.full_name,
    archival_record_application_slip:
      x.archival_record_application_slip.length + "hồ sơ",
    status: checkStatusTwoDate(x.return_date, nowDate),
    phone_number: x.customer.phone_number,
    number_record: x.archival_record_application_slip.length + " hồ sơ",
  }));
};
export const FILTER_OPEN_APPLICATION_SLIP = {};
export const VALUE_FILTER_APPLICATION_SLIP: FilterValueApplicationSlipState = {
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
  [BORROW_DATE]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
  [RETURN_DATE]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_APPLICATION_SLIP = {
  isCreateAction: "application_slip.create",
  isUpdateAction: "application_slip.update",
  isDeleteAction: "application_slip.delete",
  isReadAction: "application_slip.getList",
};
