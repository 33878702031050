import {
  FilterArchiveCollectionProps,
  ResponseArchiveCollectionItemConvert,
} from "interface/archive_collection";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { archive_collectionApi } from "service/archive_collection";
import {
  ADD_ARCHIVE_COLLECTION_FILTER,
  REMOVE_ARCHIVE_COLLECTION,
  REMOVE_ARCHIVE_COLLECTION_FILTER_ITEM,
  ArchiveCollectionActions,
  SET_ARCHIVE_COLLECTION,
  SET_ARCHIVE_COLLECTION_FILTER,
  ArchiveCollectionThunkActionTypes,
} from "types/actions/archive_collection";
import ToastMessage from "utils/notification";

export const setFilterArchiveCollectionAction = (
  payload: FilterArchiveCollectionProps,
): ArchiveCollectionActions => ({
  type: SET_ARCHIVE_COLLECTION_FILTER,
  payload,
});
export const setArchiveCollectionAction = (
  payload: ResponseArchiveCollectionItemConvert[],
): ArchiveCollectionActions => ({
  type: SET_ARCHIVE_COLLECTION,
  payload,
});
export const addFilterArchiveCollectionAction = (
  payload: FilterArchiveCollectionProps,
): ArchiveCollectionActions => ({
  type: ADD_ARCHIVE_COLLECTION_FILTER,
  payload,
});
export const removeFilterArchiveCollectionItemAction = (
  payload: string,
): ArchiveCollectionActions => ({
  type: REMOVE_ARCHIVE_COLLECTION_FILTER_ITEM,
  payload,
});
const removeArchiveCollectionAction = (payload: string[]): ArchiveCollectionActions => ({
  type: REMOVE_ARCHIVE_COLLECTION,
  payload,
});
// action thunk
export const fetchRemoveArchiveCollectionAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, ArchiveCollectionActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: ArchiveCollectionThunkActionTypes.FETCH_ARCHIVE_COLLECTION_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await archive_collectionApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa phông thành công",
        });
        dispatch(removeArchiveCollectionAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa phông thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: ArchiveCollectionThunkActionTypes.FETCH_ARCHIVE_COLLECTION_ERROR,
        payload: error.message,
      });
    }
  };
};
