import {
  RM_CODE,
  CREATOR,
  CREATED_AT,
  ID,
  NAME,
} from "../constants/type_document";
import {
  FilterValueTypeDocument,
  FilterValueTypeDocumentState,
  ResponseTypeDocumentItem,
} from "interface/type_document";

export const DATA_TITLE_FILTER_TYPE_DOCUMENT = {
  [RM_CODE]: "Mã",
  [NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_TYPE_DOCUMENT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên loại tài liệu",
    width: 400,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: CREATOR,
    name: "Người tạo",
    width: 200,
  },
];
export const DATA_TITLE_TYPE_DOCUMENT_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên loại tài liệu",
    width: 400,
  },

  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: CREATOR,
    name: "Người tạo",
    width: 200,
  },
];
export const FILTER_TYPE_DOCUMENT = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên loại tài liệu",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_TYPE_DOCUMENT_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_TYPE_DOCUMENT: FilterValueTypeDocument = {
  [NAME]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataTypeDocument = (
  data: ResponseTypeDocumentItem[],
) => {
  return data.map((x: ResponseTypeDocumentItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    creator: x.creator.full_name,
  }));
};
export const FILTER_OPEN_TYPE_DOCUMENT = {};
export const VALUE_FILTER_TYPE_DOCUMENT: FilterValueTypeDocumentState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_TYPE_DOCUMENT = {
  isCreateAction: "type_document.create",
  isUpdateAction: "type_document.update",
  isDeleteAction: "type_document.delete",
  isReadAction: "type_document.getList",
};
