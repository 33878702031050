import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { AuthUser } from "../types/models/AuthUser";
import * as actions from "service/auth/loginService";
import { fetchError, fetchStart, fetchSuccess } from "../redux/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { signoutRedirect } from "service/auth/loginSSOService";
import jwt_decode from "jwt-decode";
import { AccountSate } from "../interface/account/account";
import {
  ResponseDataToken,
  ResponseData,
  ResLogin,
} from "../interface/common/common";
import { getRole } from "utils/common";
import { authRole } from "constants/AppConst";

interface JWTAuthContextProps {
  user: AuthUser | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}
export interface LoginSSOProps {
  sid?: string;
  UserName?: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
  loginSSO: (data: string) => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
  loginSSO: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

// fake data
const FAKE_DATA_ADMIN = [
  //   role_permission
  "dashboard.all",
  "managing.all",
  "warehouse.all",
  // role
  "role.getList",
  "role.create",
  "role.update",
  "role.delete",
  // permission
  // "permission.getList",
  // "permission.create",
  // "permission.update",
  // "permission.delete",
  // customer
  "customer.getList",
  "customer.create",
  "customer.update",
  "customer.delete",
  // account
  "account.getList",
  "account.create",
  "account.update",
  "account.delete",
  // cluster
  "cluster.getList",
  "cluster.create",
  "cluster.update",
  "cluster.delete",
  // cabinet
  "cabinet.getList",
  "cabinet.create",
  "cabinet.update",
  "cabinet.delete",
  // box
  "box.getList",
  "box.create",
  "box.update",
  "box.delete",
  // compartment
  "compartment.getList",
  "compartment.update",
  // document
  "document.getList",
  "document.create",
  "document.update",
  "document.delete",
  // type_document
  "type_document.getList",
  "type_document.create",
  "type_document.update",
  "type_document.delete",
  // identifier
  "identifier.getList",
  "identifier.create",
  "identifier.update",
  "identifier.delete",
  // warehouse
  "warehouse.getList",
  "warehouse.create",
  "warehouse.update",
  "warehouse.delete",
  // archive_collection
  "archive_collection.getList",
  "archive_collection.create",
  "archive_collection.update",
  "archive_collection.delete",
  // archival_record
  "archival_record.getList",
  "archival_record.create",
  "archival_record.update",
  "archival_record.delete",
  // application_slip
  "application_slip.getList",
  "application_slip.create",
  "application_slip.update",
  "application_slip.delete",
  // history
  "history.getList",
  // group_collection
  "group_collection.getList",
  "group_collection.create",
  "group_collection.update",
  "group_collection.delete",
];
//
const FAKE_DATA_USER = [
  //   role_permission
  "dashboard.all",
  "managing.all",
  "warehouse.all",
  // role
  // "role.getList",
  // "role.create",
  // "role.update",
  // "role.delete",
  // permission
  // "permission.getList",
  // "permission.create",
  // "permission.update",
  // "permission.delete",
  // customer
  "customer.getList",
  "customer.create",
  "customer.update",
  "customer.delete",
  // account
  // "account.getList",
  // "account.create",
  // "account.update",
  // "account.delete",
  // cluster
  "cluster.getList",
  "cluster.create",
  "cluster.update",
  "cluster.delete",
  // cabinet
  "cabinet.getList",
  "cabinet.create",
  "cabinet.update",
  "cabinet.delete",
  // box
  "box.getList",
  "box.create",
  "box.update",
  "box.delete",
  // compartment
  "compartment.getList",
  "compartment.update",
  // document
  "document.getList",
  "document.create",
  "document.update",
  "document.delete",
  // type_document
  "type_document.getList",
  "type_document.create",
  "type_document.update",
  "type_document.delete",
  // identifier
  "identifier.getList",
  "identifier.create",
  "identifier.update",
  "identifier.delete",
  // warehouse
  "warehouse.getList",
  "warehouse.create",
  "warehouse.update",
  "warehouse.delete",
  // archive_collection
  "archive_collection.getList",
  "archive_collection.create",
  "archive_collection.update",
  "archive_collection.delete",
  // archilval_record
  "archilval_record.getList",
  "archilval_record.create",
  "archilval_record.update",
  "archilval_record.delete",
  // application_slip
  "application_slip.getList",
  "application_slip.create",
  "application_slip.update",
  "application_slip.delete",
  // group_collection
  "group_collection.getList",
  "group_collection.create",
  "group_collection.update",
  "group_collection.delete",
  // history
  // "history.getList",
  // "history.create",
  // "history.update",
  // "history.delete",
];
const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [baseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const getAuthUser = () => {
      if (!token) {
        // setJWTAuthData({
        //   user: {
        //     user_id: 0,
        //     id: "",
        //     email: "",
        //     photoURL:
        //       "https://scontent.fhan5-11.fna.fbcdn.net/v/t39.30808-6/336539454_1305472193646586_823248391873833735_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Fc8k-ilYdoAAX-PUk33&_nc_ht=scontent.fhan5-11.fna&oh=00_AfB5FiIpY3O7kEd5Jv3TA50VOGm5s-eJf0mZqC46ZZOs7A&oe=655F1844",
        //     token: "",
        //     role: ["admin"],
        //     userName: "hint",
        //     mobileNumber: "0962611801",
        //     permission: [""],
        //     school: {
        //       oc_code: "",
        //       name: "",
        //       id: 0,
        //     },
        //     displayName: "hint",
        //     schoolPosition: "đev",
        //   },
        //   isAuthenticated: true,
        //   isLoading: false,
        // });
        setJWTAuthData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      } else {
        getUserMe(token);
      }
    };
    getAuthUser();
  }, []);
  const loginSSO = async (data: string) => {
    try {
      const token = {
        ssoToken: data,
      };
      actions.fetchLoginSSO(token).then((res: any) => {
        if (res && res.data) {
          window.localStorage.setItem("token", res.data.accessToken);
          getUserMe(res.data.accessToken);
          dispatch(fetchSuccess());
        } else {
          signoutRedirect();
          navigate("/signin");
          setJWTAuthData({
            user: null,
            isAuthenticated: false,
            isLoading: false,
          });
          dispatch(fetchError("Something went wrong"));
        }
      });
    } catch (error) {
      signoutRedirect();
      navigate("/signin");
      setJWTAuthData({
        ...baseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };
  const signInUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    dispatch(fetchStart());
    const data = {
      email,
      password,
    };
    try {
      actions.fetchLogin(data).then((res: ResLogin) => {
        if (res && res.data) {
          window.localStorage.setItem("token", res.data.access_token);
          getUserMe(res.data.access_token);
          dispatch(fetchSuccess());
          // dispatch(fetchSuccess());
        }
        if (!res.status) {
          dispatch(fetchError("Đăng nhập thất bại"));
        }
      });
    } catch (error) {
      setJWTAuthData({
        ...baseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };

  const signUpUser = async ({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }) => {
    dispatch(fetchStart());
    try {
      // const { data } = await jwtAxios.post("users", { name, email, password });
      // localStorage.setItem("token", data.token);
      // setAuthToken(data.token);
      // const res = await jwtAxios.get("/auth");
      // setJWTAuthData({
      //   user: res.data,
      //   isAuthenticated: true,
      //   isLoading: false,
      // });
      // dispatch(fetchSuccess());
    } catch (error) {
      // setJWTAuthData({
      //   ...baseData,
      //   isAuthenticated: false,
      //   isLoading: false,
      // });
      // dispatch(fetchError("Something went wrong"));
    }
  };
  const getUserMe = async (token: string) => {
    await actions
      .getUserMe(token)
      .then((resUserMe: ResponseData<AccountSate>) => {
        if (resUserMe.statusCode === 200) {
          navigate(
            location.pathname !== "/signin" ? location.pathname : "/dashboard",
          );
          let role = authRole.admin;
          let isAdmin = false;
          if (
            resUserMe.data.role.role_permission.find((it) =>
              it.includes("role.getList"),
            )
          ) {
            isAdmin = true;
          }
          setJWTAuthData({
            user: {
              id: resUserMe.data.id,
              rm_code: resUserMe.data.rm_code,
              email: resUserMe.data.email_address,
              photoURL: "",
              token: token,
              role,
              userName: resUserMe.data.full_name,
              mobileNumber: resUserMe.data.phone_number,
              permission:
                resUserMe.data.role === null
                  ? [""]
                  : resUserMe.data.role.role_permission,
              // permission:
              //   resUserMe.data.role.name === "Quản lý"
              //     ? FAKE_DATA_ADMIN
              //     : FAKE_DATA_USER,
              displayName: resUserMe.data.full_name,
              role_name: resUserMe.data.role.name ?? "",
              isAdmin,
            },
            isAuthenticated: true,
            isLoading: false,
          });
        } else {
          localStorage.removeItem("token");
          setJWTAuthData({
            user: null,
            isLoading: false,
            isAuthenticated: false,
          });
          navigate("/signin");
        }
      })
      .catch((e) => {
        localStorage.removeItem("token");
        navigate("/signin");
      });
  };
  const logout = async () => {
    localStorage.removeItem("token");
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...baseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          loginSSO,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
