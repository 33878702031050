import {
  FilterValueCabinet,
  FilterValueCabinetState,
  ResponseCabinetItem,
} from "interface/cabinet";
import {
  ID,
  RM_CODE,
  CREATOR_ID,
  CREATED_AT,
  UPDATED_AT,
  NAME,
  SKU,
  TYPE,
  TOTAL_ROW,
  TOTAL_COLUMN,
  CLUSTER,
  ROW_COLUMN,
  TYPE_SMART,
  STT,
  WAREHOUSE,
  CLUSTER_ID,
  WAREHOUSE_ID,
} from "utils/constants/cabinet";

export const DATA_TITLE_FILTER_CABINET = {
  [RM_CODE]: "Mã",
  [NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_CABINET = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên tủ hồ sơ",
    width: 200,
  },
  {
    id: STT,
    name: "vị trí tủ",
    width: 200,
  },
  {
    id: CLUSTER,
    name: "Tên cụm hồ sơ",
    width: 200,
  },
  {
    id: WAREHOUSE,
    name: "Tên kho",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: ROW_COLUMN,
    name: "Số hàng, cột",
    width: 200,
  },
  {
    id: TYPE_SMART,
    name: "Loại",
    width: 200,
  },
];
export const DATA_TITLE_CABINET_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên tủ hồ sơ",
    width: 200,
  },
  {
    id: STT,
    name: "vị trí tủ",
    width: 200,
  },
  {
    id: CLUSTER,
    name: "Tên cụm hồ sơ",
    width: 200,
  },
  {
    id: WAREHOUSE,
    name: "Tên kho",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: ROW_COLUMN,
    name: "Số hàng, cột",
    width: 200,
  },
  // {
  //   id: TOTAL_COLUMN,
  //   name: "Số cột",
  //   width: 200,
  // },
  {
    id: TYPE_SMART,
    name: "Loại",
    width: 200,
  },
];
export const FILTER_CABINET = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên tủ hồ sơ",
      },
      {
        id: TYPE_SMART,
        name: "Loại tủ hồ sơ",
      },
      {
        id: CLUSTER_ID,
        name: "Tên cụm hồ sơ",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_CABINET_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_CABINET: FilterValueCabinet = {
  [CLUSTER_ID]: { value: null, type: typeFilter.exactly },
  [WAREHOUSE_ID]: { value: null, type: typeFilter.exactly },
  [NAME]: { value: null, type: typeFilter.exactly },
  [TYPE_SMART]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataCabinet = (data: ResponseCabinetItem[]) => {
  // row_column

  return data.map((x: ResponseCabinetItem) => {
    const row_column = x.total_row + "x" + x.total_column;

    const dataConvert = {
      ...x,
      created_at: x.created_at
        ? new Date(x.created_at).toLocaleDateString("en-GB")
        : "",
      type: x.type ? "2 mặt" : "1 mặt",
      type_smart: x.type_smart
        ? x.type_smart === 1
          ? "Tủ di động quay tay"
          : "Tủ di động thông minh"
        : "Tủ cố định",
      creator: x.creator.full_name,
      cluster: x.cluster?.name ?? "",
      row_column,
      warehouse: x.cluster.warehouse.name,
    };
    return dataConvert;
  });
};
export const FILTER_OPEN_CABINET = {};
export const VALUE_FILTER_CABINET: FilterValueCabinetState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [WAREHOUSE_ID]: {
    value: "",
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
  [CLUSTER_ID]: {
    value: "",
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
  [TYPE_SMART]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_CABINET = {
  isCreateAction: "cabinet.create",
  isUpdateAction: "cabinet.update",
  isDeleteAction: "cabinet.delete",
  isReadAction: "cabinet.getList",
};
