import {
  FilterDocumentProps,
  ResponseDocumentItemConvert,
} from "interface/document";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { documentApi } from "service/document";
import {
  ADD_DOCUMENT_FILTER,
  REMOVE_DOCUMENT,
  REMOVE_DOCUMENT_FILTER_ITEM,
  DocumentActions,
  SET_DOCUMENT,
  SET_DOCUMENT_FILTER,
  DocumentThunkActionTypes,
} from "types/actions/document";
import ToastMessage from "utils/notification";

export const setFilterDocumentAction = (
  payload: FilterDocumentProps,
): DocumentActions => ({
  type: SET_DOCUMENT_FILTER,
  payload,
});
export const setDocumentAction = (
  payload: ResponseDocumentItemConvert[],
): DocumentActions => ({
  type: SET_DOCUMENT,
  payload,
});
export const addFilterDocumentAction = (
  payload: FilterDocumentProps,
): DocumentActions => ({
  type: ADD_DOCUMENT_FILTER,
  payload,
});
export const removeFilterDocumentItemAction = (
  payload: string,
): DocumentActions => ({
  type: REMOVE_DOCUMENT_FILTER_ITEM,
  payload,
});
const removeDocumentAction = (payload: string[]): DocumentActions => ({
  type: REMOVE_DOCUMENT,
  payload,
});
// action thunk
export const fetchRemoveDocumentAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, DocumentActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: DocumentThunkActionTypes.FETCH_DOCUMENT_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await documentApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa tài liệu thành công",
        });
        dispatch(removeDocumentAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa tài liệu thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: DocumentThunkActionTypes.FETCH_DOCUMENT_ERROR,
        payload: error.message,
      });
    }
  };
};
