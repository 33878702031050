interface PropsIcon {
  h: number;
  w: number;
}
function CompartmentIcon({ w, h }: PropsIcon) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.92 6.95v10.1A2.5 2.5 0 0017.97 20H7.87a2.5 2.5 0 00-2.95-2.95V6.95A2.5 2.5 0 007.87 4h10.1a2.5 2.5 0 002.95 2.95z"
        stroke="#596373"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.92 4.5a2.5 2.5 0 01-5 0A2.5 2.5 0 017.87 4c.03.16.05.33.05.5zM22.92 4.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM7.92 19.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM22.92 19.5a2.5 2.5 0 01-4.95.5 2.5 2.5 0 114.95-.5z"
        stroke="#596373"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CompartmentIcon;
