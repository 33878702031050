import {
  ROUTER_PERMISSION,
  ROUTER_PERMISSION_CREATE,
  ROUTER_PERMISSION_UPDATE,
} from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const Permission = React.lazy(() => import("./permission"));
const EditPage = React.lazy(() => import("./edit"));

export const permissionManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ROUTER_PERMISSION,
    element: <Permission />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ROUTER_PERMISSION_CREATE,
    element: <EditPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ROUTER_PERMISSION_UPDATE,
    element: <EditPage />,
  },
];
