import {
  FilterValueRole,
  ResponseRoleItem,
  FilterValueRoleState,
} from "interface/authencation/role/role";
import {
  CREATE_ROLE,
  ID_ROLE,
  NAME_ROLE,
  PERMISSION_ROLE,
} from "utils/constants/authencation/role";

export const DATA_TITLE_FILTER_ROLE = {
  [ID_ROLE]: "mã role",
  [NAME_ROLE]: "tên role",
  [PERMISSION_ROLE]: "permission",
  [CREATE_ROLE]: "ngày tạo",
};

export const DATA_TITLE_ROLE = [
  {
    id: ID_ROLE,
    name: "mã ",
    width: 200,
  },
  {
    id: NAME_ROLE,
    name: "tên vai trò",
    width: 200,
  },
  {
    id: CREATE_ROLE,
    name: "ngày tạo",
    width: 200,
  },
  {
    id: PERMISSION_ROLE,
    name: "quyền",
    width: 200,
  },
];
export const DATA_TITLE_ROLE_DEFAULT = [
  {
    id: ID_ROLE,
    name: "mã",
    width: 200,
  },
  {
    id: NAME_ROLE,
    name: "tên vai trò",
    width: 200,
  },
  {
    id: PERMISSION_ROLE,
    name: "quyền",
    width: 200,
  },
];
export const FILTER_ROLE = [
  {
    title: "",
    list: [
      {
        id: NAME_ROLE,
        name: "Tên vai trò",
      },
      {
        id: CREATE_ROLE,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_ROLE_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_ROLE: FilterValueRole = {
  [NAME_ROLE]: { value: null, type: typeFilter.exactly },
  [CREATE_ROLE]: { value: null, type: typeFilter.exactly },
};
export const handleConvertDataRole = (data: ResponseRoleItem[]) => {
  return data.map((x: ResponseRoleItem) => ({
    ...x,
    permission:
      x.role_permission === null || x.role_permission.length === 0
        ? "đang cập nhật"
        : x.role_permission.map((it) => it.permission?.name).join(" , "),
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
  }));
};
export const FILTER_OPEN_ROLE = {
  [NAME_ROLE]: false,
  [CREATE_ROLE]: false,
};
export const VALUE_FILTER_ROLE: FilterValueRoleState = {
  [NAME_ROLE]: {
    value: "",
    type: "exactly",
    keySearch: "",
    isGet: false,
  },
  [CREATE_ROLE]: {
    value: {
      value1: "",
      value2: "",
    },
    type: "includes",
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_ROLE = {
  isCreateAction: "role.create",
  isUpdateAction: "role.update",
  isDeleteAction: "role.delete",
  isReadAction: "role.getList",
};
