import {
  FilterCompartmentProps,
  ResponseCompartmentItemConvert,
} from "interface/compartment";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { compartmentApi } from "service/compartment";
import {
  ADD_COMPARTMENT_FILTER,
  REMOVE_COMPARTMENT,
  REMOVE_COMPARTMENT_FILTER_ITEM,
  CompartmentActions,
  SET_COMPARTMENT,
  SET_COMPARTMENT_FILTER,
  CompartmentThunkActionTypes,
} from "types/actions/compartment";
import ToastMessage from "utils/notification";

export const setFilterCompartmentAction = (
  payload: FilterCompartmentProps,
): CompartmentActions => ({
  type: SET_COMPARTMENT_FILTER,
  payload,
});
export const setCompartmentAction = (
  payload: ResponseCompartmentItemConvert[],
): CompartmentActions => ({
  type: SET_COMPARTMENT,
  payload,
});
export const addFilterCompartmentAction = (
  payload: FilterCompartmentProps,
): CompartmentActions => ({
  type: ADD_COMPARTMENT_FILTER,
  payload,
});
export const removeFilterCompartmentItemAction = (
  payload: string,
): CompartmentActions => ({
  type: REMOVE_COMPARTMENT_FILTER_ITEM,
  payload,
});
const removeCompartmentAction = (payload: string[]): CompartmentActions => ({
  type: REMOVE_COMPARTMENT,
  payload,
});
// action thunk
export const fetchRemoveCompartmentAction = (
  listCheckcompartment: string[],
): ThunkAction<void, DefaultRootState, unknown, CompartmentActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: CompartmentThunkActionTypes.FETCH_COMPARTMENT_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await compartmentApi.delete(listCheckcompartment);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa khoang hồ sơ thành công",
        });
        dispatch(removeCompartmentAction(listCheckcompartment));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa khoang hồ sơ thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: CompartmentThunkActionTypes.FETCH_COMPARTMENT_ERROR,
        payload: error.message,
      });
    }
  };
};
