import {
  FilterRoleProps,
  ResponseRoleItem,
} from "interface/authencation/role/role";
import axios from "axios";
import Service from "service/api-core/apiService";
import { formatDateToYYYYMMDD, typeFilter } from "utils/common";
const URL_END_POINT = "/role";
const service = new Service();
export const roleApi = {
  getAll() {
    const url = URL_END_POINT + "?page=1&page_size=999";
    return service
      .get(url)
      .then((res: { code: string; data: ResponseRoleItem[] }) => res);
  },
  get(filter?: FilterRoleProps, pg?: number, pageSize?: number) {
    const page = pg ? pg : 1;
    const page_size = pageSize ? pageSize : 20;
    let param = `?page=${page}&page_size=${page_size}`;
    let params = [];
    if (filter?.type === "search") {
      for (const key in filter.value) {
        const item = filter.value[key as keyof typeof filter.value];
        if (item.value !== null) {
          const paramItems = [];
          switch (item.type) {
            case typeFilter.exactly:
              {
                paramItems.push({
                  query: key + "__eq",
                  value: item.value,
                });
              }
              break;
            case typeFilter.start:
              {
                paramItems.push({
                  query: key + "__like",
                  value: item.value + "%",
                });
              }
              break;
            case typeFilter.distance:
              {
                if (
                  typeof item.value === "object" &&
                  !Array.isArray(item.value) &&
                  !(item.value instanceof Date)
                ) {
                  paramItems.push(
                    {
                      query: key + "__le",
                      value: formatDateToYYYYMMDD(item.value.value2),
                    },
                    {
                      query: key + "__ge",
                      value: formatDateToYYYYMMDD(item.value.value1),
                    },
                  );
                }
              }
              break;
            case typeFilter.distanceNumber:
              {
                if (
                  typeof item.value === "object" &&
                  !Array.isArray(item.value) &&
                  !(item.value instanceof Date)
                ) {
                  paramItems.push(
                    {
                      query: key + "__le",
                      value: +item.value.value2,
                    },
                    {
                      query: key + "__ge",
                      value: +item.value.value1,
                    },
                  );
                }
              }
              break;
            case typeFilter.includesString:
              {
                paramItems.push({
                  query: key + "__like",
                  value: "%" + item.value + "%",
                });
              }
              break;
          }
          params = [...params, ...paramItems];
        }
      }
    }
    params.forEach((it) => {
      param = param + "&" + it.query + "=" + it.value;
    });
    const url = URL_END_POINT + param;
    return service
      .get(url)
      .then((res: { code: string; data: ResponseRoleItem[] }) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  getDetail(id: string) {
    const url = URL_END_POINT + "/" + id;
    return service
      .get(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  search(id: string, pg?: number, pageSize?: number) {
    const page = pg ? pg : 1;
    const page_size = pageSize ? pageSize : 20;
    let param = `?page=${page}&page_size=${page_size}&rm_code__like=%${id}%`;
    const url = URL_END_POINT + param;
    return service
      .get(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  create(payload: {
    name: string;
    creator_id: number;
    permission_id: number[];
  }) {
    return service
      .post(URL_END_POINT, payload, {})
      .then((res: { status: boolean }) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  update(
    payload: {
      name: string;
      creator_id: number;
      role_permission: { permission_id: number }[];
    },
    oc_code: string,
  ) {
    const permissionConvert = payload.role_permission.map(
      (it) => it.permission_id,
    );
    return service
      .put(URL_END_POINT + "/" + oc_code, {
        ...payload,
        role_permission: permissionConvert,
      })
      .then((res: { statusCode: number }) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  update2(
    payload: { name: string; role_permission: { permission_id: number }[] },
    oc_code,
  ) {
    const permissionConvert = payload.role_permission.map(
      (it) => it.permission_id,
    );
    return service
      .put(URL_END_POINT + "/" + oc_code, {
        ...payload,
        role_permission: permissionConvert,
      })
      .then((res: { statusCode: number }) => {
        return res.statusCode === 200;
      })
      .catch((err) => {
        throw err;
      });
  },
  delete(listID: string[]) {
    const url = process.env.REACT_APP_API_URL + URL_END_POINT;
    return service
      .delete(url, [...listID])
      .then((res: { status: boolean }) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
