import { MetadataRes } from "interface/common/common";
import {
  ResponseArchivalRecordItem,
  PayloadArchivalRecord,
  FilterArchivalRecordProps,
} from "interface/archival_record";
import Service from "service/api-core/apiService";
import { convertDateTimeToDate, typeFilter } from "utils/common";
const URL_END_POINT = "/archival_record";
const service = new Service();
export const archival_recordApi = {
  search(id: string, pg?: number, pageSize?: number) {
    const page = pg ? pg : 1;
    const page_size = pageSize ? pageSize : 20;
    let param = `?page=${page}&page_size=${page_size}&rm_code__like=%${id}%`;
    const url = URL_END_POINT + param;
    return service
      .get(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  getAll() {
    const url = URL_END_POINT + "?page=1&page_size=999";
    return service
      .get(url)
      .then(
        (res: {
          code: string;
          data: ResponseArchivalRecordItem[];
          metadata: MetadataRes;
        }) => res,
      );
  },
  get(filter?: FilterArchivalRecordProps, pg?: number, pageSize?: number) {
    const page = pg ? pg : 1;
    const page_size = pageSize ? pageSize : 20;
    let param = `?page=${page}&page_size=${page_size}`;
    let params = [];
    if (filter?.type === "search") {
      for (const key in filter.value) {
        const item = filter.value[key as keyof typeof filter.value];
        if (item.value !== null) {
          const paramItems = [];
          switch (item.type) {
            case typeFilter.exactly:
              {
                paramItems.push({
                  query: key + "__eq",
                  value: item.value,
                });
              }
              break;
            case typeFilter.start:
              {
                paramItems.push({
                  query: key + "__like",
                  value: item.value + "%",
                });
              }
              break;
            case typeFilter.distance:
              {
                if (
                  typeof item.value === "object" &&
                  !Array.isArray(item.value) &&
                  !(item.value instanceof Date)
                ) {
                  paramItems.push(
                    {
                      query: key + "__le",
                      value: convertDateTimeToDate(item.value.value2),
                    },
                    {
                      query: key + "__ge",
                      value: convertDateTimeToDate(item.value.value1),
                    },
                  );
                }
                if (typeof item.value === "string") {
                  paramItems.push({
                    query: key + "__in",
                    value: item.value,
                  });
                }
              }
              break;
            case typeFilter.distanceNumber:
              {
                if (
                  typeof item.value === "object" &&
                  !Array.isArray(item.value) &&
                  !(item.value instanceof Date)
                ) {
                  paramItems.push(
                    {
                      query: key + "__le",
                      value: +item.value.value2,
                    },
                    {
                      query: key + "__ge",
                      value: +item.value.value1,
                    },
                  );
                }
              }
              break;
            case typeFilter.includesString:
              {
                paramItems.push({
                  query: key + "__like",
                  value: "%" + item.value + "%",
                });
              }
              break;
          }
          params = [...params, ...paramItems];
        }
      }
    }
    params.forEach((it) => {
      param = param + "&" + it.query + "=" + it.value;
    });
    const url = URL_END_POINT + param;
    return service
      .get(url)
      .then(
        (res: {
          code: string;
          data: ResponseArchivalRecordItem[];
          metadata: MetadataRes;
        }) => {
          return res;
        },
      )
      .catch((err) => {
        throw err;
      });
  },
  getDetail(rm_code: string) {
    const url = URL_END_POINT + "/" + rm_code;
    return service
      .get(url)
      .then(
        (res: { statusCode: number; data: ResponseArchivalRecordItem }) => res,
      );
  },
  create(payload: PayloadArchivalRecord) {
    return service
      .post(URL_END_POINT, payload, {})
      .then((res: { statusCode: number }) => res.statusCode === 200)
      .catch(() => false);
  },
  update(payload: PayloadArchivalRecord, rm_code: string) {
    const url = URL_END_POINT + "/" + rm_code;
    return service
      .put(url, payload)
      .then((res: { statusCode: number }) => res.statusCode === 200)
      .catch(() => false);
  },
  delete(list_rm_code: string[]) {
    return service
      .delete(URL_END_POINT, list_rm_code)
      .then((res: { statusCode: number }) => res.statusCode === 200)
      .catch(() => false);
  },
};
