import {
  FilterRoleProps,
  ResponseRoleItemConvert,
} from "interface/authencation/role/role";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { roleApi } from "service/authencation/role/roleService";
import {
  ADD_ROLE_FILTER,
  REMOVE_ROLE,
  REMOVE_ROLE_FILTER_ITEM,
  RoleActions,
  SET_ROLE,
  SET_ROLE_FILTER,
  RoleThunkActionTypes,
} from "types/actions/authencation/Role.actions";
import ToastMessage from "utils/notification";

export const setFilterRoleAction = (payload: FilterRoleProps): RoleActions => ({
  type: SET_ROLE_FILTER,
  payload,
});
export const setRoleAction = (
  payload: ResponseRoleItemConvert[],
): RoleActions => ({
  type: SET_ROLE,
  payload,
});
export const addFilterRoleAction = (payload: FilterRoleProps): RoleActions => ({
  type: ADD_ROLE_FILTER,
  payload,
});
export const removeFilterRoleItemAction = (payload: string): RoleActions => ({
  type: REMOVE_ROLE_FILTER_ITEM,
  payload,
});
const removeRoleAction = (payload: string[]): RoleActions => ({
  type: REMOVE_ROLE,
  payload,
});
// action thunk
export const fetchRemoveRoleAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, RoleActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: RoleThunkActionTypes.FETCH_ROLE_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await roleApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res?.status) {
        ToastMessage({
          type: "success",
          message: "delete success",
        });
        dispatch(removeRoleAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "delete error",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: RoleThunkActionTypes.FETCH_ROLE_ERROR,
        payload: error.message,
      });
    }
  };
};
