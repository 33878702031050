import React from "react";
import { initialUrl } from "constants/AppConst";

import { authRouteConfig } from "./auth";
import Error403 from "./errorPages/Error403";
import { errorPagesConfigs } from "./errorPages";
import { accountPagesConfigs } from "./account";
import Error404 from "./errorPages/Error404";
import { dashboardPagesConfigs } from "./dashboard/routerDashBoard";
import { roleManagementPagesConfigs } from "./role/index";
import { permissionManagementPagesConfigs } from "./permission";
import { warehouseManagementPagesConfigs } from "./warehouse/router";
import { cabinetManagementPagesConfigs } from "./cabinet/router";
import { boxManagementPagesConfigs } from "./box/router";
import { identifierManagementPagesConfigs } from "./identifier/router";
import { customerManagementPagesConfigs } from "./customer/router";
import { archive_collectionManagementPagesConfigs } from "./archive_collection/router";
import { archival_recordManagementPagesConfigs } from "./archival_record/router";
import { accountManagementPagesConfigs } from "./accountManage";
import { historyManagementPagesConfigs } from "./history/router";
import { application_slipManagementPagesConfigs } from "./application_slip/router";
import { type_documentManagementPagesConfigs } from "./type_document/router";
import { clusterManagementPagesConfigs } from "./cluster/router";
import { compartmentManagementPagesConfigs } from "./compartment/router";
import { documentManagementPagesConfigs } from "./document/router";
import { group_collectionManagementPagesConfigs } from "./group_collection/router";
const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...dashboardPagesConfigs,
    ...accountPagesConfigs,
    ...roleManagementPagesConfigs,
    ...permissionManagementPagesConfigs,
    ...accountManagementPagesConfigs,
    ...warehouseManagementPagesConfigs,
    ...cabinetManagementPagesConfigs,
    ...boxManagementPagesConfigs,
    ...identifierManagementPagesConfigs,
    ...customerManagementPagesConfigs,
    ...archive_collectionManagementPagesConfigs,
    ...archival_recordManagementPagesConfigs,
    ...historyManagementPagesConfigs,
    ...application_slipManagementPagesConfigs,
    ...type_documentManagementPagesConfigs,
    ...clusterManagementPagesConfigs,
    ...compartmentManagementPagesConfigs,
    ...documentManagementPagesConfigs,
    ...group_collectionManagementPagesConfigs,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: "*",
      element: <Error404 />,
    },
  ]),
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
