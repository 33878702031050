import {
  FilterPermissionProps,
  ResponsePermissionItemConvert,
} from "interface/authencation/permission/permission";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { permissionApi } from "service/authencation/permission/permissionService";
import {
  SET_PERMISSION,
  PermissionActions,
  PermissionThunkActionTypes,
  REMOVE_PERMISSION,
  REMOVE_PERMISSION_FILTER_ITEM,
  ADD_PERMISSION_FILTER,
  SET_PERMISSION_FILTER,
} from "types/actions/authencation/Permission.actions";
import ToastMessage from "utils/notification";

export const setFilterPermissionAction = (
  payload: FilterPermissionProps,
): PermissionActions => ({
  type: SET_PERMISSION_FILTER,
  payload,
});
export const setPermissionAction = (
  payload: ResponsePermissionItemConvert[],
): PermissionActions => ({
  type: SET_PERMISSION,
  payload,
});
export const addFilterPermissionAction = (
  payload: FilterPermissionProps,
): PermissionActions => ({
  type: ADD_PERMISSION_FILTER,
  payload,
});
export const removeFilterPermissionItemAction = (
  payload: string,
): PermissionActions => ({
  type: REMOVE_PERMISSION_FILTER_ITEM,
  payload,
});
const removePermissionAction = (payload: string[]): PermissionActions => ({
  type: REMOVE_PERMISSION,
  payload,
});
// action thunk
export const fetchRemovePermissionAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, PermissionActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: PermissionThunkActionTypes.FETCH_PERMISSION_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await permissionApi.delete(listCheckbox);
      if (res?.status) {
        ToastMessage({
          type: "success",
          message: "delete success",
        });
        dispatch(removePermissionAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "delete error",
        });
      }
      // Dispatch success action if user is fetched
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: PermissionThunkActionTypes.FETCH_PERMISSION_ERROR,
        payload: error.message,
      });
    }
  };
};
