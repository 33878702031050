import {
  FilterPermissionProps,
  ResponsePermissionItem,
  ResponsePermissionItemConvert,
} from "interface/authencation/permission/permission";

export const SET_PERMISSION = "SET_PERMISSION";
export const SET_PERMISSION_FILTER = "SET_PERMISSION_FILTER";
export const ADD_PERMISSION_FILTER = "ADD_PERMISSION_FILTER";
export const REMOVE_PERMISSION_FILTER_ITEM = "REMOVE_PERMISSION_FILTER_ITEM";
export const REMOVE_PERMISSION = "REMOVE_PERMISSION";
export enum PermissionThunkActionTypes {
  FETCH_PERMISSION_REQUEST = "FETCH_PERMISSION_REQUEST",
  FETCH_PERMISSION_SUCCESS = "FETCH_PERMISSION_SUCCESS",
  FETCH_PERMISSION_ERROR = "FETCH_PERMISSION_ERROR",
}

export interface SetPermissionActions {
  type: typeof SET_PERMISSION;
  payload: ResponsePermissionItemConvert[];
}
export interface SetPermissionFilterActions {
  type: typeof SET_PERMISSION_FILTER;
  payload: FilterPermissionProps;
}
export interface RemoveFilterItemPermissionActions {
  type: typeof REMOVE_PERMISSION_FILTER_ITEM;
  payload: string;
}
export interface AddFilterPermissionActions {
  type: typeof ADD_PERMISSION_FILTER;
  payload: FilterPermissionProps;
}
export interface RemovePermissionActions {
  type: typeof REMOVE_PERMISSION;
  payload: string[];
}
interface FetchPermissionRequestAction {
  type: PermissionThunkActionTypes.FETCH_PERMISSION_REQUEST;
}

interface FetchPermissionSuccessAction {
  type: PermissionThunkActionTypes.FETCH_PERMISSION_SUCCESS;
  payload: string;
}

interface FetchPermissionErrorAction {
  type: PermissionThunkActionTypes.FETCH_PERMISSION_ERROR;
  payload: string;
}
export type PermissionActions =
  | SetPermissionActions
  | SetPermissionFilterActions
  | RemoveFilterItemPermissionActions
  | AddFilterPermissionActions
  | FetchPermissionRequestAction
  | FetchPermissionSuccessAction
  | FetchPermissionErrorAction
  | RemovePermissionActions;
