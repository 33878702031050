interface PropsIcon {
  h: number;
  w: number;
}
function RecordIcon({ w, h }: PropsIcon) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.92 7v10c0 3-1.5 5-5 5h-8c-3.5 0-5-2-5-5V7c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5z"
        stroke="#596373"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.42 4.5v2c0 1.1.9 2 2 2h2M8.92 13h4M8.92 17h8"
        stroke="#596373"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RecordIcon;
