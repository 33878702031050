// ForJWT Auth
import { useJWTAuth, useJWTAuthActions } from "hooks/JWTAuthProvider";

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user,
  };
};

export const useAuthMethod = () => {
  const { signInUser, signUpUser, logout, loginSSO } = useJWTAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
    loginSSO,
  };
};
