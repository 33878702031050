import {
  FilterRoleProps,
  FilterValueRole,
  ResponseRoleItemConvert,
} from "interface/authencation/role/role";

export const SET_ROLE = "SET_ROLE";
export const SET_ROLE_FILTER = "SET_ROLE_FILTER";
export const ADD_ROLE_FILTER = "ADD_ROLE_FILTER";
export const REMOVE_ROLE_FILTER_ITEM = "REMOVE_ROLE_FILTER_ITEM";
export const REMOVE_ROLE = "REMOVE_ROLE";
export enum RoleThunkActionTypes {
  FETCH_ROLE_REQUEST = "FETCH_ROLE_REQUEST",
  FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS",
  FETCH_ROLE_ERROR = "FETCH_ROLE_ERROR",
}
export interface SetRoleActions {
  type: typeof SET_ROLE;
  payload: ResponseRoleItemConvert[];
}
export interface SetRoleFilterActions {
  type: typeof SET_ROLE_FILTER;
  payload: FilterRoleProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_ROLE_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_ROLE_FILTER;
  payload: FilterRoleProps;
}
export interface RemoveRoleAction {
  type: typeof REMOVE_ROLE;
  payload: string[];
}
interface FetchRoleRequestAction {
  type: RoleThunkActionTypes.FETCH_ROLE_REQUEST;
}

interface FetchRoleSuccessAction {
  type: RoleThunkActionTypes.FETCH_ROLE_SUCCESS;
  payload: string;
}

interface FetchRoleErrorAction {
  type: RoleThunkActionTypes.FETCH_ROLE_ERROR;
  payload: string;
}
export type RoleActions =
  | SetRoleActions
  | SetRoleFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveRoleAction
  | FetchRoleRequestAction
  | FetchRoleSuccessAction
  | FetchRoleErrorAction;
