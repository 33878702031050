import Settings from "./Setting";
import Common from "./Common";
import RoleReducer from "./authencation/Role";
import PermissionReducer from "./authencation/Permission";
import WarehouseReducer from "./warehouse";
import CabinetReducer from "./cabinet";
import BoxReducer from "./box";
import IdentifierReducer from "./identifier";
import CustomerReducer from "./customer";
import ArchiveCollectionReducer from "./archive_collection";
import ArchivalRecordReducer from "./archival_record";
import AccountManageReducer from "./authencation/AccountManage";
import HistoryReducer from "./history";
import ApplicationSlipReducer from "./application_slip";
import TypeDocumentReducer from "./type_document";
import ClusterReducer from "./cluster";
import CompartmentReducer from "./compartment";
import DocumentReducer from "./document";
import GroupCollectionReducer from "./group_collection";
const reducers = {
  settings: Settings,
  common: Common,
  // authencation ------------
  role: RoleReducer,
  permission: PermissionReducer,
  accountManage: AccountManageReducer,
  warehouse: WarehouseReducer,
  cabinet: CabinetReducer,
  box: BoxReducer,
  identifier: IdentifierReducer,
  customer: CustomerReducer,
  archive_collection: ArchiveCollectionReducer,
  archival_record: ArchivalRecordReducer,
  history: HistoryReducer,
  application_slip: ApplicationSlipReducer,
  type_document: TypeDocumentReducer,
  cluster: ClusterReducer,
  compartment: CompartmentReducer,
  document: DocumentReducer,
  group_collection: GroupCollectionReducer,
};

export default reducers;
