import React, { ReactNode, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

interface AppContentViewWrapperProps {
  children: ReactNode;
}

const AppContentViewWrapper: React.FC<AppContentViewWrapperProps> = ({
  children,
  ...rest
}) => {
  const { pathname } = useLocation();
  const [sxStyle, setSxStyle] = useState<any>({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    mx: { xl: "auto" },
    width: { xl: "100%" },
  });
  useEffect(() => {
    if (pathname === "/signin") {
      setSxStyle({
        ...sxStyle,
        backgroundImage: 'url("/assets/images/custom/bg-login.png")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      });
    }
  }, [pathname]);

  return (
    <Box sx={sxStyle} {...rest}>
      {children}
    </Box>
  );
};

export default AppContentViewWrapper;
