import {
  FilterValueBox,
  FilterValueBoxState,
  ResponseBoxItem,
} from "interface/box";
import {
  ID,
  RM_CODE,
  CREATOR,
  CREATED_AT,
  NAME,
  SKU,
  COMPARTMENT,
  COMPARTMENT_ID,
} from "../constants/box";

export const DATA_TITLE_FILTER_BOX = {
  [RM_CODE]: "Mã",
  [NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_BOX = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên hộp",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: COMPARTMENT,
    name: "Khoang hồ sơ",
    width: 200,
  },
];
export const DATA_TITLE_BOX_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên hộp",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: COMPARTMENT,
    name: "Khoang hồ sơ",
    width: 200,
  },
];
export const FILTER_BOX = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên hộp hồ sơ",
      },
      {
        id: COMPARTMENT_ID,
        name: "Tên khoang hồ sơ",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_BOX_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_BOX: FilterValueBox = {
  [COMPARTMENT_ID]: { value: null, type: typeFilter.exactly },
  id: { value: null, type: typeFilter.exactly },
  [NAME]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataBox = (data: ResponseBoxItem[]) => {
  return data.map((x: ResponseBoxItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    compartment: x.compartment ? x.compartment.name : "Đang cập nhật",
    creator: x.creator.full_name,
  }));
};
export const FILTER_OPEN_BOX = {};
export const VALUE_FILTER_BOX: FilterValueBoxState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [COMPARTMENT_ID]: {
    value: "",
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_BOX = {
  isCreateAction: "box.create",
  isUpdateAction: "box.update",
  isDeleteAction: "box.delete",
  isReadAction: "box.getList",
};
