import {
  FilterValueGroupCollection,
  FilterValueGroupCollectionState,
  ResponseGroupCollectionItem,
} from "interface/group_collection";
import {
  ID,
  RM_CODE,
  CREATOR,
  CREATED_AT,
  NAME,
  SKU,
  IDENTIFIER,
} from "../constants/group_collection";

export const DATA_TITLE_FILTER_GROUP_COLLECTION = {
  [RM_CODE]: "Mã",
  [NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_GROUP_COLLECTION = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên tổ/nhóm",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: IDENTIFIER,
    name: "Phòng ban",
    width: 200,
  },
];
export const DATA_TITLE_GROUP_COLLECTION_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên tổ/nhóm",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: IDENTIFIER,
    name: "Phòng ban",
    width: 200,
  },
];
export const FILTER_GROUP_COLLECTION = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên tổ/nhóm",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_GROUP_COLLECTION_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // group_collectionắt đầu group_collectionằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_GROUP_COLLECTION: FilterValueGroupCollection = {
  identifier_id: { value: null, type: typeFilter.exactly },
  [NAME]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataGroupCollection = (
  data: ResponseGroupCollectionItem[],
) => {
  return data.map((x: ResponseGroupCollectionItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    creator: x.creator.full_name,
    identifier: x.identifier.name,
  }));
};
export const FILTER_OPEN_GROUP_COLLECTION = {};
export const VALUE_FILTER_GROUP_COLLECTION: FilterValueGroupCollectionState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_GROUP_COLLECTION = {
  isCreateAction: "group_collection.create",
  isUpdateAction: "group_collection.update",
  isDeleteAction: "group_collection.delete",
  isReadAction: "group_collection.getList",
};
