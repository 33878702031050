export const ID = "id";
export const RM_CODE = "rm_code";
export const CREATOR_ID = "creator";
export const CREATED_AT = "created_at";
export const SKU = "sku";
export const FOND_NAME = "fond_name";
export const FOND_HISTORY = "fond_history";
export const ARCHIVE_TIME = "archive_time";
export const PAPER_TOTAL = "paper_total";
export const PAPER_DIGITAL = "paper_digital";
export const KEY_GROUP = "key_group";
export const OTHER_TYPE = "other_type";
export const LANGUAGE = "language";
export const LOOKUP_TOOL = "lookup_tool";
export const COPY_NUMBER = "copy_number";
export const IDENTIFIER = "identifier";
export const GROUP_COLLECTION = "group_collection";
