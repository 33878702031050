import {
  FilterHistoryProps,
  ResponseHistoryItemConvert,
} from "interface/history";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { historyApi } from "service/history";
import {
  ADD_HISTORY_FILTER,
  REMOVE_HISTORY,
  REMOVE_HISTORY_FILTER_ITEM,
  HistoryActions,
  SET_HISTORY,
  SET_HISTORY_FILTER,
  HistoryThunkActionTypes,
} from "types/actions/history";
import ToastMessage from "utils/notification";

export const setFilterHistoryAction = (
  payload: FilterHistoryProps,
): HistoryActions => ({
  type: SET_HISTORY_FILTER,
  payload,
});
export const setHistoryAction = (
  payload: ResponseHistoryItemConvert[],
): HistoryActions => ({
  type: SET_HISTORY,
  payload,
});
export const addFilterHistoryAction = (
  payload: FilterHistoryProps,
): HistoryActions => ({
  type: ADD_HISTORY_FILTER,
  payload,
});
export const removeFilterHistoryItemAction = (
  payload: string,
): HistoryActions => ({
  type: REMOVE_HISTORY_FILTER_ITEM,
  payload,
});
const removeHistoryAction = (payload: string[]): HistoryActions => ({
  type: REMOVE_HISTORY,
  payload,
});
// action thunk
export const fetchRemoveHistoryAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, HistoryActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: HistoryThunkActionTypes.FETCH_HISTORY_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await historyApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa tủ hồ sơ thành công",
        });
        dispatch(removeHistoryAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa tủ hồ sơ thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: HistoryThunkActionTypes.FETCH_HISTORY_ERROR,
        payload: error.message,
      });
    }
  };
};
