export const RM_CODE = "rm_code";
export const CREATOR = "creator";
export const CREATED_AT = "created_at";
export const NAME = "name";
export const SKU = "sku";
export const COORDINATES = "coordinates";
export const CABINET = "cabinet";
export const CABINET_ID = "cabinet_id";
export const WAREHOUSE = "warehouse";
export const WAREHOUSE_ID = "warehouse_id";
export const CLUSTER = "cluster";
export const CLUSTER_ID = "cluster_id";
