import React from "react";
import { useThemeContext } from "../../../../utility/AppContextProvider/ThemeContextProvider";
import { alpha, Box, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../../../../assets/icon/logo.svg";
import { ReactComponent as LogoText } from "../../../../../assets/icon/logo_text.svg";
import { useNavigate } from "react-router-dom";

interface AppLogoProps {
  isText?: string;
  color?: string;
  size?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ color, isText, size }) => {
  const { theme } = useThemeContext();
  const naviagte = useNavigate();
  return (
    <Box
      sx={{
        height: size ? size : { xs: 52, sm: 52 },
        padding: isText ? "10px 0px 10px 18px" : "4px 8px",
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "start",
        ">img": { marginRight: "44px" },
        "& svg": {
          height: { xs: 40, sm: 45 },
        },
        "> p": {
          fontSize: "20px",
          fontWeight: "bold",
        },
      }}
      className="app-logo"
    >
      {isText ? (
        <Typography
          component="b"
          sx={{ color: "var(--bgCore)", fontSize: "20px", fontWeight: "700" }}
          onClick={() => {
            naviagte("/");
          }}
        >
          {isText}
        </Typography>
      ) : (
        <img
          style={{ height: "100%" }}
          src={"/assets/images/logo.png"}
          alt="gcity-logo"
        ></img>
      )}
    </Box>
  );
};

export default AppLogo;
