import React from "react";
import Redirect from './Redirect/Redirect';

const Signin = React.lazy(() => import("./Signin"));
const Signup = React.lazy(() => import("./Signup"));
const ForgotPassword = React.lazy(() => import("./ForgetPassword"));
const ResetPasswordAwsCognito = React.lazy(
  () => import("./ResetPasswordAwsCognito")
);

export const authRouteConfig = [
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/redirect",
    element: <Redirect />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordAwsCognito />,
  },
];
