import React from "react";
import { RoutePermittedRole } from "constants/AppConst";

const UserProfile = React.lazy(() => import("./profile/UserProfile"));
const ChangePassword = React.lazy(
  () => import("./profile/UserProfile/ChangePassword"),
);
const ResetPassword = React.lazy(
  () => import("./profile/UserProfile/ResetPassword"),
);
export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/my-account",
    element: <UserProfile />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: "/reset-password",
    element: <ResetPassword />,
  },
];
