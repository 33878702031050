import {
  FilterValueWarehouse,
  FilterValueWarehouseState,
  ResponseWarehouseItem,
} from "interface/warehouse";
import {
  ID,
  RM_CODE,
  CREATED_AT,
  NAME,
  CABINET,
  CREATOR,
  NOTE,
  POSITION,
} from "utils/constants/warehouse";

export const DATA_TITLE_FILTER_WAREHOUSE = {
  [RM_CODE]: "Mã",
  [NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_WAREHOUSE = [
  {
    id: RM_CODE,
    name: "mã",
    width: 200,
  },
  {
    id: NAME,
    name: "tên kho",
    width: 200,
  },

  {
    id: CREATOR,
    name: "Người tạo kho",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: POSITION,
    name: "vị trí",
    width: 200,
  },
  {
    id: NOTE,
    name: "Ghi chú",
    width: 200,
  },
];
export const DATA_TITLE_WAREHOUSE_DEFAULT = [
  {
    id: RM_CODE,
    name: "mã",
    width: 200,
  },
  {
    id: NAME,
    name: "tên kho",
    width: 200,
  },
  {
    id: POSITION,
    name: "vị trí",
    width: 200,
  },
  {
    id: CREATOR,
    name: "Người tạo kho",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "ngày tạo",
    width: 200,
  },
];
export const FILTER_WAREHOUSE = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên kho",
      },
      {
        id: POSITION,
        name: "Vị trí",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_WAREHOUSE_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_WAREHOUSE: FilterValueWarehouse = {
  [NAME]: { value: null, type: typeFilter.exactly },
  [POSITION]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataWarehouse = (data: ResponseWarehouseItem[]) => {
  return data.map((x: ResponseWarehouseItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    creator: x.creator.full_name,
    // note: x.note.length ? x.note : "-",
    position: x.position === null ? "-" : x.position.length ? x.position : "-",
    note: x.note === null ? "-" : x.note.length ? x.note : "-",
  }));
};
export const FILTER_OPEN_WAREHOUSE = {};
export const VALUE_FILTER_WAREHOUSE: FilterValueWarehouseState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [POSITION]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_WAREHOUSE = {
  isCreateAction: "warehouse.create",
  isUpdateAction: "warehouse.update",
  isDeleteAction: "warehouse.delete",
  isReadAction: "warehouse.getList",
};
