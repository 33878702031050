import {
  FilterGroupCollectionProps,
  ResponseGroupCollectionItemConvert,
} from "interface/group_collection";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { group_collectionApi } from "service/group_collection";
import {
  ADD_GROUP_COLLECTION_FILTER,
  REMOVE_GROUP_COLLECTION,
  REMOVE_GROUP_COLLECTION_FILTER_ITEM,
  GroupCollectionActions,
  SET_GROUP_COLLECTION,
  SET_GROUP_COLLECTION_FILTER,
  GroupCollectionThunkActionTypes,
} from "types/actions/group_collection";
import ToastMessage from "utils/notification";

export const setFilterGroupCollectionAction = (
  payload: FilterGroupCollectionProps,
): GroupCollectionActions => ({
  type: SET_GROUP_COLLECTION_FILTER,
  payload,
});
export const setGroupCollectionAction = (
  payload: ResponseGroupCollectionItemConvert[],
): GroupCollectionActions => ({
  type: SET_GROUP_COLLECTION,
  payload,
});
export const addFilterGroupCollectionAction = (
  payload: FilterGroupCollectionProps,
): GroupCollectionActions => ({
  type: ADD_GROUP_COLLECTION_FILTER,
  payload,
});
export const removeFilterGroupCollectionItemAction = (
  payload: string,
): GroupCollectionActions => ({
  type: REMOVE_GROUP_COLLECTION_FILTER_ITEM,
  payload,
});
const removeGroupCollectionAction = (
  payload: string[],
): GroupCollectionActions => ({
  type: REMOVE_GROUP_COLLECTION,
  payload,
});
// action thunk
export const fetchRemoveGroupCollectionAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, GroupCollectionActions> => {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupCollectionThunkActionTypes.FETCH_GROUP_COLLECTION_REQUEST,
    });
    try {
      // Make an asynchronous API call
      const res = await group_collectionApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa tổ/nhóm thành công",
        });
        dispatch(removeGroupCollectionAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa tổ/nhóm thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: GroupCollectionThunkActionTypes.FETCH_GROUP_COLLECTION_ERROR,
        payload: error.message,
      });
    }
  };
};
