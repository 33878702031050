export const ID = "id";
export const RM_CODE = "rm_code";
export const CREATOR = "creator";
export const CREATED_AT = "created_at";
export const SKU = "sku";
export const STATUS = "status";
export const NAME = "name";
export const BORROW_DATE = "borrow_date";
export const RETURN_DATE = "return_date";

export const BOX_ID = "box_id";
export const BOX = "box";
export const COMPARTMENT = "compartment";
export const COMPARTMENT_ID = "compartment_id";
export const CABINET = "cabinet";
export const CABINET_ID = "cabinet_id";
export const WAREHOUSE = "warehouse";
export const WAREHOUSE_ID = "warehouse_id";
export const CLUSTER = "cluster";
export const CLUSTER_ID = "cluster_id";
