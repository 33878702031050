import React, { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import { Stack } from "@mui/material";

const StyledSimpleBarReact = styled(SimpleBarReact)(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  " .simplebar-content-wrapper::-webkit-scrollbar": {
    width: "6px",
    height: "8px",
    borderRadius: "4px",
  },
  " .simplebar-content-wrapper::-webkit-scrollbar-track": {
    background: "#f1f2f4",
  },
  " .simplebar-content-wrapper::-webkit-scrollbar-thumb": {
    cursor: "pointer",
    borderRadius: "6px",
    background: "#f27e73",
  },
  " .simplebar-content-wrapper::-webkit-scrollbar-thumb:hover": {
    background: "#f27e73",
  },
}));

interface AppScrollbarProps {
  children: ReactNode;
  className?: string;
  [x: string]: any;
}

const AppScrollbar: React.FC<AppScrollbarProps> = (props) => {
  const { children, ...others } = props;

  return <StyledSimpleBarReact {...others}>{children}</StyledSimpleBarReact>;
};

export default AppScrollbar;
