import {
  ADD_ROLE_FILTER,
  REMOVE_ROLE,
  REMOVE_ROLE_FILTER_ITEM,
  RoleActions,
  SET_ROLE,
  SET_ROLE_FILTER,
} from "types/actions/authencation/Role.actions";
import { RoleState } from "types/models/authencation/Role";
import { DEFAULT_FILTER_ROLE } from "utils/data-template-table/authencation/data-role";
const INIT_STATE: RoleState = {
  filter: { value: DEFAULT_FILTER_ROLE, type: "all", title: "tất cả" },
  filters: [{ value: DEFAULT_FILTER_ROLE, type: "all", title: "tất cả" }],
  roles: [],
};

const RoleReducer = (state = INIT_STATE, action: RoleActions) => {
  switch (action.type) {
    case SET_ROLE: {
      return {
        ...state,
        roles: action.payload,
      };
    }
    case SET_ROLE_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_ROLE_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_ROLE[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_ROLE_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_ROLE: {
      const listRoleRemove = action.payload;
      const newRoles = state.roles.filter(
        (it) => !listRoleRemove.includes(it.oc_code),
      );
      return {
        ...state,
        roles: newRoles,
      };
    }
    default:
      return state;
  }
};
export default RoleReducer;
