import { ROUTER_DASHBOARD } from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
import Redirect from "../auth/Redirect/Redirect";
const Dashboard = React.lazy(() => import("./index"));
export const dashboardPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ROUTER_DASHBOARD,
    element: <Dashboard />,
  },
];
