import { HISTORY, HISTORY_EDIT, HISTORY_CREATE } from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const History = React.lazy(() => import("./index"));
// const EditPage = React.lazy(() => import("./edit/index"));
export const historyManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: HISTORY,
    element: <History />,
  },
  // {
  //   permittedRole: RoutePermittedRole.Admin,
  //   path: HISTORY_EDIT,
  //   element: <EditPage />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.Admin,
  //   path: HISTORY_CREATE,
  //   element: <EditPage />,
  // },
];
