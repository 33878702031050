import {
  FilterTypeDocumentProps,
  ResponseTypeDocumentItemConvert,
} from "interface/type_document";

export const SET_TYPE_DOCUMENT = "SET_TYPE_DOCUMENT";
export const SET_TYPE_DOCUMENT_FILTER = "SET_TYPE_DOCUMENT_FILTER";
export const ADD_TYPE_DOCUMENT_FILTER = "ADD_TYPE_DOCUMENT_FILTER";
export const REMOVE_TYPE_DOCUMENT_FILTER_ITEM =
  "REMOVE_TYPE_DOCUMENT_FILTER_ITEM";
export const REMOVE_TYPE_DOCUMENT = "REMOVE_TYPE_DOCUMENT";
export enum TypeDocumentThunkActionTypes {
  FETCH_TYPE_DOCUMENT_REQUEST = "FETCH_TYPE_DOCUMENT_REQUEST",
  FETCH_TYPE_DOCUMENT_SUCCESS = "FETCH_TYPE_DOCUMENT_SUCCESS",
  FETCH_TYPE_DOCUMENT_ERROR = "FETCH_TYPE_DOCUMENT_ERROR",
}
export interface SetTypeDocumentActions {
  type: typeof SET_TYPE_DOCUMENT;
  payload: ResponseTypeDocumentItemConvert[];
}
export interface SetTypeDocumentFilterActions {
  type: typeof SET_TYPE_DOCUMENT_FILTER;
  payload: FilterTypeDocumentProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_TYPE_DOCUMENT_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_TYPE_DOCUMENT_FILTER;
  payload: FilterTypeDocumentProps;
}
export interface RemoveTypeDocumentAction {
  type: typeof REMOVE_TYPE_DOCUMENT;
  payload: string[];
}
interface FetchTypeDocumentRequestAction {
  type: TypeDocumentThunkActionTypes.FETCH_TYPE_DOCUMENT_REQUEST;
}

interface FetchTypeDocumentSuccessAction {
  type: TypeDocumentThunkActionTypes.FETCH_TYPE_DOCUMENT_SUCCESS;
  payload: string;
}

interface FetchTypeDocumentErrorAction {
  type: TypeDocumentThunkActionTypes.FETCH_TYPE_DOCUMENT_ERROR;
  payload: string;
}
export type TypeDocumentActions =
  | SetTypeDocumentActions
  | SetTypeDocumentFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveTypeDocumentAction
  | FetchTypeDocumentRequestAction
  | FetchTypeDocumentSuccessAction
  | FetchTypeDocumentErrorAction;
