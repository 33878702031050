import {
  FilterCompartmentProps,
  ResponseCompartmentItemConvert,
} from "interface/compartment";

export const SET_COMPARTMENT = "SET_COMPARTMENT";
export const SET_COMPARTMENT_FILTER = "SET_COMPARTMENT_FILTER";
export const ADD_COMPARTMENT_FILTER = "ADD_COMPARTMENT_FILTER";
export const REMOVE_COMPARTMENT_FILTER_ITEM = "REMOVE_COMPARTMENT_FILTER_ITEM";
export const REMOVE_COMPARTMENT = "REMOVE_COMPARTMENT";
export enum CompartmentThunkActionTypes {
  FETCH_COMPARTMENT_REQUEST = "FETCH_COMPARTMENT_REQUEST",
  FETCH_COMPARTMENT_SUCCESS = "FETCH_COMPARTMENT_SUCCESS",
  FETCH_COMPARTMENT_ERROR = "FETCH_COMPARTMENT_ERROR",
}
export interface SetCompartmentActions {
  type: typeof SET_COMPARTMENT;
  payload: ResponseCompartmentItemConvert[];
}
export interface SetCompartmentFilterActions {
  type: typeof SET_COMPARTMENT_FILTER;
  payload: FilterCompartmentProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_COMPARTMENT_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_COMPARTMENT_FILTER;
  payload: FilterCompartmentProps;
}
export interface RemoveCompartmentAction {
  type: typeof REMOVE_COMPARTMENT;
  payload: string[];
}
interface FetchCompartmentRequestAction {
  type: CompartmentThunkActionTypes.FETCH_COMPARTMENT_REQUEST;
}

interface FetchCompartmentSuccessAction {
  type: CompartmentThunkActionTypes.FETCH_COMPARTMENT_SUCCESS;
  payload: string;
}

interface FetchCompartmentErrorAction {
  type: CompartmentThunkActionTypes.FETCH_COMPARTMENT_ERROR;
  payload: string;
}
export type CompartmentActions =
  | SetCompartmentActions
  | SetCompartmentFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveCompartmentAction
  | FetchCompartmentRequestAction
  | FetchCompartmentSuccessAction
  | FetchCompartmentErrorAction;
