import {
  FilterCabinetProps,
  ResponseCabinetItemConvert,
} from "interface/cabinet";

export const SET_CABINET = "SET_CABINET";
export const SET_CABINET_FILTER = "SET_CABINET_FILTER";
export const ADD_CABINET_FILTER = "ADD_CABINET_FILTER";
export const REMOVE_CABINET_FILTER_ITEM = "REMOVE_CABINET_FILTER_ITEM";
export const REMOVE_CABINET = "REMOVE_CABINET";
export enum CabinetThunkActionTypes {
  FETCH_CABINET_REQUEST = "FETCH_CABINET_REQUEST",
  FETCH_CABINET_SUCCESS = "FETCH_CABINET_SUCCESS",
  FETCH_CABINET_ERROR = "FETCH_CABINET_ERROR",
}
export interface SetCabinetActions {
  type: typeof SET_CABINET;
  payload: ResponseCabinetItemConvert[];
}
export interface SetCabinetFilterActions {
  type: typeof SET_CABINET_FILTER;
  payload: FilterCabinetProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_CABINET_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_CABINET_FILTER;
  payload: FilterCabinetProps;
}
export interface RemoveCabinetAction {
  type: typeof REMOVE_CABINET;
  payload: string[];
}
interface FetchCabinetRequestAction {
  type: CabinetThunkActionTypes.FETCH_CABINET_REQUEST;
}

interface FetchCabinetSuccessAction {
  type: CabinetThunkActionTypes.FETCH_CABINET_SUCCESS;
  payload: string;
}

interface FetchCabinetErrorAction {
  type: CabinetThunkActionTypes.FETCH_CABINET_ERROR;
  payload: string;
}
export type CabinetActions =
  | SetCabinetActions
  | SetCabinetFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveCabinetAction
  | FetchCabinetRequestAction
  | FetchCabinetSuccessAction
  | FetchCabinetErrorAction;
