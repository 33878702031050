import {
  RM_CODE,
  CREATOR_ID,
  CREATED_AT,
  ID,
  SKU,
  ADDRESS,
  NAME,
} from "../constants/identifier";
import {
  FilterValueIdentifier,
  FilterValueIdentifierState,
  ResponseIdentifierItem,
} from "interface/identifier";

export const DATA_TITLE_FILTER_IDENTIFIER = {
  [RM_CODE]: "Mã",
  [NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_IDENTIFIER = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên phòng ban",
    width: 400,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: ADDRESS,
    name: "Địa chỉ",
    width: 200,
  },
];
export const DATA_TITLE_IDENTIFIER_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên phòng ban",
    width: 400,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: ADDRESS,
    name: "Địa chỉ",
    width: 200,
  },
];
export const FILTER_IDENTIFIER = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên phòng ban",
      },
      {
        id: ADDRESS,
        name: "Vị trí",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_IDENTIFIER_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_IDENTIFIER: FilterValueIdentifier = {
  [NAME]: { value: null, type: typeFilter.exactly },
  [ADDRESS]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataIdentifier = (data: ResponseIdentifierItem[]) => {
  return data.map((x: ResponseIdentifierItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
  }));
};
export const FILTER_OPEN_IDENTIFIER = {};
export const VALUE_FILTER_IDENTIFIER: FilterValueIdentifierState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [ADDRESS]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_IDENTIFIER = {
  isCreateAction: "identifier.create",
  isUpdateAction: "identifier.update",
  isDeleteAction: "identifier.delete",
  isReadAction: "identifier.getList",
};
