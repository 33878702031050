import {
  FilterWarehouseProps,
  ResponseWarehouseItemConvert,
} from "interface/warehouse";

export const SET_WAREHOUSE = "SET_WAREHOUSE";
export const SET_WAREHOUSE_FILTER = "SET_WAREHOUSE_FILTER";
export const ADD_WAREHOUSE_FILTER = "ADD_WAREHOUSE_FILTER";
export const REMOVE_WAREHOUSE_FILTER_ITEM = "REMOVE_WAREHOUSE_FILTER_ITEM";
export const REMOVE_WAREHOUSE = "REMOVE_WAREHOUSE";
export enum WarehouseThunkActionTypes {
  FETCH_WAREHOUSE_REQUEST = "FETCH_WAREHOUSE_REQUEST",
  FETCH_WAREHOUSE_SUCCESS = "FETCH_WAREHOUSE_SUCCESS",
  FETCH_WAREHOUSE_ERROR = "FETCH_WAREHOUSE_ERROR",
}
export interface SetWarehouseActions {
  type: typeof SET_WAREHOUSE;
  payload: ResponseWarehouseItemConvert[];
}
export interface SetWarehouseFilterActions {
  type: typeof SET_WAREHOUSE_FILTER;
  payload: FilterWarehouseProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_WAREHOUSE_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_WAREHOUSE_FILTER;
  payload: FilterWarehouseProps;
}
export interface RemoveWarehouseAction {
  type: typeof REMOVE_WAREHOUSE;
  payload: string[];
}
interface FetchWarehouseRequestAction {
  type: WarehouseThunkActionTypes.FETCH_WAREHOUSE_REQUEST;
}

interface FetchWarehouseSuccessAction {
  type: WarehouseThunkActionTypes.FETCH_WAREHOUSE_SUCCESS;
  payload: string;
}

interface FetchWarehouseErrorAction {
  type: WarehouseThunkActionTypes.FETCH_WAREHOUSE_ERROR;
  payload: string;
}
export type WarehouseActions =
  | SetWarehouseActions
  | SetWarehouseFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveWarehouseAction
  | FetchWarehouseRequestAction
  | FetchWarehouseSuccessAction
  | FetchWarehouseErrorAction;
