import React, { useCallback, useEffect, useState, ChangeEvent } from "react";
import List from "@mui/material/List";
import {
  Box,
  Button,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import routesConfig, {
  RouterConfigData,
} from "../../../../../pages/routesConfig";
import NavVerticalGroup from "./VerticalNavGroup";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import VerticalCustom from "./VerticalCustom";
import AppDialog from "@ikisoft/core/AppDialog";
// import { apartmentApi } from "service/apartment";
import { useDispatch, useSelector } from "react-redux";
// import {
//   ResponseApartmentDetail,
//   ResponseApartmentItem,
// } from "interface/apartment";
// import { MetadataRes } from "interface/common/common";
// import { setApartmentAction, setApartmentItemAction } from "redux/actions";
// import { apartmentItemSelect } from "redux/selectors/apartment.selector";
import { DialogSelect } from "./VerticalCustom/dialog-select";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import { ResponseProvinceItem } from "interface/province";
// import { provinceApi } from "service/province";

const VerticalNav = () => {
  const [openDialog, setOpenDialog] = useState(false);
  // const [listApartment, setlistApartment] = useState<ResponseApartmentItem[]>();
  const [listApartment, setlistApartment] = useState<any[]>();
  const [listProvince, setListProvince] =
    useState<{ id: number; name: string }[]>();
  const dispatch = useDispatch();
  // const apartmentItem = useSelector(apartmentItemSelect);
  const [formData, setFormData] = useState({
    province: 0,
    apartment: 0,
  });

  const handleOnchange = (
    e:
      | ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<number>
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    // Nếu người dùng thay đổi tỉnh/thành phố, đặt apartment về 0
    const numericValue = value !== 0 ? +value : 0;
    if (name === "province") {
      setFormData({ province: numericValue, apartment: 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const fetchGetApartment = useCallback((params: any, isSet: boolean) => {
    // apartmentApi
    //   .get(params)
    //   .then((res: { data: ResponseApartmentItem[]; metadata: MetadataRes }) => {
    //     setlistApartment(res.data);
    //     const last_item_id = res.data[res.data.length - 1].gc_code;
    //     apartmentApi
    //       .getDetail(last_item_id)
    //       .then(
    //         (response: {
    //           statusCode: number;
    //           status: boolean;
    //           error: any[];
    //           data: ResponseApartmentDetail;
    //         }) => {
    //           const item = {
    //             id: response.data.id,
    //             province_id: response.data.province.id,
    //             gc_code: response.data.gc_code,
    //             name: response.data.name,
    //             count_floor: response.data.count_foor,
    //             list_room_id:
    //               response.data.room.length > 0
    //                 ? response.data.room.map((item) => item.id)
    //                 : [],
    //             list_utilities_id:
    //               response.data.apartment_utilities.length > 0
    //                 ? response.data.apartment_utilities.map(
    //                     (item) => item.utilities.id,
    //                   )
    //                 : [],
    //           };
    //           if (isSet) {
    //             dispatch(setApartmentItemAction(item));
    //             setFormData({
    //               province: item.province_id,
    //               apartment: item.id,
    //             });
    //             localStorage.setItem(
    //               "apartmentLocalStorage",
    //               JSON.stringify(item),
    //             );
    //           }
    //         },
    //       )
    //       .catch((error) => console.log(error));
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }, []);

  const fetchGetProvince = useCallback((params: any) => {
    // provinceApi
    //   .get(params)
    //   .then((res: { data: ResponseProvinceItem[]; metadata: MetadataRes }) => {
    //     const data = res.data.map((item) => ({ id: item.id, name: item.name }));
    //     setListProvince(data);
    //     return data;
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }, []);

  useEffect(() => {
    // const apartmentLocalStorage = localStorage.getItem("apartmentLocalStorage");
    // if (apartmentLocalStorage) {
    //   const apartment = JSON.parse(apartmentLocalStorage);
    //   setFormData({
    //     province: apartment.province_id,
    //     apartment: apartment.id,
    //   });
    // }
  }, []);

  useEffect(() => {
    // if (apartmentItem.name === "") {
    //   const apartmentLocalStorage = localStorage.getItem(
    //     "apartmentLocalStorage",
    //   );
    //   if (!apartmentLocalStorage) {
    //     fetchGetApartment({ page: 1, page_size: 999 }, true);
    //   } else {
    //     const apartment = JSON.parse(apartmentLocalStorage);
    //     dispatch(setApartmentItemAction(apartment));
    //   }
    //   // fetchGetApartment({ page: 1, page_size: 999 });
    // }
    // fetchGetApartment({ page: 1, page_size: 999 }, false);
    // fetchGetProvince({ page: 1, page_size: 999 });
  }, [openDialog]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // e.preventDefault();
    // const gc_code_select = listApartment.filter(
    //   (item) => item.id === formData.apartment,
    // )[0].gc_code;
    // apartmentApi
    //   .getDetail(gc_code_select)
    //   .then(
    //     (response: {
    //       statusCode: number;
    //       status: boolean;
    //       error: any[];
    //       data: ResponseApartmentDetail;
    //     }) => {
    //       dispatch(
    //         setApartmentItemAction({
    //           id: response.data.id,
    //           province_id: response.data.province.id,
    //           gc_code: response.data.gc_code,
    //           name: response.data.name,
    //           count_floor: response.data.count_foor,
    //           list_room_id:
    //             response.data.room.length > 0
    //               ? response.data.room.map((item) => item.id)
    //               : [],
    //           list_utilities_id:
    //             response.data.apartment_utilities.length > 0
    //               ? response.data.apartment_utilities.map(
    //                   (item) => item.utilities.id,
    //                 )
    //               : [],
    //         }),
    //       );
    //       localStorage.setItem(
    //         "apartmentLocalStorage",
    //         JSON.stringify({
    //           id: response.data.id,
    //           province_id: response.data.province.id,
    //           gc_code: response.data.gc_code,
    //           name: response.data.name,
    //           count_floor: response.data.count_foor,
    //           list_room_id:
    //             response.data.room.length > 0
    //               ? response.data.room.map((item) => item.id)
    //               : [],
    //           list_utilities_id:
    //             response.data.apartment_utilities.length > 0
    //               ? response.data.apartment_utilities.map(
    //                   (item) => item.utilities.id,
    //                 )
    //               : [],
    //         }),
    //       );
    //       setOpenDialog(false);
    //     },
    //   )
    //   .catch((e) => console.log(e));
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <List
      sx={{
        position: "relative",
        padding: 0,
      }}
      component="div"
    >
      <AppDialog open={openDialog} onClose={handleClose}>
        <div>
          <h2 className="text-center text-[#434A56] font-semibold text-4xl leading-10 py-12">
            Chọn 1 nhà và đi tới quản lý
          </h2>
          <div className="pb-6">
            <DialogSelect
              dataUi={{ inputWidth: "100%" }}
              validate={{
                isError: false,
                notification: "vui lòng chọn khu vực",
              }}
              name="province"
              width="100%"
              height="40px"
              value={formData.province}
              handleOnchange={handleOnchange}
              listOption={listProvince}
              contentPlaceholder={"Chọn Khu Vực"}
            />
          </div>
          <div className="pb-12">
            <DialogSelect
              dataUi={{ inputWidth: "100%" }}
              validate={{
                isError: false,
                notification: "vui lòng chọn khu vực",
              }}
              name="apartment"
              width="100%"
              height="40px"
              disable={
                formData.province === 0 ||
                (listApartment &&
                  listApartment.filter(
                    (item) => item.province_id === formData.province,
                  ).length <= 0)
                  ? true
                  : false
              }
              value={formData.province !== 0 ? formData.apartment : 0}
              handleOnchange={handleOnchange}
              listOption={
                listApartment &&
                listApartment.filter(
                  (item) => item.province_id === formData.province,
                )
              }
              contentPlaceholder={"Chọn nhà"}
            />
          </div>
          <div className="action flex justify-end pb-7">
            <button
              className={`flex items-center justify-center text-[#fff] 
              gap-2 rounded w-[94px] h-[40px]
              ${
                listApartment &&
                listApartment.filter(
                  (item) => item.province_id === formData.province,
                ).length > 0 &&
                formData.apartment !== 0
                  ? "bg-[#2A5EA2] hover:bg-[#2A5EA2  ]"
                  : "bg-[#ccc]"
              }`}
              onClick={(e) => {
                if (formData.province !== 0) {
                  if (
                    listApartment &&
                    listApartment.filter(
                      (item) => item.province_id === formData.province,
                    ).length > 0
                  ) {
                    handleSubmit(e);
                  }
                }
              }}
              disabled={
                listApartment &&
                listApartment.filter(
                  (item) => item.province_id === formData.province,
                ).length > 0 &&
                formData.apartment !== 0
                  ? false
                  : true
              }
            >
              Đi tới <KeyboardArrowRightIcon className="w-[20px] h-[20px]" />
            </button>
          </div>
        </div>
      </AppDialog>
      {routesConfig.map((item: RouterConfigData) => (
        <React.Fragment key={item.id}>
          {item.type === "group" && <NavVerticalGroup item={item} level={10} />}

          {item.type === "collapse" && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === "item" && <VerticalItem item={item} level={10} />}
          {item.type === "item-bold" && (
            <VerticalItem item={item} level={10} isBold={true} />
          )}

          {/* custom sidebar */}
          {item.type === "custom-1" && <VerticalItem item={item} level={30} />}
          {item.type === "custom-2" && (
            <div
            // onClick={() => setOpenDialog(true)}
            >
              <VerticalCustom item={item} level={10} isBold={true} />
            </div>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default VerticalNav;
