import {
  ID,
  RM_CODE,
  CREATOR,
  CREATED_AT,
  SKU,
  STATUS,
  NAME,
  BORROW_DATE,
  RETURN_DATE,
  BOX_ID,
} from "../constants/archival_record";
import {
  FilterValueArchivalRecord,
  FilterValueArchivalRecordState,
  ResponseArchivalRecordItem,
} from "interface/archival_record";

export const DATA_TITLE_FILTER_ARCHIVAL_RECORD = {
  [RM_CODE]: "Mã",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_ARCHIVAL_RECORD = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên hồ sơ",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên hồ sơ",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  //
  {
    id: STATUS,
    name: "Trạng thái hồ sơ",
    width: 200,
  },
];
export const DATA_TITLE_ARCHIVAL_RECORD_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },

  {
    id: NAME,
    name: "Tên hồ sơ",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  //
  {
    id: STATUS,
    name: "Trạng thái hồ sơ",
    width: 200,
  },
];
export const FILTER_ARCHIVAL_RECORD = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên hồ sơ",
      },
      {
        id: BOX_ID,
        name: "Tên hộp hồ sơ",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_ARCHIVAL_RECORD_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_ARCHIVAL_RECORD: FilterValueArchivalRecord = {
  id: { value: null, type: typeFilter.exactly },
  status: { value: null, type: typeFilter.exactly },
  [BOX_ID]: { value: null, type: typeFilter.exactly },
  [NAME]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataArchivalRecord = (
  data: ResponseArchivalRecordItem[],
) => {
  return data.map((x: ResponseArchivalRecordItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    creator: x.creator.full_name,
    status: x.status ? "Đã cho mượn" : "Trong kho",
    archival_record_application_slip: "archival_record_application_slip",
  }));
};
export const FILTER_OPEN_ARCHIVAL_RECORD = {};
export const VALUE_FILTER_ARCHIVAL_RECORD: FilterValueArchivalRecordState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [BOX_ID]: {
    value: "",
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_ARCHIVAL_RECORD = {
  isCreateAction: "archive_record.create",
  isUpdateAction: "archive_record.update",
  isDeleteAction: "archive_record.delete",
  isReadAction: "archive_record.getList",
};
