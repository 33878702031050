import { FilterBoxProps, ResponseBoxItemConvert } from "interface/box";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { boxApi } from "service/box";
import {
  ADD_BOX_FILTER,
  REMOVE_BOX,
  REMOVE_BOX_FILTER_ITEM,
  BoxActions,
  SET_BOX,
  SET_BOX_FILTER,
  BoxThunkActionTypes,
} from "types/actions/box";
import ToastMessage from "utils/notification";

export const setFilterBoxAction = (payload: FilterBoxProps): BoxActions => ({
  type: SET_BOX_FILTER,
  payload,
});
export const setBoxAction = (
  payload: ResponseBoxItemConvert[],
): BoxActions => ({
  type: SET_BOX,
  payload,
});
export const addFilterBoxAction = (payload: FilterBoxProps): BoxActions => ({
  type: ADD_BOX_FILTER,
  payload,
});
export const removeFilterBoxItemAction = (payload: string): BoxActions => ({
  type: REMOVE_BOX_FILTER_ITEM,
  payload,
});
const removeBoxAction = (payload: string[]): BoxActions => ({
  type: REMOVE_BOX,
  payload,
});
// action thunk
export const fetchRemoveBoxAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, BoxActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: BoxThunkActionTypes.FETCH_BOX_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await boxApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa tủ hồ sơ thành công",
        });
        dispatch(removeBoxAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa tủ hồ sơ thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: BoxThunkActionTypes.FETCH_BOX_ERROR,
        payload: error.message,
      });
    }
  };
};
