interface PropsIcon {
  h: number;
  w: number;
}
function BoxIcon({ w, h }: PropsIcon) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.42 10.22V19c0 2-.5 3-3 3h-9c-2.5 0-3-1-3-3v-8.78M5.92 2h14c2 0 3 1 3 3v2c0 2-1 3-3 3h-14c-2 0-3-1-3-3V5c0-2 1-3 3-3zM11.1 14h3.64"
        stroke="#596373"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BoxIcon;
