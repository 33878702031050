export interface LanguageProps {
  languageId: string;
  locale: string;
  name: string;
}

const languageData: LanguageProps[] = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
  },
  {
    languageId: "vietnamese",
    locale: "vi",
    name: "Vietnamese",
  },
];
export default languageData;
