
import { UserManager } from 'oidc-client';

const config = {
  authority: "http://10.0.180.80:5001",
  client_id: "apecfinance",
  redirect_uri: "http://10.0.180.80:2003/redirect",
  response_type: "id_token token",
  scope: "openid profile ApiApecFinance",
  post_logout_redirect_uri: "http://10.0.180.80:2003/signin",
  automaticSilentRenew: true
};

const userManager = new UserManager(config)

export function signinRedirect() {
  return userManager.signinRedirect()
}
export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}
export function signoutRedirect() {
  return userManager.signoutRedirect()
}
export function signoutRedirectCallback() {
  return userManager.signoutRedirectCallback()
}

export default userManager




