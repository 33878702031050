// dashboards
export const ROUTER_DASHBOARD = "/dashboard";
//management account
export const ROUTER_ACCOUNT_MANAGE = "/account";
export const ROUTER_ACCOUNT_MANAGE_CREATE = "/account/create";
export const ROUTER_ACCOUNT_MANAGE_UPDATE = "/account/edit/:rm_code";
// role management
export const ROUTER_ROLE = "/role";
export const ROUTER_ROLE_CREATE = "/role/create";
export const ROUTER_ROLE_UPDATE = "/role/edit/:id";
// permission management
export const ROUTER_PERMISSION = "/permission";
export const ROUTER_PERMISSION_CREATE = "/permission/create";
export const ROUTER_PERMISSION_UPDATE = "/permission/edit/:id";
// warehouse
export const WAREHOUSE = "/warehouse";
export const WAREHOUSE_CREATE = "/warehouse/create";
export const WAREHOUSE_EDIT = "/warehouse/edit/:rm_code";
// cabinet
export const CABINET = "/cabinet";
export const CABINET_CREATE = "/cabinet/create";
export const CABINET_EDIT = "/cabinet/edit/:rm_code";
// box
export const BOX = "/box";
export const BOX_CREATE = "/box/create";
export const BOX_EDIT = "/box/edit/:rm_code";
// identifier
export const IDENTIFIER = "/identifier";
export const IDENTIFIER_CREATE = "/identifier/create";
export const IDENTIFIER_EDIT = "/identifier/edit/:rm_code";
// customer
export const CUSTOMER = "/customer";
export const CUSTOMER_CREATE = "/customer/create";
export const CUSTOMER_EDIT = "/customer/edit/:rm_code";
// archive_collection
export const ARCHIVE_COLLECTION = "/archive_collection";
export const ARCHIVE_COLLECTION_CREATE = "/archive_collection/create";
export const ARCHIVE_COLLECTION_EDIT = "/archive_collection/edit/:rm_code";
// archival_record
export const ARCHIVAL_RECORD = "/archive_record";
export const ARCHIVAL_RECORD_CREATE = "/archive_record/create";
export const ARCHIVAL_RECORD_EDIT = "/archive_record/edit/:rm_code";
// history
export const HISTORY = "/history";
export const HISTORY_CREATE = "/history/create";
export const HISTORY_EDIT = "/history/edit/:rm_code";
// application_slip
export const APPLICATION_SLIP = "/application_slip";
export const APPLICATION_SLIP_CREATE = "/application_slip/create";
export const APPLICATION_SLIP_EDIT = "/application_slip/edit/:rm_code";
// type_document
export const TYPE_DOCUMENT = "/type_document";
export const TYPE_DOCUMENT_CREATE = "/type_document/create";
export const TYPE_DOCUMENT_EDIT = "/type_document/edit/:rm_code";
// cluster
export const CLUSTER = "/cluster";
export const CLUSTER_CREATE = "/cluster/create";
export const CLUSTER_EDIT = "/cluster/edit/:rm_code";
// compartment
export const COMPARTMENT = "/compartment";
export const COMPARTMENT_CREATE = "/compartment/create";
export const COMPARTMENT_EDIT = "/compartment/edit/:rm_code";
// document
export const DOCUMENT = "/document";
export const DOCUMENT_CREATE = "/document/create";
export const DOCUMENT_EDIT = "/document/edit/:rm_code";

// group_collection
export const GROUP_COLLECTION = "/group_collection";
export const GROUP_COLLECTION_CREATE = "/group_collection/create";
export const GROUP_COLLECTION_EDIT = "/group_collection/edit/:rm_code";
