import {
  RM_CODE,
  CREATOR,
  CREATED_AT,
  ID,
  SKU,
  NAME,
  WAREHOUSE,
  WAREHOUSE_ID,
  IP_ADDRESS
} from "../constants/cluster";
import {
  FilterValueCluster,
  FilterValueClusterState,
  ResponseClusterItem,
} from "interface/cluster";

export const DATA_TITLE_FILTER_CLUSTER = {
  [RM_CODE]: "Mã",
  [NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_CLUSTER = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên cụm",
    width: 400,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: WAREHOUSE,
    name: "Kho",
    width: 200,
  },
  {
    id: CREATOR,
    name: "Người tạo",
    width: 200,
  },
  {
    id: IP_ADDRESS,
    name: "Địa chỉ ip",
    width: 200,
  },
];
export const DATA_TITLE_CLUSTER_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên cụm",
    width: 400,
  },
  {
    id: IP_ADDRESS,
    name: "Địa chỉ ip",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: WAREHOUSE,
    name: "Kho",
    width: 200,
  },
  {
    id: CREATOR,
    name: "Người tạo",
    width: 200,
  },
];
export const FILTER_CLUSTER = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên cụm",
      },
      {
        id: WAREHOUSE_ID,
        name: "Tên kho",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_CLUSTER_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_CLUSTER: FilterValueCluster = {
  [NAME]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
  [WAREHOUSE_ID]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataCluster = (data: ResponseClusterItem[]) => {
  return data.map((x: ResponseClusterItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    creator: x.creator.full_name,
    warehouse: x?.warehouse ? x.warehouse.name : "đang cập nhật",
  }));
};
export const FILTER_OPEN_CLUSTER = {};
export const VALUE_FILTER_CLUSTER: FilterValueClusterState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [WAREHOUSE_ID]: {
    value: "",
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },

  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_CLUSTER = {
  isCreateAction: "cluster.create",
  isUpdateAction: "cluster.update",
  isDeleteAction: "cluster.delete",
  isReadAction: "cluster.getList",
};
