import {
  ROUTER_ROLE,
  ROUTER_ROLE_CREATE,
  ROUTER_ROLE_UPDATE,
} from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const Role = React.lazy(() => import("./role"));
const EditPage = React.lazy(() => import("./edit"));

export const roleManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ROUTER_ROLE,
    element: <Role />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ROUTER_ROLE_CREATE,
    element: <EditPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ROUTER_ROLE_UPDATE,
    element: <EditPage />,
  },
];
