export const ID = "id";
export const RM_CODE = "rm_code";
export const CREATED_AT = "created_at";
export const RETURN_DATE = "return_date";
export const BORROW_DATE = "borrow_date";
export const NOTE = "note";
export const CUSTOMER = "customer";
export const CREATOR = "creator";
export const STATUS = "status";
export const PHONE_NUMBER = "phone_number";
export const NUMBER_RECORD = "number_record";
