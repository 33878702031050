export const RM_CODE = "rm_code";
export const CREATOR = "creator";
export const CREATED_AT = "created_at";
export const ID = "id";
export const SKU = "sku";
export const NOTE = "note";
export const NAME = "name";
export const WAREHOUSE = "warehouse";
export const WAREHOUSE_ID = "warehouse_id";
export const IP_ADDRESS = "ip_address";
