import {
  CUSTOMER,
  CUSTOMER_EDIT,
  CUSTOMER_CREATE,
} from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const Customer = React.lazy(() => import("./index"));
const EditPage = React.lazy(() => import("./edit/index"));
export const customerManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: CUSTOMER,
    element: <Customer />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: CUSTOMER_EDIT,
    element: <EditPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: CUSTOMER_CREATE,
    element: <EditPage />,
  },
];
