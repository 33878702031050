import { MetadataRes } from "interface/common/common";
import {
  ACTION_PERMISSION_ACCOUNT_MANAGE,
  DATA_TITLE_FILTER_ACCOUNT_MANAGE,
  DATA_TITLE_ACCOUNT_MANAGE,
  DATA_TITLE_ACCOUNT_MANAGE_DEFAULT,
  DEFAULT_FILTER_ACCOUNT_MANAGE,
  FILTER_OPEN_ACCOUNT_MANAGE,
  FILTER_ACCOUNT_MANAGE,
  FILTER_ACCOUNT_MANAGE_PRO,
  VALUE_FILTER_ACCOUNT_MANAGE,
} from "./authencation/data-account-manage";
import {
  ACTION_PERMISSION_ROLE,
  DATA_TITLE_FILTER_ROLE,
  DATA_TITLE_ROLE,
  DATA_TITLE_ROLE_DEFAULT,
  DEFAULT_FILTER_ROLE,
  FILTER_OPEN_ROLE,
  FILTER_ROLE,
  FILTER_ROLE_PRO,
  VALUE_FILTER_ROLE,
} from "./authencation/data-role";
import {
  ACTION_PERMISSION_PERMISSION,
  DATA_TITLE_FILTER_PERMISSION,
  DATA_TITLE_PERMISSION,
  DATA_TITLE_PERMISSION_DEFAULT,
  DEFAULT_FILTER_PERMISSION,
  FILTER_OPEN_PERMISSION,
  FILTER_PERMISSION,
  FILTER_PERMISSION_PRO,
  VALUE_FILTER_PERMISSION,
} from "./authencation/data-permission";
import {
  ACTION_PERMISSION_WAREHOUSE,
  DATA_TITLE_FILTER_WAREHOUSE,
  DATA_TITLE_WAREHOUSE,
  DATA_TITLE_WAREHOUSE_DEFAULT,
  DEFAULT_FILTER_WAREHOUSE,
  FILTER_OPEN_WAREHOUSE,
  FILTER_WAREHOUSE,
  FILTER_WAREHOUSE_PRO,
  VALUE_FILTER_WAREHOUSE,
} from "./warehouse";
import {
  ACTION_PERMISSION_CABINET,
  DATA_TITLE_FILTER_CABINET,
  DATA_TITLE_CABINET,
  DATA_TITLE_CABINET_DEFAULT,
  DEFAULT_FILTER_CABINET,
  FILTER_OPEN_CABINET,
  FILTER_CABINET,
  FILTER_CABINET_PRO,
  VALUE_FILTER_CABINET,
} from "./cabinet";
import {
  ACTION_PERMISSION_CUSTOMER,
  DATA_TITLE_FILTER_CUSTOMER,
  DATA_TITLE_CUSTOMER,
  DATA_TITLE_CUSTOMER_DEFAULT,
  DEFAULT_FILTER_CUSTOMER,
  FILTER_OPEN_CUSTOMER,
  FILTER_CUSTOMER,
  FILTER_CUSTOMER_PRO,
  VALUE_FILTER_CUSTOMER,
} from "./customer";
import {
  ACTION_PERMISSION_BOX,
  DATA_TITLE_FILTER_BOX,
  DATA_TITLE_BOX,
  DATA_TITLE_BOX_DEFAULT,
  DEFAULT_FILTER_BOX,
  FILTER_OPEN_BOX,
  FILTER_BOX,
  FILTER_BOX_PRO,
  VALUE_FILTER_BOX,
} from "./box";
import {
  ACTION_PERMISSION_IDENTIFIER,
  DATA_TITLE_FILTER_IDENTIFIER,
  DATA_TITLE_IDENTIFIER,
  DATA_TITLE_IDENTIFIER_DEFAULT,
  DEFAULT_FILTER_IDENTIFIER,
  FILTER_OPEN_IDENTIFIER,
  FILTER_IDENTIFIER,
  FILTER_IDENTIFIER_PRO,
  VALUE_FILTER_IDENTIFIER,
} from "./identifier";
import {
  ACTION_PERMISSION_GROUP_COLLECTION,
  DATA_TITLE_FILTER_GROUP_COLLECTION,
  DATA_TITLE_GROUP_COLLECTION,
  DATA_TITLE_GROUP_COLLECTION_DEFAULT,
  DEFAULT_FILTER_GROUP_COLLECTION,
  FILTER_OPEN_GROUP_COLLECTION,
  FILTER_GROUP_COLLECTION,
  FILTER_GROUP_COLLECTION_PRO,
  VALUE_FILTER_GROUP_COLLECTION,
} from "./group_collection";
import {
  ACTION_PERMISSION_HISTORY,
  DATA_TITLE_FILTER_HISTORY,
  DATA_TITLE_HISTORY,
  DATA_TITLE_HISTORY_DEFAULT,
  DEFAULT_FILTER_HISTORY,
  FILTER_OPEN_HISTORY,
  FILTER_HISTORY,
  FILTER_HISTORY_PRO,
  VALUE_FILTER_HISTORY,
} from "./history";
import {
  ACTION_PERMISSION_ARCHIVE_COLLECTION,
  DATA_TITLE_FILTER_ARCHIVE_COLLECTION,
  DATA_TITLE_ARCHIVE_COLLECTION,
  DATA_TITLE_ARCHIVE_COLLECTION_DEFAULT,
  DEFAULT_FILTER_ARCHIVE_COLLECTION,
  FILTER_OPEN_ARCHIVE_COLLECTION,
  FILTER_ARCHIVE_COLLECTION,
  FILTER_ARCHIVE_COLLECTION_PRO,
  VALUE_FILTER_ARCHIVE_COLLECTION,
} from "./archive_collection";
import {
  ACTION_PERMISSION_ARCHIVAL_RECORD,
  DATA_TITLE_FILTER_ARCHIVAL_RECORD,
  DATA_TITLE_ARCHIVAL_RECORD,
  DATA_TITLE_ARCHIVAL_RECORD_DEFAULT,
  DEFAULT_FILTER_ARCHIVAL_RECORD,
  FILTER_OPEN_ARCHIVAL_RECORD,
  FILTER_ARCHIVAL_RECORD,
  FILTER_ARCHIVAL_RECORD_PRO,
  VALUE_FILTER_ARCHIVAL_RECORD,
} from "./archival_record";
import {
  ACTION_PERMISSION_APPLICATION_SLIP,
  DATA_TITLE_FILTER_APPLICATION_SLIP,
  DATA_TITLE_APPLICATION_SLIP,
  DATA_TITLE_APPLICATION_SLIP_DEFAULT,
  DEFAULT_FILTER_APPLICATION_SLIP,
  FILTER_OPEN_APPLICATION_SLIP,
  FILTER_APPLICATION_SLIP,
  FILTER_APPLICATION_SLIP_PRO,
  VALUE_FILTER_APPLICATION_SLIP,
} from "./application_slip";
import {
  ACTION_PERMISSION_TYPE_DOCUMENT,
  DATA_TITLE_FILTER_TYPE_DOCUMENT,
  DATA_TITLE_TYPE_DOCUMENT,
  DATA_TITLE_TYPE_DOCUMENT_DEFAULT,
  DEFAULT_FILTER_TYPE_DOCUMENT,
  FILTER_OPEN_TYPE_DOCUMENT,
  FILTER_TYPE_DOCUMENT,
  FILTER_TYPE_DOCUMENT_PRO,
  VALUE_FILTER_TYPE_DOCUMENT,
} from "./type_document";
import {
  ACTION_PERMISSION_CLUSTER,
  DATA_TITLE_FILTER_CLUSTER,
  DATA_TITLE_CLUSTER,
  DATA_TITLE_CLUSTER_DEFAULT,
  DEFAULT_FILTER_CLUSTER,
  FILTER_OPEN_CLUSTER,
  FILTER_CLUSTER,
  FILTER_CLUSTER_PRO,
  VALUE_FILTER_CLUSTER,
} from "./cluster";
import {
  ACTION_PERMISSION_COMPARTMENT,
  DATA_TITLE_FILTER_COMPARTMENT,
  DATA_TITLE_COMPARTMENT,
  DATA_TITLE_COMPARTMENT_DEFAULT,
  DEFAULT_FILTER_COMPARTMENT,
  FILTER_OPEN_COMPARTMENT,
  FILTER_COMPARTMENT,
  FILTER_COMPARTMENT_PRO,
  VALUE_FILTER_COMPARTMENT,
} from "./compartment";
import {
  ACTION_PERMISSION_DOCUMENT,
  DATA_TITLE_FILTER_DOCUMENT,
  DATA_TITLE_DOCUMENT,
  DATA_TITLE_DOCUMENT_DEFAULT,
  DEFAULT_FILTER_DOCUMENT,
  FILTER_OPEN_DOCUMENT,
  FILTER_DOCUMENT,
  FILTER_DOCUMENT_PRO,
  VALUE_FILTER_DOCUMENT,
} from "./document";
export const getRole = (value) => {
  let role = [];
  if (value === "admin") {
    role = ["admin", "user", "moderator"];
  }
  if (value === "user") {
    role = ["user"];
  }
  if (value === "moderator") {
    role = ["moderator", "user"];
  }
  return role;
};
// page có duyệt đơn nhiều đơn cùng lúc
export const listPageHasAcceptApplication = [
  {
    name_page: "message_medicine",
    path_url: "list_medicine",
  },
  {
    name_page: "pick_up",
    path_url: "list_pick_up",
  },
  {
    name_page: "on-leave",
    path_url: "list_on_leave",
  },
];
export const LIST_PAGE = [
  { pathname: "type_document/create", value: "Thêm mới loại tài liệu" },
  { pathname: "type_document/edit", value: "Chi tiết loại tài liệu" },
  { pathname: "type_document", value: "Danh sách loại tài liệu" },
  { pathname: "group_collection/create", value: "Thêm mới tổ/nhóm " },
  { pathname: "group_collection/edit", value: "Chi tiết tổ/nhóm " },
  { pathname: "group_collection", value: "Danh sách tổ/nhóm " },
  { pathname: "document/create", value: "Thêm mới tài liệu" },
  { pathname: "document/edit", value: "Chi tiết tài liệu" },
  { pathname: "document", value: "Danh sách tài liệu" },
  { pathname: "compartment/create", value: "Thêm mới khoang hồ sơ" },
  { pathname: "compartment/edit", value: "Chi tiết khoang hồ sơ" },
  { pathname: "compartment", value: "Danh sách khoang hồ sơ" },
  { pathname: "cluster/create", value: "Thêm mới cụm hồ sơ" },
  { pathname: "cluster/edit", value: "Chi tiết cụm hồ sơ" },
  { pathname: "cluster", value: "Danh sách cụm hồ sơ" },

  { pathname: "application_slip/create", value: "Thêm mới phiếu mượn" },
  { pathname: "application_slip/edit", value: "Chi tiết phiếu mượn" },
  { pathname: "application_slip", value: "Danh sách phiếu mượn" },
  { pathname: "archive_record/create", value: "Thêm mới hồ sơ" },
  { pathname: "archive_record/edit", value: "Chi tiết hồ sơ" },
  { pathname: "archive_record", value: "Danh sách hồ sơ" },
  { pathname: "history", value: "Lịch sử thao tác" },
  { pathname: "account", value: "Quản lý tài khoản" },
  { pathname: "archive_collection/create", value: "Thêm mới phông" },
  { pathname: "archive_collection/edit", value: "Chi tiết phông" },
  { pathname: "archive_collection", value: "Danh sách phông" },
  { pathname: "customer/create", value: "Thêm mới độc giả" },
  { pathname: "customer/edit", value: "Chi tiết độc giả" },
  { pathname: "customer", value: "Danh sách độc giả" },
  { pathname: "identifier/create", value: "Thêm mới phòng ban" },
  { pathname: "identifier/edit", value: "Chi tiết phòng ban" },
  { pathname: "identifier", value: "Danh sách phòng ban" },
  { pathname: "box/create", value: "Thêm mới hộp hồ sơ" },
  { pathname: "box/edit", value: "Chi tiết hộp hồ sơ" },
  { pathname: "box", value: "Danh sách hộp hồ sơ" },
  { pathname: "cabinet/create", value: "Thêm mới tủ hồ sơ" },
  { pathname: "cabinet/edit", value: "Chi tiết tủ hồ sơ" },
  { pathname: "cabinet", value: "Danh sách tủ hồ sơ" },
  { pathname: "warehouse/create", value: "Thêm mới kho" },
  { pathname: "warehouse/edit", value: "Chi tiết kho" },
  { pathname: "warehouse", value: "Danh sách kho" },
  { pathname: "role", value: "Vai trò" },
  { pathname: "permission", value: "Quyền" },
  { pathname: "dashboard", value: "Bảng điều khiển" },
  { pathname: "reset-password", value: "Reset mật khẩu" },
  { pathname: "change-password", value: "Đổi mật khẩu" },

  { pathname: "signin", value: "" },
  { pathname: "", value: "Dashboard" },
];
export const PAGE_DEFAULT_VALUE: MetadataRes = {
  current_page: 1,
  total_items: 10,
  page_size: 10,
};

export const NAV_DEFAULT_STATE = {
  // dashboard
  dashboard: true,
  // roll permission
  role: false,
  permission: false,
  // customer
  application_slip: false,
  account: false,
  // -----
  archive_record: false,
  managing: true,
  archive_collection: false,
  box: false,
  customer: false,
  cabinet: false,
  cluster: false,
  compartment: false,
  history: false,
  identifier: false,
  type_document: false,
  document: false,
  warehouse: false,
  group_collection: false,
};
export const NAV_COLLAPSE_DEFAULT_STATE = {
  manage_role: { pages: ["role", "permission", "account"], value: false },
};

export const DATA_PAGE = [
  {
    slug: "group_collection",
    tileDefault: DATA_TITLE_GROUP_COLLECTION_DEFAULT,
    tileAll: DATA_TITLE_GROUP_COLLECTION,
    filterBasic: FILTER_GROUP_COLLECTION,
    filterPro: FILTER_GROUP_COLLECTION_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_GROUP_COLLECTION,
    actionPermission: ACTION_PERMISSION_GROUP_COLLECTION,
    valueFilterState: VALUE_FILTER_GROUP_COLLECTION,
    valueFilterStore: DEFAULT_FILTER_GROUP_COLLECTION,
    filterIsOpen: FILTER_OPEN_GROUP_COLLECTION,
  },
  {
    slug: "account",
    tileDefault: DATA_TITLE_ACCOUNT_MANAGE_DEFAULT,
    tileAll: DATA_TITLE_ACCOUNT_MANAGE,
    filterBasic: FILTER_ACCOUNT_MANAGE,
    filterPro: FILTER_ACCOUNT_MANAGE_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_ACCOUNT_MANAGE,
    actionPermission: ACTION_PERMISSION_ACCOUNT_MANAGE,
    valueFilterState: VALUE_FILTER_ACCOUNT_MANAGE,
    valueFilterStore: DEFAULT_FILTER_ACCOUNT_MANAGE,
    filterIsOpen: FILTER_OPEN_ACCOUNT_MANAGE,
  },
  {
    slug: "role",
    tileDefault: DATA_TITLE_ROLE_DEFAULT,
    tileAll: DATA_TITLE_ROLE,
    filterBasic: FILTER_ROLE,
    filterPro: FILTER_ROLE_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_ROLE,
    actionPermission: ACTION_PERMISSION_ROLE,
    valueFilterState: VALUE_FILTER_ROLE,
    valueFilterStore: DEFAULT_FILTER_ROLE,
    filterIsOpen: FILTER_OPEN_ROLE,
  },
  {
    slug: "permission",
    tileDefault: DATA_TITLE_PERMISSION_DEFAULT,
    tileAll: DATA_TITLE_PERMISSION,
    filterBasic: FILTER_PERMISSION,
    filterPro: FILTER_PERMISSION_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_PERMISSION,
    actionPermission: ACTION_PERMISSION_PERMISSION,
    valueFilterState: VALUE_FILTER_PERMISSION,
    valueFilterStore: DEFAULT_FILTER_PERMISSION,
    filterIsOpen: FILTER_OPEN_PERMISSION,
  },
  {
    slug: "warehouse",
    tileDefault: DATA_TITLE_WAREHOUSE_DEFAULT,
    tileAll: DATA_TITLE_WAREHOUSE,
    filterBasic: FILTER_WAREHOUSE,
    filterPro: FILTER_WAREHOUSE_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_WAREHOUSE,
    actionPermission: ACTION_PERMISSION_WAREHOUSE,
    valueFilterState: VALUE_FILTER_WAREHOUSE,
    valueFilterStore: DEFAULT_FILTER_WAREHOUSE,
    filterIsOpen: FILTER_OPEN_WAREHOUSE,
  },
  {
    slug: "cabinet",
    tileDefault: DATA_TITLE_CABINET_DEFAULT,
    tileAll: DATA_TITLE_CABINET,
    filterBasic: FILTER_CABINET,
    filterPro: FILTER_CABINET_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_CABINET,
    actionPermission: ACTION_PERMISSION_CABINET,
    valueFilterState: VALUE_FILTER_CABINET,
    valueFilterStore: DEFAULT_FILTER_CABINET,
    filterIsOpen: FILTER_OPEN_CABINET,
  },
  {
    slug: "history",
    tileDefault: DATA_TITLE_HISTORY_DEFAULT,
    tileAll: DATA_TITLE_HISTORY,
    filterBasic: FILTER_HISTORY,
    filterPro: FILTER_HISTORY_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_HISTORY,
    actionPermission: ACTION_PERMISSION_HISTORY,
    valueFilterState: VALUE_FILTER_HISTORY,
    valueFilterStore: DEFAULT_FILTER_HISTORY,
    filterIsOpen: FILTER_OPEN_HISTORY,
  },
  {
    slug: "customer",
    tileDefault: DATA_TITLE_CUSTOMER_DEFAULT,
    tileAll: DATA_TITLE_CUSTOMER,
    filterBasic: FILTER_CUSTOMER,
    filterPro: FILTER_CUSTOMER_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_CUSTOMER,
    actionPermission: ACTION_PERMISSION_CUSTOMER,
    valueFilterState: VALUE_FILTER_CUSTOMER,
    valueFilterStore: DEFAULT_FILTER_CUSTOMER,
    filterIsOpen: FILTER_OPEN_CUSTOMER,
  },
  {
    slug: "box",
    tileDefault: DATA_TITLE_BOX_DEFAULT,
    tileAll: DATA_TITLE_BOX,
    filterBasic: FILTER_BOX,
    filterPro: FILTER_BOX_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_BOX,
    actionPermission: ACTION_PERMISSION_BOX,
    valueFilterState: VALUE_FILTER_BOX,
    valueFilterStore: DEFAULT_FILTER_BOX,
    filterIsOpen: FILTER_OPEN_BOX,
  },
  {
    slug: "identifier",
    tileDefault: DATA_TITLE_IDENTIFIER_DEFAULT,
    tileAll: DATA_TITLE_IDENTIFIER,
    filterBasic: FILTER_IDENTIFIER,
    filterPro: FILTER_IDENTIFIER_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_IDENTIFIER,
    actionPermission: ACTION_PERMISSION_IDENTIFIER,
    valueFilterState: VALUE_FILTER_IDENTIFIER,
    valueFilterStore: DEFAULT_FILTER_IDENTIFIER,
    filterIsOpen: FILTER_OPEN_IDENTIFIER,
  },
  {
    slug: "archive_collection",
    tileDefault: DATA_TITLE_ARCHIVE_COLLECTION_DEFAULT,
    tileAll: DATA_TITLE_ARCHIVE_COLLECTION,
    filterBasic: FILTER_ARCHIVE_COLLECTION,
    filterPro: FILTER_ARCHIVE_COLLECTION_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_ARCHIVE_COLLECTION,
    actionPermission: ACTION_PERMISSION_ARCHIVE_COLLECTION,
    valueFilterState: VALUE_FILTER_ARCHIVE_COLLECTION,
    valueFilterStore: DEFAULT_FILTER_ARCHIVE_COLLECTION,
    filterIsOpen: FILTER_OPEN_ARCHIVE_COLLECTION,
  },
  {
    slug: "archive_record",
    tileDefault: DATA_TITLE_ARCHIVAL_RECORD_DEFAULT,
    tileAll: DATA_TITLE_ARCHIVAL_RECORD,
    filterBasic: FILTER_ARCHIVAL_RECORD,
    filterPro: FILTER_ARCHIVAL_RECORD_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_ARCHIVAL_RECORD,
    actionPermission: ACTION_PERMISSION_ARCHIVAL_RECORD,
    valueFilterState: VALUE_FILTER_ARCHIVAL_RECORD,
    valueFilterStore: DEFAULT_FILTER_ARCHIVAL_RECORD,
    filterIsOpen: FILTER_OPEN_ARCHIVAL_RECORD,
  },
  {
    slug: "application_slip",
    tileDefault: DATA_TITLE_APPLICATION_SLIP_DEFAULT,
    tileAll: DATA_TITLE_APPLICATION_SLIP,
    filterBasic: FILTER_APPLICATION_SLIP,
    filterPro: FILTER_APPLICATION_SLIP_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_APPLICATION_SLIP,
    actionPermission: ACTION_PERMISSION_APPLICATION_SLIP,
    valueFilterState: VALUE_FILTER_APPLICATION_SLIP,
    valueFilterStore: DEFAULT_FILTER_APPLICATION_SLIP,
    filterIsOpen: FILTER_OPEN_APPLICATION_SLIP,
  },
  {
    slug: "type_document",
    tileDefault: DATA_TITLE_TYPE_DOCUMENT_DEFAULT,
    tileAll: DATA_TITLE_TYPE_DOCUMENT,
    filterBasic: FILTER_TYPE_DOCUMENT,
    filterPro: FILTER_TYPE_DOCUMENT_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_TYPE_DOCUMENT,
    actionPermission: ACTION_PERMISSION_TYPE_DOCUMENT,
    valueFilterState: VALUE_FILTER_TYPE_DOCUMENT,
    valueFilterStore: DEFAULT_FILTER_TYPE_DOCUMENT,
    filterIsOpen: FILTER_OPEN_TYPE_DOCUMENT,
  },
  {
    slug: "cluster",
    tileDefault: DATA_TITLE_CLUSTER_DEFAULT,
    tileAll: DATA_TITLE_CLUSTER,
    filterBasic: FILTER_CLUSTER,
    filterPro: FILTER_CLUSTER_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_CLUSTER,
    actionPermission: ACTION_PERMISSION_CLUSTER,
    valueFilterState: VALUE_FILTER_CLUSTER,
    valueFilterStore: DEFAULT_FILTER_CLUSTER,
    filterIsOpen: FILTER_OPEN_CLUSTER,
  },
  {
    slug: "compartment",
    tileDefault: DATA_TITLE_COMPARTMENT_DEFAULT,
    tileAll: DATA_TITLE_COMPARTMENT,
    filterBasic: FILTER_COMPARTMENT,
    filterPro: FILTER_COMPARTMENT_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_COMPARTMENT,
    actionPermission: ACTION_PERMISSION_COMPARTMENT,
    valueFilterState: VALUE_FILTER_COMPARTMENT,
    valueFilterStore: DEFAULT_FILTER_COMPARTMENT,
    filterIsOpen: FILTER_OPEN_COMPARTMENT,
  },
  {
    slug: "document",
    tileDefault: DATA_TITLE_DOCUMENT_DEFAULT,
    tileAll: DATA_TITLE_DOCUMENT,
    filterBasic: FILTER_DOCUMENT,
    filterPro: FILTER_DOCUMENT_PRO,
    dataTitleFilter: DATA_TITLE_FILTER_DOCUMENT,
    actionPermission: ACTION_PERMISSION_DOCUMENT,
    valueFilterState: VALUE_FILTER_DOCUMENT,
    valueFilterStore: DEFAULT_FILTER_DOCUMENT,
    filterIsOpen: FILTER_OPEN_DOCUMENT,
  },
];
