import { CABINET, CABINET_EDIT, CABINET_CREATE } from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const Cabinet = React.lazy(() => import("./index"));
const EditPage = React.lazy(() => import("./edit/index"));
export const cabinetManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: CABINET,
    element: <Cabinet />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: CABINET_EDIT,
    element: <EditPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: CABINET_CREATE,
    element: <EditPage />,
  },
];
