export const ID = "id";
export const RM_CODE = "rm_code";
export const CREATOR_ID = "creator_id";
export const CREATED_AT = "created_at";
export const SKU = "sku";
export const STATUS = "status";
export const TYPE = "type";
export const FILE_CATALOG = "file_catalog";
export const FILE_NOTATION = "file_notation";
export const NAME = "name";
export const TITLE = "title";
export const MAINTAINANCE = "maintainance";
export const RIGHT = "right";
export const LANGUAGE = "language";
export const START_DATE = "start_date";
export const END_DATE = "end_date";
export const TOTAL_DOC = "total_doc";
export const DESCRIPTION = "description";
export const INFOR_SIGN = "infor_sign";
export const KEYWORD = "keyword";
export const SHEET_NUMBER = "sheet_number";
export const PAGE_NUMBER = "page_number";
export const FORMAT = "format";
export const RETURN_DATE = "return_date";
export const BORROW_DATE = "borrow_date";
export const ARCHIVE_COLLECTION_ID = "archive_collection_id";
export const ARCHIVAL_RECORD = "archival_record";
export const WAREHOUSE = "warehouse";
export const CREATOR = "creator";
