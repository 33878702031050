import {
  ADD_TYPE_DOCUMENT_FILTER,
  REMOVE_TYPE_DOCUMENT,
  REMOVE_TYPE_DOCUMENT_FILTER_ITEM,
  TypeDocumentActions,
  SET_TYPE_DOCUMENT,
  SET_TYPE_DOCUMENT_FILTER,
} from "types/actions/type_document";
import { TypeDocumentState } from "types/models/type_document";
import { DEFAULT_FILTER_TYPE_DOCUMENT } from "utils/data-template-table/type_document";
const INIT_STATE: TypeDocumentState = {
  filter: { value: DEFAULT_FILTER_TYPE_DOCUMENT, type: "all", title: "tất cả" },
  filters: [
    { value: DEFAULT_FILTER_TYPE_DOCUMENT, type: "all", title: "tất cả" },
  ],
  type_documents: [],
};

const TypeDocumentReducer = (
  state = INIT_STATE,
  action: TypeDocumentActions,
) => {
  switch (action.type) {
    case SET_TYPE_DOCUMENT: {
      return {
        ...state,
        type_documents: action.payload,
      };
    }
    case SET_TYPE_DOCUMENT_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_TYPE_DOCUMENT_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_TYPE_DOCUMENT[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_TYPE_DOCUMENT_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_TYPE_DOCUMENT: {
      const listTypeDocumentRemove = action.payload;
      const newTypeDocuments = state.type_documents.filter(
        (it) => !listTypeDocumentRemove.includes(it.rm_code),
      );
      return {
        ...state,
        type_documents: newTypeDocuments,
      };
    }
    default:
      return state;
  }
};
export default TypeDocumentReducer;
