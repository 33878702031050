import { ReactNode } from "react";
import { RoutePermittedRole } from "../constants/AppConst";
import { AiOutlineDashboard } from "react-icons/ai";
import WarehouseIcon from "assets/icon/sidebar/warehouse";
import ClusterIcon from "assets/icon/sidebar/cluster";
import BoxIcon from "assets/icon/sidebar/box";
import RecordIcon from "assets/icon/sidebar/record";
import CustomerIcon from "assets/icon/sidebar/customer";
import CabinetIcon from "assets/icon/sidebar/cabinet";
import CompartmentIcon from "assets/icon/sidebar/compartment";
import DocumentIcon from "assets/icon/sidebar/document";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type:
    | "item"
    | "group"
    | "collapse"
    | "divider"
    | "custom-1"
    | "custom-2"
    | "item-bold";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}
const routesConfig: RouterConfigData[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    messageId: "sidebar.dashboard",
    type: "item-bold",
    url: "/dashboard",
  },

  {
    id: "identifier",
    title: "identifier",
    messageId: "sidebar.identifier",
    type: "item-bold",
    // icon: <WarehouseIcon h={21} w={21} />,
    url: "/identifier",
  },
  {
    id: "group_collection",
    title: "group_collection",
    messageId: "sidebar.group_collection",
    type: "item-bold",
    // icon: <WarehouseIcon h={21} w={21} />,
    url: "/group_collection",
  },
  {
    id: "archive_collection",
    title: "archive_collection",
    messageId: "sidebar.archive_collection",
    type: "item-bold",
    url: "/archive_collection",
  },
  {
    id: "type_document",
    title: "type_document",
    messageId: "sidebar.type_document",
    type: "item-bold",
    url: "/type_document",
  },
  {
    id: "managing",
    title: "managing",
    messageId: `sidebar.managing`,
    type: "custom-2",
  },
  {
    id: "warehouse",
    title: "warehouse",
    messageId: "sidebar.warehouse",
    type: "custom-1",
    icon: <WarehouseIcon h={21} w={21} />,
    url: "/warehouse",
  },
  {
    id: "cluster",
    title: "cluster",
    messageId: "sidebar.cluster",
    type: "custom-1",
    icon: <ClusterIcon h={21} w={21} />,
    url: "/cluster",
  },
  {
    id: "cabinet",
    title: "cabinet",
    messageId: "sidebar.cabinet",
    type: "custom-1",
    icon: <CabinetIcon h={21} w={21} />,
    url: "/cabinet",
  },
  {
    id: "compartment",
    title: "compartment",
    messageId: "sidebar.compartment",
    icon: <CompartmentIcon h={21} w={21} />,
    type: "custom-1",
    url: "/compartment",
  },
  {
    id: "box",
    title: "box",
    messageId: "sidebar.box",
    icon: <BoxIcon h={21} w={21} />,
    type: "custom-1",
    url: "/box",
  },
  {
    id: "archive_record",
    title: "archive_record",
    messageId: "sidebar.archival_record",
    icon: <RecordIcon h={21} w={21} />,
    type: "custom-1",
    url: "/archive_record",
  },
  {
    id: "document",
    title: "document",
    messageId: "sidebar.document",
    icon: <DocumentIcon h={21} w={21} />,
    type: "custom-1",
    url: "/document",
  },
  {
    id: "application_slip",
    title: "application_slip",
    messageId: "sidebar.application_slip",
    type: "item-bold",
    // icon: <WarehouseIcon h={21} w={21} />,
    url: "/application_slip",
  },
  {
    id: "manage_role",
    title: "manege_role",
    messageId: "sidebar.manage_role",
    type: "collapse",
    children: [
      {
        id: "account",
        title: "account",
        messageId: "sidebar.account",
        type: "item",
        url: "/account",
      },
      {
        id: "role",
        title: "vai trò",
        messageId: "sidebar.role",
        type: "item",
        url: "/role",
      },

      // {
      //   id: "permission",
      //   title: "permission Management",
      //   messageId: "sidebar.permission",
      //   type: "item",
      //   url: "/permission",
      // },
    ],
  },

  // {
  //   id: "office",
  //   title: "office",
  //   messageId: "sidebar.office",
  //   type: "item-bold",
  //   url: "/office",
  // },
  {
    id: "customer",
    title: "customer",
    messageId: "sidebar.customer",
    type: "item-bold",
    url: "/customer",
  },
  {
    id: "history",
    title: "history",
    messageId: "sidebar.history",
    type: "item-bold",
    url: "/history",
  },
];

export default routesConfig;
