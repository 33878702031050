const DOMAIN_EMAIL_NOTIFICATION = '/admin/email';
const DOMAIN_EMAIL_TRANSACTION = '/admin/email/transaction';
const DOMAIN_USER = '/user';
const DOMAIN_ADMIN_ROLE = '/admin/role';
const DOMAIN_ADMIN_PERMISSION = '/admin/permission';
const DOMAIN_ADMIN_USER = '/admin/user';
const DOMAIN_APP_NOTIFICATION = '/admin/app';
const DOMAIN_APP_TRANSACTION = '/app/transaction';
const DOMAIN_SMS_NOTIFICATION = '/admin/sms';
const DOMAIN_SMS_TRANSACTION = '/sms/transaction';
const DOMAIN_ADMIN_DASHBOARD = '/admin/dashboard';

export const EMAIL_NOTIFICATION = {
    LIST: `${DOMAIN_EMAIL_NOTIFICATION}/list`,
    GET: (id)=> `${DOMAIN_EMAIL_NOTIFICATION}/get/${id}`,
    CREATE: `${DOMAIN_EMAIL_NOTIFICATION}/create`,
    UPDATE: `${DOMAIN_EMAIL_NOTIFICATION}/update`,
    DETAIL: `${DOMAIN_EMAIL_NOTIFICATION}/find`,
    DELETE: `${DOMAIN_EMAIL_NOTIFICATION}/delete`,
    GET_MAILCHIMP: `${DOMAIN_EMAIL_NOTIFICATION}/template/list/mailchimp`,
    CSV_EMAIL_NOTIFICATION: (id)=>`${DOMAIN_EMAIL_NOTIFICATION}/${id}/csv`,
    UPLOAD_ATTACHMENT_NOTIFICATION: (id)=>`${DOMAIN_EMAIL_NOTIFICATION}/${id}/attachments_upload`,
    TRIGGER: `${DOMAIN_EMAIL_NOTIFICATION}/trigger`,
  };
export const APP_NOTIFICATION = {
    LIST: `${DOMAIN_APP_NOTIFICATION}/list`,
    GET: (id)=> `${DOMAIN_APP_NOTIFICATION}/get/${id}`,
    CREATE: `${DOMAIN_APP_NOTIFICATION}/create`,
    UPDATE: `${DOMAIN_APP_NOTIFICATION}/update`,
    DETAIL: `${DOMAIN_APP_NOTIFICATION}/find`,
    DELETE: `${DOMAIN_APP_NOTIFICATION}/delete`,
    TRIGGER: (id)=> `${DOMAIN_APP_NOTIFICATION}/trigger/${id}`,
  };
  export const APP_TRANSACTION = {
    LIST: `${DOMAIN_APP_TRANSACTION}/list`,
    GET: (id)=>`${DOMAIN_APP_TRANSACTION}/get/${id}`,
  };
  export const EMAIL_TRANSACTION = {
    LIST: `${DOMAIN_EMAIL_TRANSACTION}/list`,
    GET: (id)=>`${DOMAIN_EMAIL_TRANSACTION}/get/${id}`,
  };


  export const SMS_NOTIFICATION = {
    LIST: `${DOMAIN_SMS_NOTIFICATION}/list`,
    GET: (id)=> `${DOMAIN_SMS_NOTIFICATION}/get/${id}`,
    CREATE: `${DOMAIN_SMS_NOTIFICATION}/create`,
    UPDATE: `${DOMAIN_SMS_NOTIFICATION}/update`,
    DETAIL: `${DOMAIN_SMS_NOTIFICATION}/find`,
    DELETE: `${DOMAIN_SMS_NOTIFICATION}/delete`,
    TRIGGER: (id)=> `${DOMAIN_SMS_NOTIFICATION}/trigger/${id}`,
  };
  export const SMS_TRANSACTION = {
    LIST: `${DOMAIN_SMS_TRANSACTION}/list`,
    GET: (id)=>`${DOMAIN_SMS_TRANSACTION}/get/${id}`,
  };

  export const SMS_USER = {
    TRIGGER: (id)=> `${DOMAIN_SMS_NOTIFICATION}/trigger/${id}`,
  };

  export const USER = {
    LOGIN: `${DOMAIN_USER}/login`,
    LOGIN_SSO: `${DOMAIN_USER}/login/sso`,
    GET_USER_ME: `${DOMAIN_USER}/profile`,
  };
  export const ADMIN_USER = {
    LIST: `${DOMAIN_ADMIN_USER}/list`,
    GET: (id)=> `${DOMAIN_ADMIN_USER}/get/${id}`,
    CREATE: `${DOMAIN_ADMIN_USER}/create`,
    UPDATE: (id)=>`${DOMAIN_ADMIN_USER}/update/${(id)}`,
    DETAIL: `${DOMAIN_ADMIN_USER}/find`,
    DELETE: `${DOMAIN_ADMIN_USER}/delete`,
  }
  export const ADMIN_ROLE = {
    LIST: `${DOMAIN_ADMIN_ROLE}/list`,
    GET: (id)=> `${DOMAIN_ADMIN_ROLE}/get/${id}`,
    CREATE: `${DOMAIN_ADMIN_ROLE}/create`,
    UPDATE: `${DOMAIN_ADMIN_ROLE}/update`,
    DETAIL: `${DOMAIN_ADMIN_ROLE}/find`,
    DELETE: `${DOMAIN_ADMIN_ROLE}/delete`,
  }
  export const ADMIN_PERMISSION = {
    LIST: `${DOMAIN_ADMIN_PERMISSION}/list`,
    GET: (id)=> `${DOMAIN_ADMIN_PERMISSION}/get/${id}`,
    CREATE: `${DOMAIN_ADMIN_PERMISSION}/create`,
    UPDATE: `${DOMAIN_ADMIN_PERMISSION}/update`,
    DETAIL: `${DOMAIN_ADMIN_PERMISSION}/find`,
    DELETE: `${DOMAIN_ADMIN_PERMISSION}/delete`,
  }
  export const ADMIN_DASHBOARD = {
    LIST: `${DOMAIN_ADMIN_DASHBOARD}/list`,
  }