import {
  FilterAccountManageProps,
  FilterValueAccountManage,
  ResponseAccountManageItemConvert,
} from "interface/authencation/accountManage/accountManage";

export const SET_ACCOUNT_MANAGE = "SET_ACCOUNT_MANAGE";
export const SET_ACCOUNT_MANAGE_FILTER = "SET_ACCOUNT_MANAGE_FILTER";
export const ADD_ACCOUNT_MANAGE_FILTER = "ADD_ACCOUNT_MANAGE_FILTER";
export const REMOVE_ACCOUNT_MANAGE_FILTER_ITEM =
  "REMOVE_ACCOUNT_MANAGE_FILTER_ITEM";
export const REMOVE_ACCOUNT_MANAGE = "REMOVE_ACCOUNT_MANAGE";
export enum AccountManageThunkActionTypes {
  FETCH_ACCOUNT_MANAGE_REQUEST = "FETCH_ACCOUNT_MANAGE_REQUEST",
  FETCH_ACCOUNT_MANAGE_SUCCESS = "FETCH_ACCOUNT_MANAGE_SUCCESS",
  FETCH_ACCOUNT_MANAGE_ERROR = "FETCH_ACCOUNT_MANAGE_ERROR",
}
export interface SetAccountManageActions {
  type: typeof SET_ACCOUNT_MANAGE;
  payload: ResponseAccountManageItemConvert[];
}
export interface SetAccountManageFilterActions {
  type: typeof SET_ACCOUNT_MANAGE_FILTER;
  payload: FilterAccountManageProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_ACCOUNT_MANAGE_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_ACCOUNT_MANAGE_FILTER;
  payload: FilterAccountManageProps;
}
export interface RemoveAccountManageAction {
  type: typeof REMOVE_ACCOUNT_MANAGE;
  payload: string[];
}
interface FetchAccountManageRequestAction {
  type: AccountManageThunkActionTypes.FETCH_ACCOUNT_MANAGE_REQUEST;
}

interface FetchAccountManageSuccessAction {
  type: AccountManageThunkActionTypes.FETCH_ACCOUNT_MANAGE_SUCCESS;
  payload: string;
}

interface FetchAccountManageErrorAction {
  type: AccountManageThunkActionTypes.FETCH_ACCOUNT_MANAGE_ERROR;
  payload: string;
}
export type AccountManageActions =
  | SetAccountManageActions
  | SetAccountManageFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveAccountManageAction
  | FetchAccountManageRequestAction
  | FetchAccountManageSuccessAction
  | FetchAccountManageErrorAction;
