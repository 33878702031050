import { enUS } from "@mui/material/locale";
import enSystem from "../locales/en-US/en-US.json";
import enNotificationEmail from "../locales/en-US/notification/email-notification.json";
import enNotificationApp from "../locales/en-US/notification/app-notification.json";
import enNotificationSMS from "../locales/en-US/notification/sms-notification.json";
import enDashboard from "../locales/en-US/dashboard/dashboard.json";
import enUser from "../locales/en-US/user-management/user.json";
import enRole from "../locales/en-US/user-management/role.json";
import enPermission from "../locales/en-US/user-management/permission.json";
import enSidebar from "../locales/en-US/sidebar/index.json";
const EnLang = {
  messages: {
    ...enSystem,
    ...enNotificationEmail,
    ...enNotificationApp,
    ...enNotificationSMS,
    ...enDashboard,
    ...enUser,
    ...enRole,
    ...enPermission,
    ...enSidebar,
  },
  muiLocale: enUS,
  locale: "en-US",
};
export default EnLang;
