import {
  ARCHIVE_COLLECTION,
  ARCHIVE_COLLECTION_EDIT,
  ARCHIVE_COLLECTION_CREATE,
} from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const ArchiveCollection = React.lazy(() => import("./index"));
const EditPage = React.lazy(() => import("./edit/index"));
export const archive_collectionManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ARCHIVE_COLLECTION,
    element: <ArchiveCollection />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ARCHIVE_COLLECTION_EDIT,
    element: <EditPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ARCHIVE_COLLECTION_CREATE,
    element: <EditPage />,
  },
];
