import {
  FilterApplicationSlipProps,
  ResponseApplicationSlipItemConvert,
} from "interface/application_slip";

export const SET_APPLICATION_SLIP = "SET_APPLICATION_SLIP";
export const SET_APPLICATION_SLIP_FILTER = "SET_APPLICATION_SLIP_FILTER";
export const ADD_APPLICATION_SLIP_FILTER = "ADD_APPLICATION_SLIP_FILTER";
export const REMOVE_APPLICATION_SLIP_FILTER_ITEM =
  "REMOVE_APPLICATION_SLIP_FILTER_ITEM";
export const REMOVE_APPLICATION_SLIP = "REMOVE_APPLICATION_SLIP";
export enum ApplicationSlipThunkActionTypes {
  FETCH_APPLICATION_SLIP_REQUEST = "FETCH_APPLICATION_SLIP_REQUEST",
  FETCH_APPLICATION_SLIP_SUCCESS = "FETCH_APPLICATION_SLIP_SUCCESS",
  FETCH_APPLICATION_SLIP_ERROR = "FETCH_APPLICATION_SLIP_ERROR",
}
export interface SetApplicationSlipActions {
  type: typeof SET_APPLICATION_SLIP;
  payload: ResponseApplicationSlipItemConvert[];
}
export interface SetApplicationSlipFilterActions {
  type: typeof SET_APPLICATION_SLIP_FILTER;
  payload: FilterApplicationSlipProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_APPLICATION_SLIP_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_APPLICATION_SLIP_FILTER;
  payload: FilterApplicationSlipProps;
}
export interface RemoveApplicationSlipAction {
  type: typeof REMOVE_APPLICATION_SLIP;
  payload: string[];
}
interface FetchApplicationSlipRequestAction {
  type: ApplicationSlipThunkActionTypes.FETCH_APPLICATION_SLIP_REQUEST;
}

interface FetchApplicationSlipSuccessAction {
  type: ApplicationSlipThunkActionTypes.FETCH_APPLICATION_SLIP_SUCCESS;
  payload: string;
}

interface FetchApplicationSlipErrorAction {
  type: ApplicationSlipThunkActionTypes.FETCH_APPLICATION_SLIP_ERROR;
  payload: string;
}
export type ApplicationSlipActions =
  | SetApplicationSlipActions
  | SetApplicationSlipFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveApplicationSlipAction
  | FetchApplicationSlipRequestAction
  | FetchApplicationSlipSuccessAction
  | FetchApplicationSlipErrorAction;
