import { useAuthUser } from "@ikisoft/utility/AuthHooks";
import React, {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { ACTION_PERMISSION_PERMISSION } from "utils/data-template-table/authencation/data-permission";
import { ACTION_PERMISSION_ROLE } from "utils/data-template-table/authencation/data-role";
import { ACTION_PERMISSION_ACCOUNT_MANAGE } from "utils/data-template-table/authencation/data-account-manage";
import { ACTION_PERMISSION_APPLICATION_SLIP } from "utils/data-template-table/application_slip";
import { ACTION_PERMISSION_ARCHIVAL_RECORD } from "utils/data-template-table/archival_record";
import { ACTION_PERMISSION_ARCHIVE_COLLECTION } from "utils/data-template-table/archive_collection";
import { ACTION_PERMISSION_BOX } from "utils/data-template-table/box";
import { ACTION_PERMISSION_CABINET } from "utils/data-template-table/cabinet";
import { ACTION_PERMISSION_CLUSTER } from "utils/data-template-table/cluster";
import { ACTION_PERMISSION_COMPARTMENT } from "utils/data-template-table/compartment";
import { ACTION_PERMISSION_CUSTOMER } from "utils/data-template-table/customer";
import { ACTION_PERMISSION_HISTORY } from "utils/data-template-table/history";
import { ACTION_PERMISSION_IDENTIFIER } from "utils/data-template-table/identifier";
import { ACTION_PERMISSION_TYPE_DOCUMENT } from "utils/data-template-table/type_document";
import { ACTION_PERMISSION_DOCUMENT } from "utils/data-template-table/document";
import { ACTION_PERMISSION_WAREHOUSE } from "utils/data-template-table/warehouse";
import { ACTION_PERMISSION_GROUP_COLLECTION } from "utils/data-template-table/group_collection";

interface PermissionActionsProps {
  isCreateAction: boolean;
  isUpdateAction: boolean;
  isDeleteAction: boolean;
  isReadAction: boolean;
}
export interface PermissionProviderProps {
  children: ReactElement;
}
const LIST_TABLE = [
  { pathname: "role", value: "Vai trò" },
  { pathname: "permission", value: "Quyền" },
  { pathname: "application_slip", value: "Vai trò" },
  { pathname: "account", value: "Quyền" },
  { pathname: "archive_record", value: "Vai trò" },
  { pathname: "archive_collection", value: "Quyền" },
  { pathname: "box", value: "Vai trò" },
  { pathname: "customer", value: "Quyền" },
  { pathname: "cabinet", value: "Vai trò" },
  { pathname: "cluster", value: "Quyền" },
  { pathname: "compartment", value: "Vai trò" },
  { pathname: "history", value: "Quyền" },
  { pathname: "identifier", value: "Vai trò" },
  { pathname: "type_document", value: "Quyền" },
  { pathname: "document", value: "Vai trò" },
  { pathname: "warehouse", value: "Quyền" },
  { pathname: "group_collection", value: "Quyền" },
];
const PermissionActionsContext = createContext<PermissionActionsProps>({
  isCreateAction: false,
  isUpdateAction: false,
  isDeleteAction: false,
  isReadAction: false,
});
export const PermissionProviderAction = () =>
  useContext(PermissionActionsContext);
export default function PermissionProvider({
  children,
}: PermissionProviderProps) {
  const location = useLocation();
  const { user } = useAuthUser();
  const [permission, setPermission] = useState({
    isCreateAction: false,
    isUpdateAction: false,
    isDeleteAction: false,
    isReadAction: false,
  });
  const pageItem = LIST_TABLE.find((x) =>
    location.pathname.includes(x.pathname),
  );
  const page = pageItem ? pageItem.pathname : "";
  const checkPermission = useCallback(() => {
    const permissionUser = user === null ? [] : user.permission;
    const payload = {
      isCreateAction: false,
      isUpdateAction: false,
      isDeleteAction: false,
      isReadAction: false,
    };
    let actions:
      | {
          isCreateAction: string;
          isUpdateAction: string;
          isDeleteAction: string;
          isReadAction: string;
        }
      | {} = {};
    switch (page) {
      case "role":
        actions = ACTION_PERMISSION_ROLE;
        break;
      case "permission":
        actions = ACTION_PERMISSION_PERMISSION;
        break;
      case "account":
        actions = ACTION_PERMISSION_ACCOUNT_MANAGE;
        break;
      case "application_slip":
        actions = ACTION_PERMISSION_APPLICATION_SLIP;
        break;
      case "archive_record":
        actions = ACTION_PERMISSION_ARCHIVAL_RECORD;
        break;
      case "archive_collection":
        actions = ACTION_PERMISSION_ARCHIVE_COLLECTION;
        break;
      case "box":
        actions = ACTION_PERMISSION_BOX;
        break;
      case "cabinet":
        actions = ACTION_PERMISSION_CABINET;
        break;
      case "cluster":
        actions = ACTION_PERMISSION_CLUSTER;
        break;
      case "compartment":
        actions = ACTION_PERMISSION_COMPARTMENT;
        break;
      case "customer":
        actions = ACTION_PERMISSION_CUSTOMER;
        break;
      case "history":
        actions = ACTION_PERMISSION_HISTORY;
        break;
      case "identifier":
        actions = ACTION_PERMISSION_IDENTIFIER;
        break;
      case "type_document":
        actions = ACTION_PERMISSION_TYPE_DOCUMENT;
        break;
      case "document":
        actions = ACTION_PERMISSION_DOCUMENT;
        break;
      case "warehouse":
        actions = ACTION_PERMISSION_WAREHOUSE;
        break;
      case "group_collection":
        actions = ACTION_PERMISSION_GROUP_COLLECTION;
        break;
      default: {
      }
    }
    for (const key in actions) {
      payload[key] = permissionUser.includes(actions[key]);
    }
    setPermission(payload);
  }, [page, user]);
  useEffect(() => {
    checkPermission();
  }, [user, checkPermission, location.pathname]);
  return (
    <PermissionActionsContext.Provider
      value={{
        isCreateAction: permission.isCreateAction,
        isUpdateAction: permission.isUpdateAction,
        isDeleteAction: permission.isDeleteAction,
        isReadAction: permission.isReadAction,
        // isCreateAction: true,
        // isUpdateAction: true,
        // isDeleteAction: true,
        // isReadAction: true,
      }}
    >
      {children}
    </PermissionActionsContext.Provider>
  );
}
