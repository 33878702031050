import {
  FilterWarehouseProps,
  ResponseWarehouseItemConvert,
} from "interface/warehouse";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { warehouseApi } from "service/warehouse";
import {
  ADD_WAREHOUSE_FILTER,
  REMOVE_WAREHOUSE,
  REMOVE_WAREHOUSE_FILTER_ITEM,
  WarehouseActions,
  SET_WAREHOUSE,
  SET_WAREHOUSE_FILTER,
  WarehouseThunkActionTypes,
} from "types/actions/warehouse";
import ToastMessage from "utils/notification";

export const setFilterWarehouseAction = (
  payload: FilterWarehouseProps,
): WarehouseActions => ({
  type: SET_WAREHOUSE_FILTER,
  payload,
});
export const setWarehouseAction = (
  payload: ResponseWarehouseItemConvert[],
): WarehouseActions => ({
  type: SET_WAREHOUSE,
  payload,
});
export const addFilterWarehouseAction = (
  payload: FilterWarehouseProps,
): WarehouseActions => ({
  type: ADD_WAREHOUSE_FILTER,
  payload,
});
export const removeFilterWarehouseItemAction = (
  payload: string,
): WarehouseActions => ({
  type: REMOVE_WAREHOUSE_FILTER_ITEM,
  payload,
});
const removeWarehouseAction = (payload: string[]): WarehouseActions => ({
  type: REMOVE_WAREHOUSE,
  payload,
});
// action thunk
export const fetchRemoveWarehouseAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, WarehouseActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: WarehouseThunkActionTypes.FETCH_WAREHOUSE_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await warehouseApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa kho thành công",
        });
        dispatch(removeWarehouseAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa kho thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: WarehouseThunkActionTypes.FETCH_WAREHOUSE_ERROR,
        payload: error.message,
      });
    }
  };
};
