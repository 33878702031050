import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import AuthRoutes from "@ikisoft/utility/AuthRoutes";
import AppContextProvider from "@ikisoft/utility/AppContextProvider";
import AppThemeProvider from "@ikisoft/utility/AppThemeProvider";
import AppStyleProvider from "@ikisoft/utility/AppStyleProvider";
import AppLocaleProvider from "@ikisoft/utility/AppLocaleProvider";
import AppLayout from "@ikisoft/core/AppLayout";
import configureStore from "redux/store";
import { BrowserRouter } from "react-router-dom";
import JWTAuthAuthProvider from "hooks/JWTAuthProvider";
import "assets/styles/main.scss";
import "assets/styles/index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PermissionProvider from "hooks/PermissionProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const store = configureStore();
const App = () => {
  useEffect(() => {
    // save param session storage when page reload
    const pathname = window.location.pathname.slice(1);
    const search = window.location.search;
    sessionStorage.setItem("params", JSON.stringify({ pathname, search }));
  });
  return (
    <AppContextProvider>
      <Provider store={store}>
        <AppThemeProvider>
          <AppStyleProvider>
            <AppLocaleProvider>
              <BrowserRouter>
                <JWTAuthAuthProvider>
                  <AuthRoutes>
                    <CssBaseline />
                    <PermissionProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <AppLayout />
                      </LocalizationProvider>
                    </PermissionProvider>
                    <ToastContainer />
                  </AuthRoutes>
                </JWTAuthAuthProvider>
              </BrowserRouter>
            </AppLocaleProvider>
          </AppStyleProvider>
        </AppThemeProvider>
      </Provider>
    </AppContextProvider>
  );
};

export default App;
