import {
  FilterApplicationSlipProps,
  ResponseApplicationSlipItemConvert,
} from "interface/application_slip";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { application_slipApi } from "service/application_slip";
import {
  ADD_APPLICATION_SLIP_FILTER,
  REMOVE_APPLICATION_SLIP,
  REMOVE_APPLICATION_SLIP_FILTER_ITEM,
  ApplicationSlipActions,
  SET_APPLICATION_SLIP,
  SET_APPLICATION_SLIP_FILTER,
  ApplicationSlipThunkActionTypes,
} from "types/actions/application_slip";
import ToastMessage from "utils/notification";

export const setFilterApplicationSlipAction = (
  payload: FilterApplicationSlipProps,
): ApplicationSlipActions => ({
  type: SET_APPLICATION_SLIP_FILTER,
  payload,
});
export const setApplicationSlipAction = (
  payload: ResponseApplicationSlipItemConvert[],
): ApplicationSlipActions => ({
  type: SET_APPLICATION_SLIP,
  payload,
});
export const addFilterApplicationSlipAction = (
  payload: FilterApplicationSlipProps,
): ApplicationSlipActions => ({
  type: ADD_APPLICATION_SLIP_FILTER,
  payload,
});
export const removeFilterApplicationSlipItemAction = (
  payload: string,
): ApplicationSlipActions => ({
  type: REMOVE_APPLICATION_SLIP_FILTER_ITEM,
  payload,
});
const removeApplicationSlipAction = (
  payload: string[],
): ApplicationSlipActions => ({
  type: REMOVE_APPLICATION_SLIP,
  payload,
});
// action thunk
export const fetchRemoveApplicationSlipAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, ApplicationSlipActions> => {
  return async (dispatch, getState) => {
    dispatch({
      type: ApplicationSlipThunkActionTypes.FETCH_APPLICATION_SLIP_REQUEST,
    });
    try {
      // Make an asynchronous API call
      const res = await application_slipApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa phiếu mượn thành công",
        });
        dispatch(removeApplicationSlipAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa phiếu mượn thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: ApplicationSlipThunkActionTypes.FETCH_APPLICATION_SLIP_ERROR,
        payload: error.message,
      });
    }
  };
};
