import Service from "service/api-core/apiService";
import { USER } from "service/api-core/endpoints";
import { AccountSate } from "interface/account/account";
import { ResponseData } from "interface/common/common";
const service = new Service();
export const fetchLogin = (data: { email: string; password: string }) => {
  const params = "/auth/system/login";
  // const params = "/auth/member/login";
  const payload = {
    username: data.email,
    password: data.password,
  };
  return service
    .post(params, payload, null)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const fetchLoginSSO = (data: any) => {
  const params = USER.LOGIN_SSO;
  return service
    .post(params, data, null)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getUserMe = (token: string) => {
  return service
    .getMe(token)
    .then((res: ResponseData<AccountSate>) => {
      return res;
    })
    .catch((err) => {
      return { statusCode: 500 };
    });
};
