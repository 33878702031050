import { useAuthMethod } from "@ikisoft/utility/AuthHooks";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
function Redirect() {
  const { loginSSO } = useAuthMethod();
  const { hash } = useLocation();

  useEffect(() => {
    if (
      hash &&
      hash.split("&") &&
      hash.split("&").length > 0 &&
      hash.split("&")[1].split("=") &&
      hash.split("&")[1].split("=").length > 0
    )
      loginSSO(hash.split("&")[1].split("=")[1]);
  }, [hash]);

  return <div>Redirecting...</div>;
}

export default Redirect;
