import { viVN } from "@mui/material/locale";
import enSystem from "../locales/vi-VN/vi-VN.json";
import enNotificationEmail from "../locales/vi-VN/notification/email-notification.json";
import enNotificationApp from "../locales/vi-VN/notification/app-notification.json";
import enNotificationSMS from "../locales/vi-VN/notification/sms-notification.json";
import enDashboard from "../locales/vi-VN/dashboard/dashboard.json";
import enUser from "../locales/vi-VN/user-management/user.json";
import enRole from "../locales/vi-VN/user-management/role.json";
import enPermission from "../locales/vi-VN/user-management/permission.json";
import viSidebar from "../locales/vi-VN/sidebar/index.json";
const VNLang = {
  messages: {
    ...enSystem,
    ...enNotificationEmail,
    ...enNotificationApp,
    ...enNotificationSMS,
    ...enDashboard,
    ...enUser,
    ...enRole,
    ...enPermission,
    ...viSidebar,
  },
  muiLocale: viVN,
  locale: "vi-VN",
};
export default VNLang;
