import {
  ID,
  RM_CODE,
  CREATOR_ID,
  CREATED_AT,
  SKU,
  FOND_NAME,
  FOND_HISTORY,
  ARCHIVE_TIME,
  PAPER_TOTAL,
  PAPER_DIGITAL,
  KEY_GROUP,
  OTHER_TYPE,
  LANGUAGE,
  LOOKUP_TOOL,
  COPY_NUMBER,
  GROUP_COLLECTION,
} from "../constants/archive_collection";
import {
  FilterValueArchiveCollection,
  FilterValueArchiveCollectionState,
  ResponseArchiveCollectionItem,
} from "interface/archive_collection";

export const DATA_TITLE_FILTER_ARCHIVE_COLLECTION = {
  [RM_CODE]: "Mã",
  [FOND_NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_ARCHIVE_COLLECTION = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: FOND_NAME,
    name: "Tên phông",
    width: 200,
  },
  {
    id: GROUP_COLLECTION,
    name: "Tổ/nhóm",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
];
export const DATA_TITLE_ARCHIVE_COLLECTION_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: FOND_NAME,
    name: "Tên phông",
    width: 200,
  },
  {
    id: GROUP_COLLECTION,
    name: "Tổ/nhóm",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
];
export const FILTER_ARCHIVE_COLLECTION = [
  {
    title: "",
    list: [
      {
        id: FOND_NAME,
        name: "Tên phông",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_ARCHIVE_COLLECTION_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_ARCHIVE_COLLECTION: FilterValueArchiveCollection = {
  group_collection_id: { value: null, type: typeFilter.exactly },
  id: { value: null, type: typeFilter.exactly },
  [FOND_NAME]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataArchiveCollection = (
  data: ResponseArchiveCollectionItem[],
) => {
  return data.map((x: ResponseArchiveCollectionItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-US")
      : "",
    creator: x.creator.full_name,
    group_collection: x.group_collection
      ? x.group_collection.name
      : "Đang cập nhật",
  }));
};
export const FILTER_OPEN_ARCHIVE_COLLECTION = {};
export const VALUE_FILTER_ARCHIVE_COLLECTION: FilterValueArchiveCollectionState =
  {
    [FOND_NAME]: {
      value: "",
      type: typeFilter.exactly,
      keySearch: "",
      isGet: false,
    },
    [CREATED_AT]: {
      value: {
        value1: "",
        value2: "",
      },
      type: typeFilter.distance,
      keySearch: "",
      isGet: false,
    },
  };

export const ACTION_PERMISSION_ARCHIVE_COLLECTION = {
  isCreateAction: "archive_collection.create",
  isUpdateAction: "archive_collection.update",
  isDeleteAction: "archive_collection.delete",
  isReadAction: "archive_collection.getList",
};
