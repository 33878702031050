export const ID = "id";
export const RM_CODE = "rm_code";
export const CREATOR_ID = "creator_id";
export const CREATED_AT = "created_at";
export const UPDATED_AT = "updated_at";
export const NAME = "name";
export const SKU = "sku";
export const TYPE = "type";
export const TYPE_SMART = "type_smart";

export const TOTAL_ROW = "total_row";
export const TOTAL_COLUMN = "total_column";
export const ROW_COLUMN = "row_column";
export const STT = "stt";

export const WAREHOUSE = "warehouse";
export const WAREHOUSE_ID = "warehouse_id";
export const CLUSTER = "cluster";
export const CLUSTER_ID = "cluster_id";
