import {
  FilterValuePermission,
  ResponsePermissionItem,
} from "interface/authencation/permission/permission";
import {
  CREATE_PERMISSION,
  DESCRIPTION,
  HTTP_METHOD,
  ID_PERMISSION,
  IS_REQUIRED_ACCESS_TOKEN,
  NAME_PERMISSION,
  PATTERN,
  PERMISSION_NAME,
  SHOULD_CHECK_PERMISSION,
} from "utils/constants";

export const DATA_TITLE_FILTER_PERMISSION = {
  [ID_PERMISSION]: "mã quyền",
  [NAME_PERMISSION]: "tên quyền",
  [PATTERN]: "pattern",
  [CREATE_PERMISSION]: "ngày tạo",
};

export const DATA_TITLE_PERMISSION = [
  {
    id: ID_PERMISSION,
    name: "mã quyền",
  },
  {
    id: NAME_PERMISSION,
    name: "tên quyền",
  },
  {
    id: CREATE_PERMISSION,
    name: "ngày tạo",
  },
  {
    id: HTTP_METHOD,
    name: "tên phương thức",
  },
  {
    id: PERMISSION_NAME,
    name: "phương thức",
  },
  {
    id: PATTERN,
    name: "đường dẫn",
  },

  {
    id: DESCRIPTION,
    name: "mô tả",
  },
  {
    id: SHOULD_CHECK_PERMISSION,
    name: "kiểm tra quyền ",
  },
  {
    id: IS_REQUIRED_ACCESS_TOKEN,
    name: "quyền truy cập",
  },
];
export const DATA_TITLE_PERMISSION_DEFAULT = [
  {
    id: ID_PERMISSION,
    name: "mã quyền",
  },
  {
    id: NAME_PERMISSION,
    name: "tên quyền",
  },
  {
    id: CREATE_PERMISSION,
    name: "ngày tạo",
  },
  {
    id: HTTP_METHOD,
    name: "tên phương thức",
  },
  {
    id: PERMISSION_NAME,
    name: "phương thức",
  },
  {
    id: PATTERN,
    name: "đường dẫn",
  },

  {
    id: DESCRIPTION,
    name: "mô tả",
  },
  {
    id: SHOULD_CHECK_PERMISSION,
    name: "kiểm tra quyền ",
  },
  {
    id: IS_REQUIRED_ACCESS_TOKEN,
    name: "quyền truy cập",
  },
];
export const FILTER_PERMISSION = [
  {
    title: "nổi bật",
    list: [
      {
        id: ID_PERMISSION,
        name: "mã quyền",
      },
      {
        id: NAME_PERMISSION,
        name: "tên quyền",
      },
      {
        id: CREATE_PERMISSION,
        name: "ngày tạo",
      },
      {
        id: PATTERN,
        name: "trạng thái",
      },
    ],
  },
];
export const FILTER_PERMISSION_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_PERMISSION: FilterValuePermission = {
  [ID_PERMISSION]: { value: null, type: typeFilter.exactly },
  [NAME_PERMISSION]: { value: null, type: typeFilter.exactly },
  [PATTERN]: { value: null, type: typeFilter.exactly },
  [CREATE_PERMISSION]: { value: null, type: typeFilter.exactly },
};
export const handleConvertDataPermission = (data: ResponsePermissionItem[]) => {
  return data.map((x: ResponsePermissionItem) => ({
    ...x,
    is_required_access_token:
      x.is_required_access_token === 0
        ? "Không"
        : x.is_required_access_token === 1
        ? "Có"
        : "",
    should_check_permission:
      x.should_check_permission === 0
        ? "Không"
        : x.should_check_permission === 1
        ? "Có"
        : "",
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-US")
      : "",
  }));
};
export const FILTER_OPEN_PERMISSION = {
  [ID_PERMISSION]: false,
  [NAME_PERMISSION]: false,
  [CREATE_PERMISSION]: false,
  [PATTERN]: false,
};
export const ACTION_PERMISSION_PERMISSION = {
  isCreateAction: "permission.create",
  isUpdateAction: "permission.update",
  isDeleteAction: "permission.delete",
  isReadAction: "permission.getList",
};
export const listValuePattern = [
  {
    id: 0,
    name: "đang kích hoạt",
    value: true,
  },
  {
    id: 1,
    name: "Ngừng kích hoạt",
    value: false,
  },
];
export const VALUE_FILTER_PERMISSION = {
  [ID_PERMISSION]: {
    value: "",
    type: "exactly",
    keySearch: "",
    isGet: false,
  },
  [NAME_PERMISSION]: {
    value: "",
    type: "exactly",
    keySearch: "",
    isGet: false,
  },
  [CREATE_PERMISSION]: {
    value: {
      value1: "",
      value2: "",
    },
    type: "includes",
    keySearch: "",
    isGet: false,
  },
  [PATTERN]: {
    value: "",
    type: "exactly",
    keySearch: "",
    isGet: false,
  },
};
