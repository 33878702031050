import {
  ADD_ARCHIVAL_RECORD_FILTER,
  REMOVE_ARCHIVAL_RECORD,
  REMOVE_ARCHIVAL_RECORD_FILTER_ITEM,
  ArchivalRecordActions,
  SET_ARCHIVAL_RECORD,
  SET_ARCHIVAL_RECORD_FILTER,
} from "types/actions/archival_record";
import { ArchivalRecordState } from "types/models/archival_record";
import { DEFAULT_FILTER_ARCHIVAL_RECORD } from "utils/data-template-table/archival_record";
const INIT_STATE: ArchivalRecordState = {
  filter: {
    value: DEFAULT_FILTER_ARCHIVAL_RECORD,
    type: "all",
    title: "tất cả",
  },
  filters: [
    { value: DEFAULT_FILTER_ARCHIVAL_RECORD, type: "all", title: "tất cả" },
  ],
  archival_records: [],
};

const ArchivalRecordReducer = (
  state = INIT_STATE,
  action: ArchivalRecordActions,
) => {
  switch (action.type) {
    case SET_ARCHIVAL_RECORD: {
      return {
        ...state,
        archival_records: action.payload,
      };
    }
    case SET_ARCHIVAL_RECORD_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_ARCHIVAL_RECORD_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_ARCHIVAL_RECORD[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_ARCHIVAL_RECORD_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_ARCHIVAL_RECORD: {
      const listArchivalRecordRemove = action.payload;
      const newArchivalRecords = state.archival_records.filter(
        (it) => !listArchivalRecordRemove.includes(it.rm_code),
      );
      return {
        ...state,
        archival_records: newArchivalRecords,
      };
    }
    default:
      return state;
  }
};
export default ArchivalRecordReducer;
