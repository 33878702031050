import {
  ADD_CLUSTER_FILTER,
  REMOVE_CLUSTER,
  REMOVE_CLUSTER_FILTER_ITEM,
  ClusterActions,
  SET_CLUSTER,
  SET_CLUSTER_FILTER,
} from "types/actions/cluster";
import { ClusterState } from "types/models/cluster";
import { DEFAULT_FILTER_CLUSTER } from "utils/data-template-table/cluster";
const INIT_STATE: ClusterState = {
  filter: { value: DEFAULT_FILTER_CLUSTER, type: "all", title: "tất cả" },
  filters: [{ value: DEFAULT_FILTER_CLUSTER, type: "all", title: "tất cả" }],
  clusters: [],
};

const ClusterReducer = (state = INIT_STATE, action: ClusterActions) => {
  switch (action.type) {
    case SET_CLUSTER: {
      return {
        ...state,
        clusters: action.payload,
      };
    }
    case SET_CLUSTER_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_CLUSTER_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_CLUSTER[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_CLUSTER_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_CLUSTER: {
      const listClusterRemove = action.payload;
      const newClusters = state.clusters.filter(
        (it) => !listClusterRemove.includes(it.rm_code),
      );
      return {
        ...state,
        clusters: newClusters,
      };
    }
    default:
      return state;
  }
};
export default ClusterReducer;
