import React, { useCallback, useEffect, useState } from "react";
import { IconButton, Stack, Theme } from "@mui/material";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import AppScrollbar from "@ikisoft/core/AppScrollbar";
import IntlMessages from "@ikisoft/utility/IntlMessages";
import NotificationItem from "./NotificationItem";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/system";
import { formatDateToDDMMYYYYvsHHMM } from "utils/common";
import { useNavigate } from "react-router-dom";
import { ResponseTableDashboard } from "interface/dashboard/dashboard";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
interface AppNotificationContentProps {
  onClose: () => void;
  sxStyle: SxProps<Theme>;
  listNotification: { id: string; content: string }[];
}

const AppNotificationContent: React.FC<AppNotificationContentProps> = ({
  onClose,
  sxStyle,
  listNotification,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "30vw",
        height: "100%",
        ...sxStyle,
      }}
    >
      <Box
        sx={{
          padding: "5px 20px",
          display: "flex",
          alignItems: "center",
          borderBottom: 1,
          borderBottomColor: (theme) => theme.palette.divider,
          minHeight: { xs: 56, sm: 70 },
        }}
      >
        <h4>Thông báo</h4>
        <IconButton
          sx={{
            height: 40,
            width: 40,
            marginLeft: "auto",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large"
        >
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
      <AppScrollbar
        sx={{
          height: { xs: "calc(100% - 96px)", sm: "calc(100% - 110px)" },
        }}
      >
        <List sx={{ py: 0 }}>
          {listNotification.map((it) => (
            <Stack
              direction="column"
              key={it.id}
              sx={{
                padding: "8px 16px",
                gap: "8px",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  gap: "12px",
                  alignItems: "center",
                  " > span": {
                    display: "flex",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: "2px solid var(--bgCore)",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  },
                  "> p": {
                    m: 0,
                    width: "calc(100% - 40px)",
                  },
                }}
                onClick={() => {
                  onClose();
                  navigate("/archive_record/edit/" + it.id);
                }}
              >
                <span>
                  <NotificationsNoneIcon />
                </span>
                <p>{it.content}</p>
              </Stack>
              {/* <Stack
                direction="row-reverse"
                sx={{
                  fontSize: "12px",
                  color: "#ccc",
                }}
              >
                {formatDateToDDMMYYYYvsHHMM(it.created_at)}
              </Stack> */}
            </Stack>
          ))}
        </List>
      </AppScrollbar>
      <Button
        sx={{
          borderRadius: 0,
          width: "100%",
          marginTop: "auto",
          height: 40,
          bgcolor: "var(--bgCore) !important",
        }}
        variant="contained"
        color="primary"
        onClick={() => {
          onClose();
          navigate("/application_slip");
        }}
      >
        <IntlMessages id="common.viewAll" />
      </Button>
    </Box>
  );
};

export default AppNotificationContent;
