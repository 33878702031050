import { ResponsePermissionItemConvert } from "interface/authencation/permission/permission";
import {
  ADD_PERMISSION_FILTER,
  PermissionActions,
  REMOVE_PERMISSION,
  REMOVE_PERMISSION_FILTER_ITEM,
  SET_PERMISSION,
  SET_PERMISSION_FILTER,
} from "types/actions/authencation/Permission.actions";
import { PermissionState } from "types/models/authencation/Permission";
import { DEFAULT_FILTER_PERMISSION } from "utils/data-template-table/authencation/data-permission";
const permissionDefault: ResponsePermissionItemConvert[] = [];
const INIT_STATE: PermissionState = {
  filter: {
    value: DEFAULT_FILTER_PERMISSION,
    type: "all",
    title: "tất cả",
  },
  filters: [{ value: DEFAULT_FILTER_PERMISSION, type: "all", title: "tất cả" }],
  permissions: permissionDefault,
};

const PermissionReducer = (state = INIT_STATE, action: PermissionActions) => {
  switch (action.type) {
    case SET_PERMISSION: {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case SET_PERMISSION_FILTER: {
      const condition =
        action.payload.type === "search" &&
        !state.filters.find((item) => item.type === "search");
      const newFilters = condition
        ? [...state.filters, action.payload]
        : state.filters;
      return {
        ...state,
        filter: action.payload,
        filters: newFilters,
      };
    }
    case REMOVE_PERMISSION_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_PERMISSION[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
        filters: state.filters.map((it) =>
          it.type === "search"
            ? {
                ...it,
                value: newFilterValue,
              }
            : it,
        ),
      };
    }
    case ADD_PERMISSION_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_PERMISSION: {
      const listRoleRemove = action.payload;
      const newPermissions = state.permissions.filter(
        (it) => !listRoleRemove.includes(it.oc_code),
      );
      return {
        ...state,
        permissions: newPermissions,
      };
    }
    default:
      return state;
  }
};
export default PermissionReducer;
