import {
  ADD_GROUP_COLLECTION_FILTER,
  REMOVE_GROUP_COLLECTION,
  REMOVE_GROUP_COLLECTION_FILTER_ITEM,
  GroupCollectionActions,
  SET_GROUP_COLLECTION,
  SET_GROUP_COLLECTION_FILTER,
} from "types/actions/group_collection";
import { GroupCollectionState } from "types/models/group_collection";
import { DEFAULT_FILTER_GROUP_COLLECTION } from "utils/data-template-table/group_collection";
const INIT_STATE: GroupCollectionState = {
  filter: {
    value: DEFAULT_FILTER_GROUP_COLLECTION,
    type: "all",
    title: "tất cả",
  },
  filters: [
    { value: DEFAULT_FILTER_GROUP_COLLECTION, type: "all", title: "tất cả" },
  ],
  group_collections: [],
};

const GroupCollectionReducer = (
  state = INIT_STATE,
  action: GroupCollectionActions,
) => {
  switch (action.type) {
    case SET_GROUP_COLLECTION: {
      return {
        ...state,
        group_collections: action.payload,
      };
    }
    case SET_GROUP_COLLECTION_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_GROUP_COLLECTION_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_GROUP_COLLECTION[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_GROUP_COLLECTION_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_GROUP_COLLECTION: {
      const listGroupCollectionRemove = action.payload;
      const newGroupCollections = state.group_collections.filter(
        (it) => !listGroupCollectionRemove.includes(it.rm_code),
      );
      return {
        ...state,
        group_collections: newGroupCollections,
      };
    }
    default:
      return state;
  }
};
export default GroupCollectionReducer;
