import {
  FilterCabinetProps,
  ResponseCabinetItemConvert,
} from "interface/cabinet";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { cabinetApi } from "service/cabinet";
import {
  ADD_CABINET_FILTER,
  REMOVE_CABINET,
  REMOVE_CABINET_FILTER_ITEM,
  CabinetActions,
  SET_CABINET,
  SET_CABINET_FILTER,
  CabinetThunkActionTypes,
} from "types/actions/cabinet";
import ToastMessage from "utils/notification";

export const setFilterCabinetAction = (
  payload: FilterCabinetProps,
): CabinetActions => ({
  type: SET_CABINET_FILTER,
  payload,
});
export const setCabinetAction = (
  payload: ResponseCabinetItemConvert[],
): CabinetActions => ({
  type: SET_CABINET,
  payload,
});
export const addFilterCabinetAction = (
  payload: FilterCabinetProps,
): CabinetActions => ({
  type: ADD_CABINET_FILTER,
  payload,
});
export const removeFilterCabinetItemAction = (
  payload: string,
): CabinetActions => ({
  type: REMOVE_CABINET_FILTER_ITEM,
  payload,
});
const removeCabinetAction = (payload: string[]): CabinetActions => ({
  type: REMOVE_CABINET,
  payload,
});
// action thunk
export const fetchRemoveCabinetAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, CabinetActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: CabinetThunkActionTypes.FETCH_CABINET_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await cabinetApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa tủ hồ sơ thành công",
        });
        dispatch(removeCabinetAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa tủ hồ sơ thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: CabinetThunkActionTypes.FETCH_CABINET_ERROR,
        payload: error.message,
      });
    }
  };
};
