import {
  FilterTypeDocumentProps,
  ResponseTypeDocumentItemConvert,
} from "interface/type_document";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { type_documentApi } from "service/type_document";
import {
  ADD_TYPE_DOCUMENT_FILTER,
  REMOVE_TYPE_DOCUMENT,
  REMOVE_TYPE_DOCUMENT_FILTER_ITEM,
  TypeDocumentActions,
  SET_TYPE_DOCUMENT,
  SET_TYPE_DOCUMENT_FILTER,
  TypeDocumentThunkActionTypes,
} from "types/actions/type_document";
import ToastMessage from "utils/notification";

export const setFilterTypeDocumentAction = (
  payload: FilterTypeDocumentProps,
): TypeDocumentActions => ({
  type: SET_TYPE_DOCUMENT_FILTER,
  payload,
});
export const setTypeDocumentAction = (
  payload: ResponseTypeDocumentItemConvert[],
): TypeDocumentActions => ({
  type: SET_TYPE_DOCUMENT,
  payload,
});
export const addFilterTypeDocumentAction = (
  payload: FilterTypeDocumentProps,
): TypeDocumentActions => ({
  type: ADD_TYPE_DOCUMENT_FILTER,
  payload,
});
export const removeFilterTypeDocumentItemAction = (
  payload: string,
): TypeDocumentActions => ({
  type: REMOVE_TYPE_DOCUMENT_FILTER_ITEM,
  payload,
});
const removeTypeDocumentAction = (payload: string[]): TypeDocumentActions => ({
  type: REMOVE_TYPE_DOCUMENT,
  payload,
});
// action thunk
export const fetchRemoveTypeDocumentAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, TypeDocumentActions> => {
  return async (dispatch, getState) => {
    dispatch({
      type: TypeDocumentThunkActionTypes.FETCH_TYPE_DOCUMENT_REQUEST,
    });
    try {
      // Make an asynchronous API call
      const res = await type_documentApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa loại tài liệu thành công",
        });
        dispatch(removeTypeDocumentAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa loại tài liệu thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: TypeDocumentThunkActionTypes.FETCH_TYPE_DOCUMENT_ERROR,
        payload: error.message,
      });
    }
  };
};
