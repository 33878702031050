import {
  FilterCustomerProps,
  ResponseCustomerItemConvert,
} from "interface/customer";

export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_CUSTOMER_FILTER = "SET_CUSTOMER_FILTER";
export const ADD_CUSTOMER_FILTER = "ADD_CUSTOMER_FILTER";
export const REMOVE_CUSTOMER_FILTER_ITEM = "REMOVE_CUSTOMER_FILTER_ITEM";
export const REMOVE_CUSTOMER = "REMOVE_CUSTOMER";
export enum CustomerThunkActionTypes {
  FETCH_CUSTOMER_REQUEST = "FETCH_CUSTOMER_REQUEST",
  FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS",
  FETCH_CUSTOMER_ERROR = "FETCH_CUSTOMER_ERROR",
}
export interface SetCustomerActions {
  type: typeof SET_CUSTOMER;
  payload: ResponseCustomerItemConvert[];
}
export interface SetCustomerFilterActions {
  type: typeof SET_CUSTOMER_FILTER;
  payload: FilterCustomerProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_CUSTOMER_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_CUSTOMER_FILTER;
  payload: FilterCustomerProps;
}
export interface RemoveCustomerAction {
  type: typeof REMOVE_CUSTOMER;
  payload: string[];
}
interface FetchCustomerRequestAction {
  type: CustomerThunkActionTypes.FETCH_CUSTOMER_REQUEST;
}

interface FetchCustomerSuccessAction {
  type: CustomerThunkActionTypes.FETCH_CUSTOMER_SUCCESS;
  payload: string;
}

interface FetchCustomerErrorAction {
  type: CustomerThunkActionTypes.FETCH_CUSTOMER_ERROR;
  payload: string;
}
export type CustomerActions =
  | SetCustomerActions
  | SetCustomerFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveCustomerAction
  | FetchCustomerRequestAction
  | FetchCustomerSuccessAction
  | FetchCustomerErrorAction;
