import {
  FilterArchiveCollectionProps,
  ResponseArchiveCollectionItemConvert,
} from "interface/archive_collection";

export const SET_ARCHIVE_COLLECTION = "SET_ARCHIVE_COLLECTION";
export const SET_ARCHIVE_COLLECTION_FILTER = "SET_ARCHIVE_COLLECTION_FILTER";
export const ADD_ARCHIVE_COLLECTION_FILTER = "ADD_ARCHIVE_COLLECTION_FILTER";
export const REMOVE_ARCHIVE_COLLECTION_FILTER_ITEM = "REMOVE_ARCHIVE_COLLECTION_FILTER_ITEM";
export const REMOVE_ARCHIVE_COLLECTION = "REMOVE_ARCHIVE_COLLECTION";
export enum ArchiveCollectionThunkActionTypes {
  FETCH_ARCHIVE_COLLECTION_REQUEST = "FETCH_ARCHIVE_COLLECTION_REQUEST",
  FETCH_ARCHIVE_COLLECTION_SUCCESS = "FETCH_ARCHIVE_COLLECTION_SUCCESS",
  FETCH_ARCHIVE_COLLECTION_ERROR = "FETCH_ARCHIVE_COLLECTION_ERROR",
}
export interface SetArchiveCollectionActions {
  type: typeof SET_ARCHIVE_COLLECTION;
  payload: ResponseArchiveCollectionItemConvert[];
}
export interface SetArchiveCollectionFilterActions {
  type: typeof SET_ARCHIVE_COLLECTION_FILTER;
  payload: FilterArchiveCollectionProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_ARCHIVE_COLLECTION_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_ARCHIVE_COLLECTION_FILTER;
  payload: FilterArchiveCollectionProps;
}
export interface RemoveArchiveCollectionAction {
  type: typeof REMOVE_ARCHIVE_COLLECTION;
  payload: string[];
}
interface FetchArchiveCollectionRequestAction {
  type: ArchiveCollectionThunkActionTypes.FETCH_ARCHIVE_COLLECTION_REQUEST;
}

interface FetchArchiveCollectionSuccessAction {
  type: ArchiveCollectionThunkActionTypes.FETCH_ARCHIVE_COLLECTION_SUCCESS;
  payload: string;
}

interface FetchArchiveCollectionErrorAction {
  type: ArchiveCollectionThunkActionTypes.FETCH_ARCHIVE_COLLECTION_ERROR;
  payload: string;
}
export type ArchiveCollectionActions =
  | SetArchiveCollectionActions
  | SetArchiveCollectionFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveArchiveCollectionAction
  | FetchArchiveCollectionRequestAction
  | FetchArchiveCollectionSuccessAction
  | FetchArchiveCollectionErrorAction;
