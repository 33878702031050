import {
  FilterDocumentProps,
  ResponseDocumentItemConvert,
} from "interface/document";

export const SET_DOCUMENT = "SET_DOCUMENT";
export const SET_DOCUMENT_FILTER = "SET_DOCUMENT_FILTER";
export const ADD_DOCUMENT_FILTER = "ADD_DOCUMENT_FILTER";
export const REMOVE_DOCUMENT_FILTER_ITEM = "REMOVE_DOCUMENT_FILTER_ITEM";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export enum DocumentThunkActionTypes {
  FETCH_DOCUMENT_REQUEST = "FETCH_DOCUMENT_REQUEST",
  FETCH_DOCUMENT_SUCCESS = "FETCH_DOCUMENT_SUCCESS",
  FETCH_DOCUMENT_ERROR = "FETCH_DOCUMENT_ERROR",
}
export interface SetDocumentActions {
  type: typeof SET_DOCUMENT;
  payload: ResponseDocumentItemConvert[];
}
export interface SetDocumentFilterActions {
  type: typeof SET_DOCUMENT_FILTER;
  payload: FilterDocumentProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_DOCUMENT_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_DOCUMENT_FILTER;
  payload: FilterDocumentProps;
}
export interface RemoveDocumentAction {
  type: typeof REMOVE_DOCUMENT;
  payload: string[];
}
interface FetchDocumentRequestAction {
  type: DocumentThunkActionTypes.FETCH_DOCUMENT_REQUEST;
}

interface FetchDocumentSuccessAction {
  type: DocumentThunkActionTypes.FETCH_DOCUMENT_SUCCESS;
  payload: string;
}

interface FetchDocumentErrorAction {
  type: DocumentThunkActionTypes.FETCH_DOCUMENT_ERROR;
  payload: string;
}
export type DocumentActions =
  | SetDocumentActions
  | SetDocumentFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveDocumentAction
  | FetchDocumentRequestAction
  | FetchDocumentSuccessAction
  | FetchDocumentErrorAction;
