export const authRole = {
  admin: ["admin", "user", "moderator"],
  user: ["user"],
  moderator: ["moderator", "user"],
};

export enum RoutePermittedRole {
  Admin = "admin",
  User = "user",
  Moderator = "moderator",
}

export const initialUrl = "/dashboard"; // this url will open after login
