import {
  ID,
  RM_CODE,
  CREATOR_ID,
  CREATED_AT,
  SKU,
  STATUS,
  TYPE,
  FILE_CATALOG,
  FILE_NOTATION,
  TITLE,
  MAINTAINANCE,
  RIGHT,
  LANGUAGE,
  START_DATE,
  END_DATE,
  TOTAL_DOC,
  DESCRIPTION,
  INFOR_SIGN,
  KEYWORD,
  SHEET_NUMBER,
  PAGE_NUMBER,
  FORMAT,
  RETURN_DATE,
  BORROW_DATE,
  ARCHIVE_COLLECTION_ID,
  ARCHIVAL_RECORD,
  WAREHOUSE,
  CREATOR,
  NAME,
} from "../constants/document";
import {
  FilterValueDocument,
  FilterValueDocumentState,
  ResponseDocumentItem,
  ResponseDocumentItemConvert,
} from "interface/document";

export const DATA_TITLE_FILTER_DOCUMENT = {
  [RM_CODE]: "Mã",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_DOCUMENT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: FILE_NOTATION,
    name: "Số tài liệu",
    width: 200,
  },
  {
    id: TITLE,
    name: "Tiêu đề",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  //
  {
    id: STATUS,
    name: "Trạng thái tài liệu",
    width: 200,
  },
  {
    id: MAINTAINANCE,
    name: "Thời hạn bảo quản",
    width: 200,
  },
  {
    id: RIGHT,
    name: "Chế độ sử dụng",
    width: 200,
  },
  {
    id: LANGUAGE,
    name: "Ngôn ngữ",
    width: 200,
  },
  {
    id: START_DATE,
    name: "Ngày bắt đầu",
    width: 200,
  },
  {
    id: END_DATE,
    name: "Ngày kết thúc",
    width: 200,
  },
  {
    id: TOTAL_DOC,
    name: "Tổng số văn bản trong tài liệu",
    width: 200,
  },
  {
    id: DESCRIPTION,
    name: "Chú giải",
    width: 200,
  },
  {
    id: INFOR_SIGN,
    name: "Ký hiệu thông tin",
    width: 200,
  },
  {
    id: KEYWORD,
    name: "Từ khóa",
    width: 200,
  },
  {
    id: SHEET_NUMBER,
    name: "Số lượng tờ",
    width: 200,
  },
  {
    id: PAGE_NUMBER,
    name: "Số trang",
    width: 200,
  },
  {
    id: FORMAT,
    name: "Tình trạng vật lý",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên tài liệu",
    width: 200,
  },
];
export const DATA_TITLE_DOCUMENT_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: FILE_NOTATION,
    name: "Số tài liệu",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên tài liệu",
  },
  {
    id: TYPE,
    name: "Loại tài liệu",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  //

  {
    id: MAINTAINANCE,
    name: "Thời hạn bảo quản",
    width: 200,
  },

  {
    id: TOTAL_DOC,
    name: "Số văn bản ",
    width: 200,
  },

  {
    id: SHEET_NUMBER,
    name: "Số lượng tờ",
    width: 200,
  },
  {
    id: PAGE_NUMBER,
    name: "Số trang",
    width: 200,
  },
  {
    id: FORMAT,
    name: "Tình trạng vật lý",
    width: 200,
  },
];
export const FILTER_DOCUMENT = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên tài liệu",
      },
      {
        id: TYPE,
        name: "Loại tài liệu",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_DOCUMENT_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_DOCUMENT: FilterValueDocument = {
  status: { value: null, type: typeFilter.exactly },
  [NAME]: { value: null, type: typeFilter.exactly },
  [TYPE]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataDocument = (data: ResponseDocumentItem[]) => {
  return data.map((x: ResponseDocumentItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",

    borrow_date: x.borrow_date
      ? new Date(x.borrow_date).toLocaleDateString("en-GB")
      : "",
    return_date: x.return_date
      ? new Date(x.return_date).toLocaleDateString("en-GB")
      : "",
    creator: x.creator.full_name,
    type: x.type ? "Chưa số hóa" : "Đã số hóa",
    warehouse: x.warehouse ? x.warehouse.name : "đang cập nhật",
    archival_record: x.archival_record
      ? x.archival_record.name
      : "đang cập nhật",
    archive_collection: x.archive_collection
      ? x.archive_collection.fond_name
      : "đang cập nhật",
    type_document: x.type_document ? x.type_document.name : "",
  }));
};
export const FILTER_OPEN_DOCUMENT = {};
export const VALUE_FILTER_DOCUMENT: FilterValueDocumentState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [TYPE]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_DOCUMENT = {
  isCreateAction: "document.create",
  isUpdateAction: "document.update",
  isDeleteAction: "document.delete",
  isReadAction: "document.getList",
};
