import {
  FilterClusterProps,
  ResponseClusterItemConvert,
} from "interface/cluster";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { clusterApi } from "service/cluster";
import {
  ADD_CLUSTER_FILTER,
  REMOVE_CLUSTER,
  REMOVE_CLUSTER_FILTER_ITEM,
  ClusterActions,
  SET_CLUSTER,
  SET_CLUSTER_FILTER,
  ClusterThunkActionTypes,
} from "types/actions/cluster";
import ToastMessage from "utils/notification";

export const setFilterClusterAction = (
  payload: FilterClusterProps,
): ClusterActions => ({
  type: SET_CLUSTER_FILTER,
  payload,
});
export const setClusterAction = (
  payload: ResponseClusterItemConvert[],
): ClusterActions => ({
  type: SET_CLUSTER,
  payload,
});
export const addFilterClusterAction = (
  payload: FilterClusterProps,
): ClusterActions => ({
  type: ADD_CLUSTER_FILTER,
  payload,
});
export const removeFilterClusterItemAction = (
  payload: string,
): ClusterActions => ({
  type: REMOVE_CLUSTER_FILTER_ITEM,
  payload,
});
const removeClusterAction = (payload: string[]): ClusterActions => ({
  type: REMOVE_CLUSTER,
  payload,
});
// action thunk
export const fetchRemoveClusterAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, ClusterActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: ClusterThunkActionTypes.FETCH_CLUSTER_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await clusterApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa cụm hồ sơ thành công",
        });
        dispatch(removeClusterAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa cụm hồ sơ thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: ClusterThunkActionTypes.FETCH_CLUSTER_ERROR,
        payload: error.message,
      });
    }
  };
};
