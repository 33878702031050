import {
  FilterGroupCollectionProps,
  ResponseGroupCollectionItemConvert,
} from "interface/group_collection";

export const SET_GROUP_COLLECTION = "SET_GROUP_COLLECTION";
export const SET_GROUP_COLLECTION_FILTER = "SET_GROUP_COLLECTION_FILTER";
export const ADD_GROUP_COLLECTION_FILTER = "ADD_GROUP_COLLECTION_FILTER";
export const REMOVE_GROUP_COLLECTION_FILTER_ITEM =
  "REMOVE_GROUP_COLLECTION_FILTER_ITEM";
export const REMOVE_GROUP_COLLECTION = "REMOVE_GROUP_COLLECTION";
export enum GroupCollectionThunkActionTypes {
  FETCH_GROUP_COLLECTION_REQUEST = "FETCH_GROUP_COLLECTION_REQUEST",
  FETCH_GROUP_COLLECTION_SUCCESS = "FETCH_GROUP_COLLECTION_SUCCESS",
  FETCH_GROUP_COLLECTION_ERROR = "FETCH_GROUP_COLLECTION_ERROR",
}
export interface SetGroupCollectionActions {
  type: typeof SET_GROUP_COLLECTION;
  payload: ResponseGroupCollectionItemConvert[];
}
export interface SetGroupCollectionFilterActions {
  type: typeof SET_GROUP_COLLECTION_FILTER;
  payload: FilterGroupCollectionProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_GROUP_COLLECTION_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_GROUP_COLLECTION_FILTER;
  payload: FilterGroupCollectionProps;
}
export interface RemoveGroupCollectionAction {
  type: typeof REMOVE_GROUP_COLLECTION;
  payload: string[];
}
interface FetchGroupCollectionRequestAction {
  type: GroupCollectionThunkActionTypes.FETCH_GROUP_COLLECTION_REQUEST;
}

interface FetchGroupCollectionSuccessAction {
  type: GroupCollectionThunkActionTypes.FETCH_GROUP_COLLECTION_SUCCESS;
  payload: string;
}

interface FetchGroupCollectionErrorAction {
  type: GroupCollectionThunkActionTypes.FETCH_GROUP_COLLECTION_ERROR;
  payload: string;
}
export type GroupCollectionActions =
  | SetGroupCollectionActions
  | SetGroupCollectionFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveGroupCollectionAction
  | FetchGroupCollectionRequestAction
  | FetchGroupCollectionSuccessAction
  | FetchGroupCollectionErrorAction;
