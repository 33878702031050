import { Box, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";

export interface DialogSelectProps {
  validate: {
    isError: boolean;
    notification: string;
  };
  dataUi: {
    inputWidth: string;
  };
  value: number;
  width: string;
  label?: string;

  name: string;
  listOption: { id: number; name: string }[];
  height?: string;
  handleOnchange: (
    e:
      | ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<number>
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLTextAreaElement>
  ) => void;
  disable?: boolean;
  contentPlaceholder?: string;
}

export function DialogSelect({
  validate,
  dataUi,
  label,
  value,
  name,
  listOption,
  width,
  height,
  handleOnchange,
  disable,
  contentPlaceholder,
}: DialogSelectProps) {
  return (
    <>
      <Stack
        direction={"row"}
        sx={{
          width: width,
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Select
          value={value}
          onChange={(e) => {
            handleOnchange(e);
          }}
          name={name}
          displayEmpty
          disabled={disable}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            width: dataUi.inputWidth,
            height: height ? height : "40px",
            ">div": {
              padding: "6px 10px",
              textTransform: "capitalize",
            },
          }}
        >
          {/* <MenuItem value={defaultValue.id}>{defaultValue.title}</MenuItem> */}
          <MenuItem value={0} disabled>
            {contentPlaceholder}
          </MenuItem>
          {listOption?.map((x: { id: number; name: string }) => (
            <MenuItem key={x.id} value={x.id}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Box
        component={"p"}
        sx={{
          display: validate.isError ? "block" : "none",
          color: "red",
          fontSize: "12px",
          " > span ": {
            textTransform: "capitalize",
          },
        }}
      >
        <i className="fa-solid fa-triangle-exclamation mr-2"></i>
        <span className="capitalize">{validate.notification}</span>
      </Box>
    </>
  );
}
