interface PropsIcon {
  h: number;
  w: number;
}
function DocumentIcon({ w, h }: PropsIcon) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.35 14.55h-5"
        stroke="#596373"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.92 11v6c0 4-1 5-5 5h-10c-4 0-5-1-5-5V7c0-4 1-5 5-5h1.5c1.5 0 1.83.44 2.4 1.2l1.5 2c.38.5.6.8 1.6.8h3c4 0 5 1 5 5z"
        stroke="#596373"
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default DocumentIcon;
