import {
  ADD_APPLICATION_SLIP_FILTER,
  REMOVE_APPLICATION_SLIP,
  REMOVE_APPLICATION_SLIP_FILTER_ITEM,
  ApplicationSlipActions,
  SET_APPLICATION_SLIP,
  SET_APPLICATION_SLIP_FILTER,
} from "types/actions/application_slip";
import { ApplicationSlipState } from "types/models/application_slip";
import { DEFAULT_FILTER_APPLICATION_SLIP } from "utils/data-template-table/application_slip";
const INIT_STATE: ApplicationSlipState = {
  filter: {
    value: DEFAULT_FILTER_APPLICATION_SLIP,
    type: "all",
    title: "tất cả",
  },
  filters: [
    { value: DEFAULT_FILTER_APPLICATION_SLIP, type: "all", title: "tất cả" },
  ],
  application_slips: [],
};

const ApplicationSlipReducer = (
  state = INIT_STATE,
  action: ApplicationSlipActions,
) => {
  switch (action.type) {
    case SET_APPLICATION_SLIP: {
      return {
        ...state,
        application_slips: action.payload,
      };
    }
    case SET_APPLICATION_SLIP_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_APPLICATION_SLIP_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_APPLICATION_SLIP[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_APPLICATION_SLIP_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_APPLICATION_SLIP: {
      const listApplicationSlipRemove = action.payload;
      const newApplicationSlips = state.application_slips.filter(
        (it) => !listApplicationSlipRemove.includes(it.rm_code),
      );
      return {
        ...state,
        application_slips: newApplicationSlips,
      };
    }
    default:
      return state;
  }
};
export default ApplicationSlipReducer;
