import {
  ADD_ACCOUNT_MANAGE_FILTER,
  REMOVE_ACCOUNT_MANAGE,
  REMOVE_ACCOUNT_MANAGE_FILTER_ITEM,
  AccountManageActions,
  SET_ACCOUNT_MANAGE,
  SET_ACCOUNT_MANAGE_FILTER,
} from "types/actions/authencation/Account.actions";
import { AccountManageState } from "types/models/authencation/AccountManage";
import { DEFAULT_FILTER_ACCOUNT_MANAGE } from "utils/data-template-table/authencation/data-account-manage";
const INIT_STATE: AccountManageState = {
  filter: {
    value: DEFAULT_FILTER_ACCOUNT_MANAGE,
    type: "all",
    title: "tất cả",
  },
  filters: [
    { value: DEFAULT_FILTER_ACCOUNT_MANAGE, type: "all", title: "tất cả" },
  ],
  accountManages: [],
};

const AccountManageReducer = (
  state = INIT_STATE,
  action: AccountManageActions,
) => {
  switch (action.type) {
    case SET_ACCOUNT_MANAGE: {
      return {
        ...state,
        accountManages: action.payload,
      };
    }
    case SET_ACCOUNT_MANAGE_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_ACCOUNT_MANAGE_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_ACCOUNT_MANAGE[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_ACCOUNT_MANAGE_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_ACCOUNT_MANAGE: {
      const listAccountManageRemove = action.payload;
      const newAccountManages = state.accountManages.filter(
        (it) => !listAccountManageRemove.includes(it.rm_code),
      );
      return {
        ...state,
        accountManages: newAccountManages,
      };
    }
    default:
      return state;
  }
};
export default AccountManageReducer;
