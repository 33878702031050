import {
  COMPARTMENT,
  COMPARTMENT_EDIT,
  COMPARTMENT_CREATE,
} from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const Compartment = React.lazy(() => import("./index"));
const EditPage = React.lazy(() => import("./edit/index"));
export const compartmentManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: COMPARTMENT,
    element: <Compartment />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: COMPARTMENT_EDIT,
    element: <EditPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: COMPARTMENT_CREATE,
    element: <EditPage />,
  },
];
