import {
  ADD_CUSTOMER_FILTER,
  REMOVE_CUSTOMER,
  REMOVE_CUSTOMER_FILTER_ITEM,
  CustomerActions,
  SET_CUSTOMER,
  SET_CUSTOMER_FILTER,
} from "types/actions/customer";
import { CustomerState } from "types/models/customer";
import { DEFAULT_FILTER_CUSTOMER } from "utils/data-template-table/customer";
const INIT_STATE: CustomerState = {
  filter: { value: DEFAULT_FILTER_CUSTOMER, type: "all", title: "tất cả" },
  filters: [{ value: DEFAULT_FILTER_CUSTOMER, type: "all", title: "tất cả" }],
  customers: [],
};

const CustomerReducer = (state = INIT_STATE, action: CustomerActions) => {
  switch (action.type) {
    case SET_CUSTOMER: {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case SET_CUSTOMER_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_CUSTOMER_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_CUSTOMER[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_CUSTOMER_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_CUSTOMER: {
      const listCustomerRemove = action.payload;
      const newCustomers = state.customers.filter(
        (it) => !listCustomerRemove.includes(it.rm_code),
      );
      return {
        ...state,
        customers: newCustomers,
      };
    }
    default:
      return state;
  }
};
export default CustomerReducer;
