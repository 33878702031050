import {
  DOCUMENT,
  DOCUMENT_EDIT,
  DOCUMENT_CREATE,
} from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const Document = React.lazy(() => import("./index"));
const EditPage = React.lazy(() => import("./edit/index"));
export const documentManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: DOCUMENT,
    element: <Document />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: DOCUMENT_EDIT,
    element: <EditPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: DOCUMENT_CREATE,
    element: <EditPage />,
  },
];
