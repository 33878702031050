import {
  FilterIdentifierProps,
  ResponseIdentifierItemConvert,
} from "interface/identifier";

export const SET_IDENTIFIER = "SET_IDENTIFIER";
export const SET_IDENTIFIER_FILTER = "SET_IDENTIFIER_FILTER";
export const ADD_IDENTIFIER_FILTER = "ADD_IDENTIFIER_FILTER";
export const REMOVE_IDENTIFIER_FILTER_ITEM = "REMOVE_IDENTIFIER_FILTER_ITEM";
export const REMOVE_IDENTIFIER = "REMOVE_IDENTIFIER";
export enum IdentifierThunkActionTypes {
  FETCH_IDENTIFIER_REQUEST = "FETCH_IDENTIFIER_REQUEST",
  FETCH_IDENTIFIER_SUCCESS = "FETCH_IDENTIFIER_SUCCESS",
  FETCH_IDENTIFIER_ERROR = "FETCH_IDENTIFIER_ERROR",
}
export interface SetIdentifierActions {
  type: typeof SET_IDENTIFIER;
  payload: ResponseIdentifierItemConvert[];
}
export interface SetIdentifierFilterActions {
  type: typeof SET_IDENTIFIER_FILTER;
  payload: FilterIdentifierProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_IDENTIFIER_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_IDENTIFIER_FILTER;
  payload: FilterIdentifierProps;
}
export interface RemoveIdentifierAction {
  type: typeof REMOVE_IDENTIFIER;
  payload: string[];
}
interface FetchIdentifierRequestAction {
  type: IdentifierThunkActionTypes.FETCH_IDENTIFIER_REQUEST;
}

interface FetchIdentifierSuccessAction {
  type: IdentifierThunkActionTypes.FETCH_IDENTIFIER_SUCCESS;
  payload: string;
}

interface FetchIdentifierErrorAction {
  type: IdentifierThunkActionTypes.FETCH_IDENTIFIER_ERROR;
  payload: string;
}
export type IdentifierActions =
  | SetIdentifierActions
  | SetIdentifierFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveIdentifierAction
  | FetchIdentifierRequestAction
  | FetchIdentifierSuccessAction
  | FetchIdentifierErrorAction;
