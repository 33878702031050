import {
  ADD_BOX_FILTER,
  REMOVE_BOX,
  REMOVE_BOX_FILTER_ITEM,
  BoxActions,
  SET_BOX,
  SET_BOX_FILTER,
} from "types/actions/box";
import { BoxState } from "types/models/box";
import { DEFAULT_FILTER_BOX } from "utils/data-template-table/box";
const INIT_STATE: BoxState = {
  filter: { value: DEFAULT_FILTER_BOX, type: "all", title: "tất cả" },
  filters: [{ value: DEFAULT_FILTER_BOX, type: "all", title: "tất cả" }],
  boxs: [],
};

const BoxReducer = (state = INIT_STATE, action: BoxActions) => {
  switch (action.type) {
    case SET_BOX: {
      return {
        ...state,
        boxs: action.payload,
      };
    }
    case SET_BOX_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_BOX_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_BOX[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_BOX_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_BOX: {
      const listBoxRemove = action.payload;
      const newBoxs = state.boxs.filter(
        (it) => !listBoxRemove.includes(it.rm_code),
      );
      return {
        ...state,
        boxs: newBoxs,
      };
    }
    default:
      return state;
  }
};
export default BoxReducer;
