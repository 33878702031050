import {
    ROUTER_ACCOUNT_MANAGE,
    ROUTER_ACCOUNT_MANAGE_CREATE,
    ROUTER_ACCOUNT_MANAGE_UPDATE,
} from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const AccountManage = React.lazy(() => import("./accountManage"));
const EditPage = React.lazy(() => import("./edit"));

export const accountManagementPagesConfigs = [
    {
        permittedRole: RoutePermittedRole.Admin,
        path: ROUTER_ACCOUNT_MANAGE,
        element: <AccountManage />,
    },
    {
        permittedRole: RoutePermittedRole.Admin,
        path: ROUTER_ACCOUNT_MANAGE_CREATE,
        element: <EditPage />,
    },
    {
        permittedRole: RoutePermittedRole.Admin,
        path: ROUTER_ACCOUNT_MANAGE_UPDATE,
        element: <EditPage />,
    },
];
