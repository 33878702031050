import {
  FilterArchivalRecordProps,
  ResponseArchivalRecordItemConvert,
} from "interface/archival_record";

export const SET_ARCHIVAL_RECORD = "SET_ARCHIVAL_RECORD";
export const SET_ARCHIVAL_RECORD_FILTER = "SET_ARCHIVAL_RECORD_FILTER";
export const ADD_ARCHIVAL_RECORD_FILTER = "ADD_ARCHIVAL_RECORD_FILTER";
export const REMOVE_ARCHIVAL_RECORD_FILTER_ITEM =
  "REMOVE_ARCHIVAL_RECORD_FILTER_ITEM";
export const REMOVE_ARCHIVAL_RECORD = "REMOVE_ARCHIVAL_RECORD";
export enum ArchivalRecordThunkActionTypes {
  FETCH_ARCHIVAL_RECORD_REQUEST = "FETCH_ARCHIVAL_RECORD_REQUEST",
  FETCH_ARCHIVAL_RECORD_SUCCESS = "FETCH_ARCHIVAL_RECORD_SUCCESS",
  FETCH_ARCHIVAL_RECORD_ERROR = "FETCH_ARCHIVAL_RECORD_ERROR",
}
export interface SetArchivalRecordActions {
  type: typeof SET_ARCHIVAL_RECORD;
  payload: ResponseArchivalRecordItemConvert[];
}
export interface SetArchivalRecordFilterActions {
  type: typeof SET_ARCHIVAL_RECORD_FILTER;
  payload: FilterArchivalRecordProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_ARCHIVAL_RECORD_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_ARCHIVAL_RECORD_FILTER;
  payload: FilterArchivalRecordProps;
}
export interface RemoveArchivalRecordAction {
  type: typeof REMOVE_ARCHIVAL_RECORD;
  payload: string[];
}
interface FetchArchivalRecordRequestAction {
  type: ArchivalRecordThunkActionTypes.FETCH_ARCHIVAL_RECORD_REQUEST;
}

interface FetchArchivalRecordSuccessAction {
  type: ArchivalRecordThunkActionTypes.FETCH_ARCHIVAL_RECORD_SUCCESS;
  payload: string;
}

interface FetchArchivalRecordErrorAction {
  type: ArchivalRecordThunkActionTypes.FETCH_ARCHIVAL_RECORD_ERROR;
  payload: string;
}
export type ArchivalRecordActions =
  | SetArchivalRecordActions
  | SetArchivalRecordFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveArchivalRecordAction
  | FetchArchivalRecordRequestAction
  | FetchArchivalRecordSuccessAction
  | FetchArchivalRecordErrorAction;
