import { GROUP_COLLECTION, GROUP_COLLECTION_EDIT, GROUP_COLLECTION_CREATE } from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const GroupCollection = React.lazy(() => import("./index"));
const EditPage = React.lazy(() => import("./edit/index"));
export const group_collectionManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: GROUP_COLLECTION,
    element: <GroupCollection />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: GROUP_COLLECTION_EDIT,
    element: <EditPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: GROUP_COLLECTION_CREATE,
    element: <EditPage />,
  },
];
