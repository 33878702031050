import {
  ADD_COMPARTMENT_FILTER,
  REMOVE_COMPARTMENT,
  REMOVE_COMPARTMENT_FILTER_ITEM,
  CompartmentActions,
  SET_COMPARTMENT,
  SET_COMPARTMENT_FILTER,
} from "types/actions/compartment";
import { CompartmentState } from "types/models/compartment";
import { DEFAULT_FILTER_COMPARTMENT } from "utils/data-template-table/compartment";
const INIT_STATE: CompartmentState = {
  filter: { value: DEFAULT_FILTER_COMPARTMENT, type: "all", title: "tất cả" },
  filters: [
    { value: DEFAULT_FILTER_COMPARTMENT, type: "all", title: "tất cả" },
  ],
  compartments: [],
};

const CompartmentReducer = (state = INIT_STATE, action: CompartmentActions) => {
  switch (action.type) {
    case SET_COMPARTMENT: {
      return {
        ...state,
        compartments: action.payload,
      };
    }
    case SET_COMPARTMENT_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case REMOVE_COMPARTMENT_FILTER_ITEM: {
      const key = action.payload;
      const newFilterValue = { ...state.filter.value };
      newFilterValue[key] = DEFAULT_FILTER_COMPARTMENT[key];
      return {
        ...state,
        filter: { ...state.filter, value: newFilterValue },
      };
    }
    case ADD_COMPARTMENT_FILTER: {
      const condition = state.filters.find((item) => item.type === "search");
      if (condition) {
        return {
          ...state,
          filters: state.filters.map((it) =>
            it.type === "search" ? action.payload : it,
          ),
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, action.payload],
        };
      }
    }
    case REMOVE_COMPARTMENT: {
      const listCompartmentRemove = action.payload;
      const newCompartments = state.compartments.filter(
        (it) => !listCompartmentRemove.includes(it.rm_code),
      );
      return {
        ...state,
        compartments: newCompartments,
      };
    }
    default:
      return state;
  }
};
export default CompartmentReducer;
