import React, { useEffect, useMemo, useState } from "react";
import { Icon, ListItemText } from "@mui/material";
import clsx from "clsx";
import AppBadge from "@ikisoft/core/AppBadge";
import AppNavLink from "@ikisoft/core/AppNavLink";
import Box from "@mui/material/Box";
import IntlMessages from "../../../../../utility/IntlMessages";
import { checkPermission } from "../../../../../utility/helper/RouteHelper";
import { useAuthUser } from "../../../../../utility/AuthHooks";
import { RouterConfigData } from "../../../../../../pages/routesConfig";
import { NAV_DEFAULT_STATE } from "utils/data-template-table";
import VerticalCustomItem from "./VerticalCustomItem";
import { useSelector } from "react-redux";

interface VerticalCustomProps {
  item: RouterConfigData;
  level: number;
  isBold?: boolean;
}

const VerticalCustom: React.FC<VerticalCustomProps> = ({
  level,
  item,
  isBold,
}) => {
  const { user } = useAuthUser();
  const [pageActive, setPageActive] = useState(NAV_DEFAULT_STATE);
  // const [apartment, setApartment] = useState<{
  //   id: number;
  //   province_id: number;
  //   gc_code: string;
  //   name: string;
  //   count_floor: number;
  //   list_room_id: number[];
  // }>();
  // const apartmentItem = useSelector(apartmentItemSelect);
  // console.log("apartmentItem: ", apartmentItem);

  const permissionUser = user === null ? [] : user.permission;
  const checkShowNav = () => {
    const newValue = { ...pageActive };
    for (const key in pageActive) {
      const condition = permissionUser.find((it) => it.includes(key));
      if (!!condition) newValue[key] = true;
    }
    setPageActive(newValue);
  };
  useEffect(() => {
    checkShowNav();
  }, []);
  const hasPermission = useMemo(
    () => checkPermission(item.permittedRole, user.role),
    [item.permittedRole, user.role],
  );
  if (!hasPermission) {
    return null;
  }
  // if (!pageActive[item.id] && item.id !== "dashboard") return null;
  return (
    <VerticalCustomItem
      level={level}
      button
      component={AppNavLink}
      to={item.url}
      // activeClassName="active"
      exact={item.exact}
    >
      <ListItemText
        className="nav-item-content"
        primary={
          isBold ? (
            <div className="flex flex-col font-bold">
              <IntlMessages id={item.messageId} />
              {/* <span>{apartmentItem && apartmentItem.name}</span> */}
            </div>
          ) : (
            <div className="flex flex-col">
              <IntlMessages id={item.messageId} />
              {/* <span>{apartmentItem && apartmentItem.name}</span> */}
            </div>
          )
        }
        classes={{ primary: "nav-item-text" }}
      />

      {item.count && (
        <Box sx={{ mr: 3.5 }} className="menu-badge">
          <AppBadge count={item.count} color={item.color} />
        </Box>
      )}
      {item.icon && <Box component="span">{item.icon}</Box>}
    </VerticalCustomItem>
  );
};

export default React.memo(VerticalCustom);
