import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  FaInfo,
  FaCheck,
  FaExclamationTriangle,
  FaBug,
  FaExclamationCircle,
} from "react-icons/fa";
// import notificationSound from "assets/sound/notification.mp3";
export const displayIcon = (type) => {
  switch (type) {
    case "success":
      return <FaCheck />;
    case "info":
      return <FaInfo />;
    case "error":
      return <FaExclamationCircle />;
    case "warning":
      return <FaExclamationTriangle />;
    default:
      return <FaBug />;
  }
};

const ToastMessage = ({ type, message }, time?: number) =>
  toast[type](
    () => {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1, fontSize: 15, padding: "8px 12px" }}>
            {message}
          </div>
        </div>
      );
    },
    {
      autoClose: time ?? 2500,
    },
  );

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

ToastMessage.dismiss = toast.dismiss;

export default ToastMessage;
