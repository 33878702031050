import {
  FilterValueAccountManage,
  ResponseAccountManageItem,
  FilterValueAccountManageState,
} from "interface/authencation/accountManage/accountManage";
import {
  ID_ACCOUNT_MANAGE,
  NAME_ACCOUNT_MANAGE,
  PERMISSION_ACCOUNT_MANAGE,
  CREATE_ACCOUNT_MANAGE,
  HASHED_PASSWORD_ACCOUNT_MANAGE,
  FULL_NAME_ACCOUNT_MANAGE,
  DATE_OF_BIRTH_ACCOUNT_MANAGE,
  EMAIL_ADDRESS_ACCOUNT_MANAGE,
  PHONE_NUMBER_ACCOUNT_MANAGE,
} from "utils/constants/authencation/accountManage";

export const DATA_TITLE_FILTER_ACCOUNT_MANAGE = {
  [ID_ACCOUNT_MANAGE]: "mã accountManage",
  [NAME_ACCOUNT_MANAGE]: "tên accountManage",
  [PERMISSION_ACCOUNT_MANAGE]: "permission",
  [CREATE_ACCOUNT_MANAGE]: "ngày tạo",
  [HASHED_PASSWORD_ACCOUNT_MANAGE]: "password",
  [FULL_NAME_ACCOUNT_MANAGE]: "full name",
  [DATE_OF_BIRTH_ACCOUNT_MANAGE]: "ngày sinh",
  [EMAIL_ADDRESS_ACCOUNT_MANAGE]: "địa chỉ Email",
  [PHONE_NUMBER_ACCOUNT_MANAGE]: "số điện thoại",
};

export const DATA_TITLE_ACCOUNT_MANAGE = [
  {
    id: ID_ACCOUNT_MANAGE,
    name: "mã ",
    width: 200,
  },
  {
    id: NAME_ACCOUNT_MANAGE,
    name: "tài khoản",
    width: 200,
  },
  {
    id: CREATE_ACCOUNT_MANAGE,
    name: "ngày tạo",
    width: 200,
  },
  {
    id: PERMISSION_ACCOUNT_MANAGE,
    name: "quyền",
    width: 200,
  },
  // {
  //   id: HASHED_PASSWORD_ACCOUNT_MANAGE,
  //   name: "password",
  //   width: 200,
  // },
  {
    id: FULL_NAME_ACCOUNT_MANAGE,
    name: "full name",
    width: 200,
  },
  {
    id: DATE_OF_BIRTH_ACCOUNT_MANAGE,
    name: "ngày sinh",
    width: 200,
  },
  {
    id: EMAIL_ADDRESS_ACCOUNT_MANAGE,
    name: "địa chỉ Email",
    width: 300,
  },
  {
    id: PHONE_NUMBER_ACCOUNT_MANAGE,
    name: "số điện thoại",
    width: 200,
  },
];
export const DATA_TITLE_ACCOUNT_MANAGE_DEFAULT = [
  {
    id: ID_ACCOUNT_MANAGE,
    name: "mã ",
    width: 200,
  },
  {
    id: NAME_ACCOUNT_MANAGE,
    name: "tài khoản",
    width: 200,
  },
  {
    id: PERMISSION_ACCOUNT_MANAGE,
    name: "quyền",
    width: 200,
  },
  // {
  //   id: HASHED_PASSWORD_ACCOUNT_MANAGE,
  //   name: "password",
  //   width: 200,
  // },
  {
    id: FULL_NAME_ACCOUNT_MANAGE,
    name: "full name",
    width: 200,
  },
  {
    id: DATE_OF_BIRTH_ACCOUNT_MANAGE,
    name: "ngày sinh",
    width: 200,
  },
  {
    id: EMAIL_ADDRESS_ACCOUNT_MANAGE,
    name: "địa chỉ Email",
    width: 300,
  },
];
export const FILTER_ACCOUNT_MANAGE = [
  {
    title: "",
    list: [
      {
        id: FULL_NAME_ACCOUNT_MANAGE,
        name: "Họ và tên",
      },
      {
        id: CREATE_ACCOUNT_MANAGE,
        name: "Ngày tạo",
        width: 200,
      },
      {
        id: DATE_OF_BIRTH_ACCOUNT_MANAGE,
        name: "Ngày sinh",
      },
    ],
  },
];
export const FILTER_ACCOUNT_MANAGE_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_ACCOUNT_MANAGE: FilterValueAccountManage = {
  [CREATE_ACCOUNT_MANAGE]: { value: null, type: typeFilter.exactly },

  [FULL_NAME_ACCOUNT_MANAGE]: { value: null, type: typeFilter.exactly },
  [DATE_OF_BIRTH_ACCOUNT_MANAGE]: { value: null, type: typeFilter.exactly },
};
export const handleConvertDataAccountManage = (
  data: ResponseAccountManageItem[],
) => {
  return data.map((x: ResponseAccountManageItem) => ({
    ...x,
    permission: x.role?.name,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    date_of_birth: x.date_of_birth
      ? new Date(x.date_of_birth).toLocaleDateString("en-GB")
      : "",
  }));
};
export const FILTER_OPEN_ACCOUNT_MANAGE = {
  [ID_ACCOUNT_MANAGE]: false,
  [NAME_ACCOUNT_MANAGE]: false,
  [CREATE_ACCOUNT_MANAGE]: false,
  [PERMISSION_ACCOUNT_MANAGE]: false,
  [HASHED_PASSWORD_ACCOUNT_MANAGE]: false,
  [FULL_NAME_ACCOUNT_MANAGE]: false,
  [DATE_OF_BIRTH_ACCOUNT_MANAGE]: false,
  [EMAIL_ADDRESS_ACCOUNT_MANAGE]: false,
  [PHONE_NUMBER_ACCOUNT_MANAGE]: false,
};
export const VALUE_FILTER_ACCOUNT_MANAGE: FilterValueAccountManageState = {
  [CREATE_ACCOUNT_MANAGE]: {
    value: {
      value1: "",
      value2: "",
    },
    type: "includes",
    keySearch: "",
    isGet: false,
  },

  [FULL_NAME_ACCOUNT_MANAGE]: {
    value: "",
    type: "exactly",
    keySearch: "",
    isGet: false,
  },
  [DATE_OF_BIRTH_ACCOUNT_MANAGE]: {
    value: {
      value1: "",
      value2: "",
    },
    type: "includes",
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_ACCOUNT_MANAGE = {
  isCreateAction: "account.create",
  isUpdateAction: "account.update",
  isDeleteAction: "account.delete",
  isReadAction: "account.getList",
};
