import {
  ID,
  RM_CODE,
  CREATED_AT,
  CONTENT,
  ACCOUNT,
  STATUS,
} from "../constants/history";
import {
  FilterValueHistory,
  FilterValueHistoryState,
  ResponseHistoryItem,
} from "interface/history";

export const DATA_TITLE_FILTER_HISTORY = {
  [RM_CODE]: "Mã",
  [CONTENT]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_HISTORY = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: CONTENT,
    name: "Nội dung",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: ACCOUNT,
    name: "Tài khoản",
    width: 200,
  },
  {
    id: STATUS,
    name: "Trạng thái",
    width: 200,
  },
];
export const DATA_TITLE_HISTORY_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: CONTENT,
    name: "Nội dung",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: ACCOUNT,
    name: "Tài khoản",
    width: 200,
  },
  {
    id: STATUS,
    name: "Trạng thái",
    width: 200,
  },
];
export const FILTER_HISTORY = [
  {
    title: "",
    list: [
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_HISTORY_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_HISTORY: FilterValueHistory = {
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataHistory = (data: ResponseHistoryItem[]) => {
  return data.map((x: ResponseHistoryItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    account: x.account.full_name,
  }));
};
export const FILTER_OPEN_HISTORY = {};
export const VALUE_FILTER_HISTORY: FilterValueHistoryState = {
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_HISTORY = {
  isCreateAction: "history.create",
  isUpdateAction: "history.update",
  isDeleteAction: "history.delete",
  isReadAction: "history.getList",
};
