import React from "react";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import clsx from "clsx";
import { toggleNavCollapsed } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AppScrollbar from "../../AppScrollbar";
import VerticalNav from "../components/VerticalNav";
import MainSidebar from "../components/MainSidebar";
import { useLayoutContext } from "../../../utility/AppContextProvider/LayoutContextProvider";
import UserInfo from "../components/UserInfo";
import { useSidebarContext } from "../../../utility/AppContextProvider/SidebarContextProvider";
import { AppState } from "../../../../redux/store";
import AppLogo from "../components/AppLogo";
import { Box, Stack, Typography } from "@mui/material";

interface AppSidebarProps {
  position?: "left" | "top" | "right" | "bottom";
  variant?: string;
}

const AppSidebar: React.FC<AppSidebarProps> = ({
  variant = "",
  position = "left",
}) => {
  const dispatch = useDispatch();
  const navCollapsed = useSelector<AppState, AppState["settings"]>(
    ({ settings }) => settings,
  ).navCollapsed;
  const { footer, footerType } = useLayoutContext();

  // const { sidebarTextColor } = useSidebarContext();

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor={position}
          open={navCollapsed}
          onClose={() => handleToggleDrawer()}
          classes={{
            root: clsx(variant),
            paper: clsx(variant),
          }}
          style={{ position: "absolute" }}
        >
          <MainSidebar>
            {/* <UserInfo color={sidebarTextColor} /> */}
            <AppLogo isText="Phần mềm quản lý" />
            <AppScrollbar
              sx={{
                py: 2,
                height: "calc(100vh - 70px) !important",
                borderTop: (theme: { palette: { divider: string } }) =>
                  `solid 1px ${theme.palette.divider}`,
              }}
            >
              <VerticalNav />
            </AppScrollbar>
            <Box
              sx={{
                color: "#ccc",
                position: "sticky",
                bottom: "5px",
                right: "0px",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "400",
              }}
            >
              <Stack
                direction="row"
                gap="16px"
                sx={{
                  padding: "4px 8px",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "16px",
                  img: {
                    width: "24px",
                    height: "24px",
                  },
                }}
              >
                <img src="/assets/images/apple-touch-icon.png" alt="logo" />
                <Typography
                  component="p"
                  sx={{
                    color: "#AEC8EA",
                    fontSize: "12px",
                  }}
                >
                  Copyright by Thiên Bình Group
                </Typography>
              </Stack>
            </Box>
          </MainSidebar>
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <MainSidebar>
          {/* <UserInfo color={sidebarTextColor} /> */}
          <AppLogo isText="Phần mềm quản lý" />
          <AppScrollbar
            className={clsx({
              "has-footer-fixed": footer && footerType === "fixed",
            })}
            sx={{
              p: 2,
              height: "calc(100vh - 100px) !important",
              borderTop: (theme: { palette: { divider: string } }) =>
                `solid 1px ${theme.palette.divider}`,

              "&.has-footer-fixed": {
                height: {
                  xs: "calc(100vh - 117px) !important",
                  xl: "calc(100vh - 127px) !important",
                },
              },
            }}
          >
            <VerticalNav />
          </AppScrollbar>
          <Box
            sx={{
              color: "#ccc",
              position: "sticky",
              bottom: "5px",
              right: "0px",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "400",
            }}
          >
            <Stack
              direction="row"
              gap="16px"
              sx={{
                padding: "4px 8px",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "16px",
                img: {
                  width: "24px",
                  height: "24px",
                },
              }}
            >
              <img src="/assets/images/apple-touch-icon.png" alt="logo" />
              <Typography
                component="p"
                sx={{
                  color: "#AEC8EA",
                  fontSize: "12px",
                }}
              >
                Copyright by Thiên Bình Group
              </Typography>
            </Stack>
          </Box>
        </MainSidebar>
      </Hidden>
    </>
  );
};

export default AppSidebar;
