import {
  FilterArchivalRecordProps,
  ResponseArchivalRecordItemConvert,
} from "interface/archival_record";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { archival_recordApi } from "service/archival_record";
import {
  ADD_ARCHIVAL_RECORD_FILTER,
  REMOVE_ARCHIVAL_RECORD,
  REMOVE_ARCHIVAL_RECORD_FILTER_ITEM,
  ArchivalRecordActions,
  SET_ARCHIVAL_RECORD,
  SET_ARCHIVAL_RECORD_FILTER,
  ArchivalRecordThunkActionTypes,
} from "types/actions/archival_record";
import ToastMessage from "utils/notification";

export const setFilterArchivalRecordAction = (
  payload: FilterArchivalRecordProps,
): ArchivalRecordActions => ({
  type: SET_ARCHIVAL_RECORD_FILTER,
  payload,
});
export const setArchivalRecordAction = (
  payload: ResponseArchivalRecordItemConvert[],
): ArchivalRecordActions => ({
  type: SET_ARCHIVAL_RECORD,
  payload,
});
export const addFilterArchivalRecordAction = (
  payload: FilterArchivalRecordProps,
): ArchivalRecordActions => ({
  type: ADD_ARCHIVAL_RECORD_FILTER,
  payload,
});
export const removeFilterArchivalRecordItemAction = (
  payload: string,
): ArchivalRecordActions => ({
  type: REMOVE_ARCHIVAL_RECORD_FILTER_ITEM,
  payload,
});
const removeArchivalRecordAction = (
  payload: string[],
): ArchivalRecordActions => ({
  type: REMOVE_ARCHIVAL_RECORD,
  payload,
});
// action thunk
export const fetchRemoveArchivalRecordAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, ArchivalRecordActions> => {
  return async (dispatch, getState) => {
    dispatch({
      type: ArchivalRecordThunkActionTypes.FETCH_ARCHIVAL_RECORD_REQUEST,
    });
    try {
      // Make an asynchronous API call
      const res = await archival_recordApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa hồ sơ thành công",
        });
        dispatch(removeArchivalRecordAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa hồ sơ thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: ArchivalRecordThunkActionTypes.FETCH_ARCHIVAL_RECORD_ERROR,
        payload: error.message,
      });
    }
  };
};
