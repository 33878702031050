interface PropsIcon {
  h: number;
  w: number;
}
function CabinetIcon({ w, h }: PropsIcon) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.82 13.5H5.02c-1.5 0-2.1.64-2.1 2.23v4.04c0 1.59.6 2.23 2.1 2.23h15.8c1.5 0 2.1-.64 2.1-2.23v-4.04c0-1.59-.6-2.23-2.1-2.23zM13.82 2h-8.8c-1.5 0-2.1.64-2.1 2.23v4.04c0 1.59.6 2.23 2.1 2.23h8.8c1.5 0 2.1-.64 2.1-2.23V4.23c0-1.59-.6-2.23-2.1-2.23z"
        stroke="#596373"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CabinetIcon;
