import {
  FilterHistoryProps,
  ResponseHistoryItemConvert,
} from "interface/history";

export const SET_HISTORY = "SET_HISTORY";
export const SET_HISTORY_FILTER = "SET_HISTORY_FILTER";
export const ADD_HISTORY_FILTER = "ADD_HISTORY_FILTER";
export const REMOVE_HISTORY_FILTER_ITEM = "REMOVE_HISTORY_FILTER_ITEM";
export const REMOVE_HISTORY = "REMOVE_HISTORY";
export enum HistoryThunkActionTypes {
  FETCH_HISTORY_REQUEST = "FETCH_HISTORY_REQUEST",
  FETCH_HISTORY_SUCCESS = "FETCH_HISTORY_SUCCESS",
  FETCH_HISTORY_ERROR = "FETCH_HISTORY_ERROR",
}
export interface SetHistoryActions {
  type: typeof SET_HISTORY;
  payload: ResponseHistoryItemConvert[];
}
export interface SetHistoryFilterActions {
  type: typeof SET_HISTORY_FILTER;
  payload: FilterHistoryProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_HISTORY_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_HISTORY_FILTER;
  payload: FilterHistoryProps;
}
export interface RemoveHistoryAction {
  type: typeof REMOVE_HISTORY;
  payload: string[];
}
interface FetchHistoryRequestAction {
  type: HistoryThunkActionTypes.FETCH_HISTORY_REQUEST;
}

interface FetchHistorySuccessAction {
  type: HistoryThunkActionTypes.FETCH_HISTORY_SUCCESS;
  payload: string;
}

interface FetchHistoryErrorAction {
  type: HistoryThunkActionTypes.FETCH_HISTORY_ERROR;
  payload: string;
}
export type HistoryActions =
  | SetHistoryActions
  | SetHistoryFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveHistoryAction
  | FetchHistoryRequestAction
  | FetchHistorySuccessAction
  | FetchHistoryErrorAction;
