import React, { useEffect, useMemo, useState } from "react";
import { Icon, ListItemText } from "@mui/material";
import clsx from "clsx";
import AppBadge from "@ikisoft/core/AppBadge";
import AppNavLink from "@ikisoft/core/AppNavLink";
import Box from "@mui/material/Box";
import IntlMessages from "../../../../../utility/IntlMessages";
import { checkPermission } from "../../../../../utility/helper/RouteHelper";
import { useAuthUser } from "../../../../../utility/AuthHooks";
import VerticalNavItem from "./VerticalNavItem";
import { RouterConfigData } from "../../../../../../pages/routesConfig";
import { NAV_DEFAULT_STATE } from "utils/data-template-table";

interface VerticalItemProps {
  item: RouterConfigData;
  level: number;
  isBold?: boolean;
}

const VerticalItem: React.FC<VerticalItemProps> = ({ level, item, isBold }) => {
  const { user } = useAuthUser();
  const [pageActive, setPageActive] = useState(NAV_DEFAULT_STATE);
  const permissionUser = user === null ? [] : user.permission;
  const checkShowNav = () => {
    const newValue = { ...pageActive };
    for (const key in pageActive) {
      const condition = permissionUser.find((it) =>
        it.includes(key + ".getList"),
      );
      if (condition) newValue[key] = true;
    }
    setPageActive(newValue);
  };
  useEffect(() => {
    checkShowNav();
  }, []);
  const hasPermission = useMemo(
    () => checkPermission(item.permittedRole, user.role),
    [item.permittedRole, user.role],
  );
  if (!hasPermission) {
    return null;
  }
  if (!pageActive[item.id]) return null;
  return (
    <VerticalNavItem
      level={level}
      button
      component={AppNavLink}
      to={item.url}
      activeClassName="active"
      exact={item.exact}
    >
      {item.icon && (
        <Box component="span">
          <Icon
            sx={{
              fontSize: 18,
              display: "block",
              mr: 4,
            }}
            className={clsx("nav-item-icon", "material-icons-outlined")}
            color="action"
          >
            {item.icon}
          </Icon>
        </Box>
      )}
      {isBold ? (
        <ListItemText
          className={"nav-item-content"}
          primary={<IntlMessages id={item.messageId} />}
          classes={{ primary: "nav-item-text font-bold" }}
        />
      ) : (
        <ListItemText
          className={"nav-item-content"}
          primary={<IntlMessages id={item.messageId} />}
          classes={{ primary: "nav-item-text" }}
        />
      )}

      {item.count && (
        <Box sx={{ mr: 3.5 }} className="menu-badge">
          <AppBadge count={item.count} color={item.color} />
        </Box>
      )}
    </VerticalNavItem>
  );
};

export default React.memo(VerticalItem);
