import {
  FilterValueCompartment,
  FilterValueCompartmentState,
  ResponseCompartmentItem,
} from "interface/compartment";
import {
  RM_CODE,
  CREATOR,
  CREATED_AT,
  NAME,
  SKU,
  COORDINATES,
  CABINET,
  CABINET_ID,
} from "../constants/compartment";

export const DATA_TITLE_FILTER_COMPARTMENT = {
  [RM_CODE]: "Mã",
  [NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
};

export const DATA_TITLE_COMPARTMENT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên khoang",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: COORDINATES,
    name: "Số hàng, cột",
    width: 200,
  },
  {
    id: CABINET,
    name: "Tủ hồ sơ",
    width: 200,
  },
];
export const DATA_TITLE_COMPARTMENT_DEFAULT = [
  {
    id: RM_CODE,
    name: "Mã",
    width: 200,
  },
  {
    id: NAME,
    name: "Tên khoang",
    width: 200,
  },
  {
    id: CABINET,
    name: "Tủ hồ sơ",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "Ngày tạo",
    width: 200,
  },
  {
    id: COORDINATES,
    name: "Số hàng, cột",
    width: 200,
  },
];
export const FILTER_COMPARTMENT = [
  {
    title: "",
    list: [
      {
        id: NAME,
        name: "Tên khoang",
      },
      {
        id: CABINET_ID,
        name: "Tên tủ hồ sơ",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_COMPARTMENT_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_COMPARTMENT: FilterValueCompartment = {
  [CABINET_ID]: { value: null, type: typeFilter.exactly },
  id: { value: null, type: typeFilter.exactly },
  [NAME]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataCompartment = (
  data: ResponseCompartmentItem[],
) => {
  return data.map((x: ResponseCompartmentItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
    coordinates: x.row_x + " x " + x.column_y,
    creator: x.creator.full_name,
    cabinet: x.cabinet.name,
  }));
};
export const FILTER_OPEN_COMPARTMENT = {};
export const VALUE_FILTER_COMPARTMENT: FilterValueCompartmentState = {
  [NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [CABINET_ID]: {
    value: "",
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_COMPARTMENT = {
  isCreateAction: "compartment.create",
  isUpdateAction: "compartment.update",
  isDeleteAction: "compartment.delete",
  isReadAction: "compartment.getList",
};
