import {
  FilterValueCustomer,
  FilterValueCustomerState,
  ResponseCustomerItem,
} from "interface/customer";
import {
  ID,
  RM_CODE,
  CREATED_AT,
  UPDATED_AT,
  FULL_NAME,
  PHONE_NUMBER,
} from "utils/constants/customer";

export const DATA_TITLE_FILTER_CUSTOMER = {
  [RM_CODE]: "Mã",
  [FULL_NAME]: "Tên độc giả",
  [CREATED_AT]: "Ngày tạo",
  [PHONE_NUMBER]: "Số điện thoại",
};

export const DATA_TITLE_CUSTOMER = [
  {
    id: RM_CODE,
    name: "mã",
    width: 200,
  },
  {
    id: FULL_NAME,
    name: "tên độc giả",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "ngày tạo",
    width: 200,
  },
  {
    id: PHONE_NUMBER,
    name: "số điện thoại",
    width: 200,
  },
];
export const DATA_TITLE_CUSTOMER_DEFAULT = [
  {
    id: RM_CODE,
    name: "mã",
    width: 200,
  },
  {
    id: FULL_NAME,
    name: "tên độc giả",
    width: 200,
  },
  {
    id: PHONE_NUMBER,
    name: "số điện thoại",
    width: 200,
  },
  {
    id: CREATED_AT,
    name: "ngày tạo",
    width: 200,
  },
];
export const FILTER_CUSTOMER = [
  {
    title: "",
    list: [
      {
        id: FULL_NAME,
        name: "Tên độc giả",
      },
      {
        id: PHONE_NUMBER,
        name: "số điện thoại",
      },
      {
        id: CREATED_AT,
        name: "Ngày tạo",
        width: 200,
      },
    ],
  },
];
export const FILTER_CUSTOMER_PRO = [];
const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
};
// value filter redux init
export const DEFAULT_FILTER_CUSTOMER: FilterValueCustomer = {
  [FULL_NAME]: { value: null, type: typeFilter.exactly },
  [PHONE_NUMBER]: { value: null, type: typeFilter.exactly },
  [CREATED_AT]: { value: null, type: typeFilter.distance },
};
export const handleConvertDataCustomer = (data: ResponseCustomerItem[]) => {
  return data.map((x: ResponseCustomerItem) => ({
    ...x,
    created_at: x.created_at
      ? new Date(x.created_at).toLocaleDateString("en-GB")
      : "",
  }));
};
export const FILTER_OPEN_CUSTOMER = {};
export const VALUE_FILTER_CUSTOMER: FilterValueCustomerState = {
  [FULL_NAME]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [PHONE_NUMBER]: {
    value: "",
    type: typeFilter.exactly,
    keySearch: "",
    isGet: false,
  },
  [CREATED_AT]: {
    value: {
      value1: "",
      value2: "",
    },
    type: typeFilter.distance,
    keySearch: "",
    isGet: false,
  },
};

export const ACTION_PERMISSION_CUSTOMER = {
  isCreateAction: "customer.create",
  isUpdateAction: "customer.update",
  isDeleteAction: "customer.delete",
  isReadAction: "customer.getList",
};
