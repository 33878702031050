import { FilterPermissionProps } from "interface/authencation/permission/permission";
import axios from "axios";
import Service from "service/api-core/apiService";
const URL_END_POINT = "/permission";
const service = new Service();
export interface PayloadCreatePermission {
  name: string;
  http_method: string;
  pattern: string;
  permission_name: string;
  is_required_access_token: number;
  should_check_permission: number;
  school_id: number;
}
export const permissionApi = {
  get(filter?: FilterPermissionProps, pg?: number, pageSize?: number) {
    const page = pg ? pg : 1;
    const page_size = pageSize ? pageSize : 20;
    let param = `?page=${page}&page_size=${page_size}`;
    const params = {};
    if (filter?.type === "search") {
      for (const key in filter.value) {
        const item = filter.value[key as keyof typeof filter.value];
        if (item.value !== null) {
          params[key] = filter.value[key].value;
        }
      }
    }
    for (const key in params) {
      param = param + "&" + key + "=" + params[key];
    }
    const url = URL_END_POINT + param;
    return service
      .get(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  getDetail(id: string) {
    const url = URL_END_POINT + "/" + id;
    return service
      .get(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  search(id: string, pg?: number, pageSize?: number) {
    const page = pg ? pg : 1;
    const page_size = pageSize ? pageSize : 20;
    let param = `?oc_code=${id}&page=${page}&page_size=${page_size}`;
    const url = URL_END_POINT + param;
    return service
      .get(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  create(payload: PayloadCreatePermission) {
    return service
      .post(URL_END_POINT, payload, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  update(payload: PayloadCreatePermission, oc_code: string) {
    return service
      .put(URL_END_POINT + "/" + oc_code, payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  delete(listID: string[]) {
    const url = process.env.REACT_APP_API_URL + URL_END_POINT;
    return service
      .delete(url, [...listID])
      .then((res: { status: boolean }) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  getAll() {
    let param = `?page=${1}&page_size=${999}`;

    const url = URL_END_POINT + param;
    return service
      .get(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
