import {
  FilterCustomerProps,
  ResponseCustomerItemConvert,
} from "interface/customer";
import { DefaultRootState } from "react-redux";
import { ThunkAction } from "redux-thunk";
import { customerApi } from "service/customer";
import {
  ADD_CUSTOMER_FILTER,
  REMOVE_CUSTOMER,
  REMOVE_CUSTOMER_FILTER_ITEM,
  CustomerActions,
  SET_CUSTOMER,
  SET_CUSTOMER_FILTER,
  CustomerThunkActionTypes,
} from "types/actions/customer";
import ToastMessage from "utils/notification";

export const setFilterCustomerAction = (
  payload: FilterCustomerProps,
): CustomerActions => ({
  type: SET_CUSTOMER_FILTER,
  payload,
});
export const setCustomerAction = (
  payload: ResponseCustomerItemConvert[],
): CustomerActions => ({
  type: SET_CUSTOMER,
  payload,
});
export const addFilterCustomerAction = (
  payload: FilterCustomerProps,
): CustomerActions => ({
  type: ADD_CUSTOMER_FILTER,
  payload,
});
export const removeFilterCustomerItemAction = (
  payload: string,
): CustomerActions => ({
  type: REMOVE_CUSTOMER_FILTER_ITEM,
  payload,
});
const removeCustomerAction = (payload: string[]): CustomerActions => ({
  type: REMOVE_CUSTOMER,
  payload,
});
// action thunk
export const fetchRemoveCustomerAction = (
  listCheckbox: string[],
): ThunkAction<void, DefaultRootState, unknown, CustomerActions> => {
  return async (dispatch, getState) => {
    dispatch({ type: CustomerThunkActionTypes.FETCH_CUSTOMER_REQUEST });
    try {
      // Make an asynchronous API call
      const res = await customerApi.delete(listCheckbox);
      // Dispatch success action if user is fetched
      if (res) {
        ToastMessage({
          type: "success",
          message: "Xóa độc giả thành công",
        });
        dispatch(removeCustomerAction(listCheckbox));
      } else {
        ToastMessage({
          type: "error",
          message: "Xóa độc giả thất bại",
        });
      }
    } catch (error) {
      // Dispatch error action if there's an error
      dispatch({
        type: CustomerThunkActionTypes.FETCH_CUSTOMER_ERROR,
        payload: error.message,
      });
    }
  };
};
