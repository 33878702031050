import {
  FilterBoxProps,
  ResponseBoxItemConvert,
} from "interface/box";

export const SET_BOX = "SET_BOX";
export const SET_BOX_FILTER = "SET_BOX_FILTER";
export const ADD_BOX_FILTER = "ADD_BOX_FILTER";
export const REMOVE_BOX_FILTER_ITEM = "REMOVE_BOX_FILTER_ITEM";
export const REMOVE_BOX = "REMOVE_BOX";
export enum BoxThunkActionTypes {
  FETCH_BOX_REQUEST = "FETCH_BOX_REQUEST",
  FETCH_BOX_SUCCESS = "FETCH_BOX_SUCCESS",
  FETCH_BOX_ERROR = "FETCH_BOX_ERROR",
}
export interface SetBoxActions {
  type: typeof SET_BOX;
  payload: ResponseBoxItemConvert[];
}
export interface SetBoxFilterActions {
  type: typeof SET_BOX_FILTER;
  payload: FilterBoxProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_BOX_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_BOX_FILTER;
  payload: FilterBoxProps;
}
export interface RemoveBoxAction {
  type: typeof REMOVE_BOX;
  payload: string[];
}
interface FetchBoxRequestAction {
  type: BoxThunkActionTypes.FETCH_BOX_REQUEST;
}

interface FetchBoxSuccessAction {
  type: BoxThunkActionTypes.FETCH_BOX_SUCCESS;
  payload: string;
}

interface FetchBoxErrorAction {
  type: BoxThunkActionTypes.FETCH_BOX_ERROR;
  payload: string;
}
export type BoxActions =
  | SetBoxActions
  | SetBoxFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveBoxAction
  | FetchBoxRequestAction
  | FetchBoxSuccessAction
  | FetchBoxErrorAction;
