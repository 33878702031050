import {
  FilterClusterProps,
  ResponseClusterItemConvert,
} from "interface/cluster";

export const SET_CLUSTER = "SET_CLUSTER";
export const SET_CLUSTER_FILTER = "SET_CLUSTER_FILTER";
export const ADD_CLUSTER_FILTER = "ADD_CLUSTER_FILTER";
export const REMOVE_CLUSTER_FILTER_ITEM = "REMOVE_CLUSTER_FILTER_ITEM";
export const REMOVE_CLUSTER = "REMOVE_CLUSTER";
export enum ClusterThunkActionTypes {
  FETCH_CLUSTER_REQUEST = "FETCH_CLUSTER_REQUEST",
  FETCH_CLUSTER_SUCCESS = "FETCH_CLUSTER_SUCCESS",
  FETCH_CLUSTER_ERROR = "FETCH_CLUSTER_ERROR",
}
export interface SetClusterActions {
  type: typeof SET_CLUSTER;
  payload: ResponseClusterItemConvert[];
}
export interface SetClusterFilterActions {
  type: typeof SET_CLUSTER_FILTER;
  payload: FilterClusterProps;
}
export interface RemoveFilterItemActions {
  type: typeof REMOVE_CLUSTER_FILTER_ITEM;
  payload: string;
}
export interface AddFilterActions {
  type: typeof ADD_CLUSTER_FILTER;
  payload: FilterClusterProps;
}
export interface RemoveClusterAction {
  type: typeof REMOVE_CLUSTER;
  payload: string[];
}
interface FetchClusterRequestAction {
  type: ClusterThunkActionTypes.FETCH_CLUSTER_REQUEST;
}

interface FetchClusterSuccessAction {
  type: ClusterThunkActionTypes.FETCH_CLUSTER_SUCCESS;
  payload: string;
}

interface FetchClusterErrorAction {
  type: ClusterThunkActionTypes.FETCH_CLUSTER_ERROR;
  payload: string;
}
export type ClusterActions =
  | SetClusterActions
  | SetClusterFilterActions
  | RemoveFilterItemActions
  | AddFilterActions
  | RemoveClusterAction
  | FetchClusterRequestAction
  | FetchClusterSuccessAction
  | FetchClusterErrorAction;
