import { CLUSTER, CLUSTER_EDIT, CLUSTER_CREATE } from "constants/ConstRouter";
import React from "react";
import { RoutePermittedRole } from "constants/AppConst";
const Cluster = React.lazy(() => import("./index"));
const EditPage = React.lazy(() => import("./edit/index"));
export const clusterManagementPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: CLUSTER,
    element: <Cluster />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: CLUSTER_EDIT,
    element: <EditPage />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: CLUSTER_CREATE,
    element: <EditPage />,
  },
];
