import { MetadataRes } from "interface/common/common";
import { startOfWeek, endOfWeek, format, getDate } from "date-fns";
import moment from "moment";
import { DATA_PAGE } from "./data-template-table";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
// import { saveAs } from "file-saver";
import { encode, decode } from "js-base64";
export const COLOR = {
  succes: "rgb(76, 175, 80)",
  new: "rgb(33, 150, 243)",
  sending: "#ffef62",
  fail: "rgb(244, 67, 54)",
};
export const statusEmailNotification = {
  NEW: "new",
  SENDING: "sending",
  SUCCESS: "success",
  FAILED: "failed",
};
export const getRole = (value) => {
  let role = [];
  if (value === "admin") {
    role = ["admin", "user", "moderator"];
  }
  if (value === "user") {
    role = ["user"];
  }
  if (value === "moderator") {
    role = ["moderator", "user"];
  }
  return role;
};

export const showInformationPageNavigation = (page: MetadataRes) => {
  const { current_page, page_size, total_items } = page;
  const start = (current_page - 1) * page_size;
  return `Từ ${start ? start : 1} đến ${
    total_items > page_size ? current_page * page_size : total_items
  } trên tổng ${total_items}`;
};

export function removeVietnameseTones(str: string) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    "",
  );
  return str;
}
export const getItemDataPage = (slug: string) => {
  const item = DATA_PAGE.find((it) => it.slug === slug);
  return item ?? null;
};

export const formatCurrency = (number: number) => {
  return number?.toLocaleString("vi", {
    style: "currency",
    currency: "VND",
  });
};
export const formatCurrencyToNumber = (currency: string) => {
  const number = parseInt(currency.replace(/[^\d]/g, ""), 10);
  return number;
};
export const formatNumber = (number: number) => {
  return number.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

// convert date yyyy-mm-dd
export function formatDateToYYYYMMDD(date: string) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + (d.getDate() + 1),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}
export function formatDateToYYYYMM(date: string) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return "Tháng" + month + "/" + year;
}

// function filter
// params
export const a = (params: string | any[]) => {
  if (typeof params === "string") {
  }
};
export const deleteAllParams = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  params.forEach((value, key) => {
    params.delete(key);
  });

  // Modify the current URL without the parameters
  window.history.replaceState({}, "", url.pathname);
};

export const getAllQueryParams = (search?: string) => {
  const urlParams = new URLSearchParams(search ?? "");

  const allValues: Record<string, string | null> = {};

  urlParams.forEach((value, key) => {
    allValues[key] = value;
  });

  return allValues;
};
// ---
export const typeFilter = {
  exactly: "exactly", // tìm kiếm chính xác
  distance: "distance", // trong khoảng cách
  distanceNumber: "distanceNumber", // trong khoảng cách
  includes: "includes", //
  start: "start", // bắt đầu bằng
  bigger: "bigger", // lớn hơn
  less: "less", // nhỏ hơn
  end: "end",
  includesString: "includesString",
};
export const handleConvertParam = (query: string) => {
  const urlParams = new URLSearchParams(query);

  const allValues: Record<string, string | null> = {};

  urlParams.forEach((value, key) => {
    allValues[key] = value;
  });
  const params = allValues;
  const filter1 = [];
  for (let key in params) {
    filter1.push({ key, value: params[key] });
  }
  const listKeys = filter1.map((it) => it.key.split("__")[0]);
  const filter2 = filter1.map((it) => {
    const a = it.key.split("__");
    const isDistant = listKeys.filter((it) => it === a[0]).length;
    const result = {
      key: a[0],
      value: it.value,
      type: "",
    };
    const convertValue = it.value.split("%");
    if (isDistant === 2) {
      result.type = typeFilter.distance;
    } else if (a[1] === "eq") {
      result.type = typeFilter.exactly;
    } else if (a[1] == "lt") {
      result.type = typeFilter.less;
    } else if (a[1] == "gt") {
      result.type = typeFilter.bigger;
    } else if (a[1] == "gt") {
      result.type = typeFilter.bigger;
    } else if (a[1] == "in") {
      result.type = typeFilter.distance;
    } else if (
      a[1] == "like" &&
      convertValue[0] == "" &&
      convertValue[convertValue.length - 1] == ""
    ) {
      result.type = typeFilter.includes;
      result.value = convertValue[1];
    } else if (a[1] == "like" && convertValue[0] == "") {
      result.type = typeFilter.end;
      result.value = convertValue[1];
    } else if (a[1] == "like" && convertValue[convertValue.length - 1] == "") {
      result.type = typeFilter.start;
      result.value = convertValue[0];
    }
    return result;
  });
  const filter3 = [];
  for (let i = 0; i < filter2.length; i++) {
    let item = filter2[i];
    const key = item.key;
    for (let j = i + 1; j < filter2.length; j++) {
      if (filter2[j].key === key) {
        item = {
          ...item,
          value: { value1: item.value, value2: filter2[j].value },
        };
        filter2.splice(j, 1);
      }
    }
    filter3.push(item);
  }
  return filter3;
};
export const paramsHistory = (filter: any, slug: string) => {
  const { pathname, search } = JSON.parse(sessionStorage.getItem("params"));
  const newFilter = { ...filter };
  if (slug !== pathname || !search) return filter;

  const query = handleConvertParam(search.slice(1));
  if (query.length) {
    newFilter.type = "search";
    newFilter.title = "Kết quả tim kiếm";
    query.forEach((it) => {
      newFilter.value[it.key] = {
        value: it.value,
        type: it.type,
      };
    });
    //
  }
  return { newFilter, search };
};

// tạo mảng number
export const createArrayNumber = (number: number) => {
  const result: number[] = [];
  for (let i = 1; i <= number; i++) {
    result.push(i);
  }
  return result;
};
// convert ngày theo định dạng DD/MM/YYYY vs HH:mm
export function formatDateToDDMMYYYYvsHHMM(inputDate: string | Date): string {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}/${month}/${year}, ${hours}:${minutes}`;
}
// convert ngày theo định dạng DD/MM/YYYY
export function formatDateToDDMMYYYY(date: Date): string {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

// lấy ngày đầu tháng
export function returnFirstDayOfTheMonth() {
  const date = new Date();
  date.setDate(1);
  const formattedDate =
    date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  return formattedDate;
}

//  convert datetime to date
export const convertDateTimeToDate = (date_time: string | Date) => {
  const dateValue = date_time instanceof Date ? date_time : new Date(date_time);
  // Ví dụ giá trị datetime

  dateValue.setHours(0, 0, 0, 0); // Đặt giờ, phút, giây và mili giây thành 0
  dateValue.setDate(dateValue.getDate() + 1);
  return dateValue.toISOString().split("T")[0]; // Đây là ngày (date) dưới dạng chuỗi, ví dụ '2023-11-03'
};

export function checkFileType(file: File) {
  let result: string | null = null;
  const imageTypes = ["image/jpeg", "image/png", "image/jpg"];
  const videoTypes = ["video/mp4"];

  if (imageTypes.includes(file.type)) {
    result = "image";
  }
  if (videoTypes.includes(file.type)) {
    result = "video";
  }
  return result;
}

export function checkFileTypeString(file: string) {
  let result = 1;
  const imageTypes = ["jpeg", "png", "jpg"];
  const videoTypes = ["mp4"];

  if (imageTypes.includes(file)) {
    result = 1;
  }
  if (videoTypes.includes(file)) {
    result = 2;
  }
  return result;
}

// covert time create
export const showTimeStart = (createAt: string) => {
  const b = moment().format();
  const a = moment(createAt, "YYYY-MM-DD");
  const c = -a.diff(b, "minutes");
  let result = createAt;
  if (c < 1440) {
    result = moment(result).fromNow();
  } else if (c < 2880) {
    result = "Đã tạo ngày hôm qua";
  } else {
    const date = moment(result).date();
    const month = moment(result).month() + 1;
    const year = moment(result).year();
    result = `Đã tạo ${date} thg ${month} năm ${year}`;
  }
  return result;
};

// tính số ngày từ start đến end
function tinhSoNgayGiuaHaiNgay(start_learn, end_learn) {
  var startDate = new Date(start_learn).getTime();
  var endDate = new Date(end_learn).getTime();
  var timeDifference = endDate - startDate;
  var daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1;
  return daysDifference;
}
// find start week and end week
export const getWeekRange = (selectedDate: string | Date) => {
  const date =
    selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
  const startDate = startOfWeek(date);
  const endDate = endOfWeek(date);
  startDate.setDate(startDate.getDate() + 1);
  return {
    startDate: startDate.toString(),
    endDate: endDate.toString(),
  };
};
// chekc am or pm
export function checkAMorPM(timeString: string) {
  // Tạo một đối tượng Date từ chuỗi thời gian
  const time = new Date(`2000-01-01 ${timeString}`);

  // Lấy giờ từ đối tượng Date
  const hours = time.getHours();

  // Kiểm tra xem giờ có lớn hơn hoặc bằng 12 không
  if (hours >= 12) {
    return "PM";
  } else {
    return "AM";
  }
}
export const convertText = (text: string, number: number) => {
  let result: string = text;
  if (text.length > number) {
    result = text.slice(0, number) + " ...";
  }
  return result;
};
export const stringToArray = (string) => {
  const number = Math.ceil(+string);
  const result = [];
  for (let i = 0; i < number; i++) {
    result.push(i + 1);
  }
  return result;
};
export const checkStatusTwoDate = (
  date1: Date | string,
  date2: Date | string,
  distant?: number,
) => {
  const limit = distant ?? 3;
  // convert string to date
  const Date1 = typeof date1 === "string" ? new Date(date1) : date1;
  const Date2 = typeof date2 === "string" ? new Date(date2) : date2;
  // Calculate the difference in milliseconds
  const timeDiff = Date1.getTime() - Date2.getTime();

  // Convert the time difference to days
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  // Round to the nearest whole number and return
  const distantDate = Math.round(daysDiff);
  if (distantDate < 0) return "Quá hạn";
  if (distantDate > limit) return "Trong hạn";
  return "Gần đến hạn";
};
export const exportToExcel = (base64Data, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const blob = new Blob([base64Data], {
    type: fileType,
  });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName + ".xlsx";

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);

  // const ws = XLSX.utils.json_to_sheet(base64Data);
  // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  // const data = new Blob([excelBuffer], { type: fileType });
  // FileSaver.saveAs(data, fileName + ".xlsx");
};
