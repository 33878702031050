import { ResponseTableDashboard } from "interface/dashboard/dashboard";
import Service from "service/api-core/apiService";
const service = new Service();
export interface ResponseDashboard {
  total_warehouse: number;
  total_archival_record: number;
  total_cluster: number;
  total_paper_total: number;
  total_paper_digital: number;
  total_customer: number;
  in_term: number;
  due: number;
  overdue: number;
}
export const dashboardApi = {
  //  information system
  system() {
    const END_POINT = "/archival_record/dashboard/fe/div1";
    let url = END_POINT;

    return service
      .get(url)
      .then((res: { statusCode: number; data: ResponseDashboard }) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  listRecord() {
    const END_POINT = "/archival_record/dashboard/fe/div2";
    let url = END_POINT;

    return service
      .get(url)
      .then((res: { code: string; data: ResponseTableDashboard[] }) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
